.card-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.cards {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.tran-head{
  font-size: 24px;
  font-family:Inter-Medium,sans-serif;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color:#362A41;
  margin-top: 30px;
}
.bank-admin{
  background-color: #fff;
  border-radius: 24px;
  margin:0 60px;
  padding:33px 27px;
}
.bank-admins{
  background: #F7F7F8A6;
  opacity:0.65;
  border-radius: 24px;
  margin:0px 60px;
  padding:33px 27px;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select{
  top:8px;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions{
  margin-top:10px;
}
.date-picker-wrap{
  position: absolute;
  right:200px;
  background-color: #FFF;
  border-radius:8px;
  padding:10px;
  z-index:3;
  width:370px;
}
.date-box{
  display: flex;
  justify-content: space-between;
}
.date-box-date{
  color:#4F4953;
  font-family:Inter-Medium,sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 16.8px;
text-align: left;
}
.date-box-clear{
  color: #955CCA;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
  font-family:Inter-Medium,sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
}
.MuiDateRangeCalendar-root, .css-e47596-MuiDateRangeCalendar-root div:nth-child(1){
  color: #FFF !important;
  z-index: 0;
}
.MuiPickersCalendarHeader-label, .css-dplwbx-MuiPickersCalendarHeader-label{
  font-size: 14px !important;
  opacity: 0.9 !important;
  background-color: #362A41;
  border-radius: 8px;
  padding: 5px;
  margin-left: 80px;
}
.css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected){
  background-color: #362A41;
  color:#FFF;
}
.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  position: absolute !important;
  left:0;
}
.css-1ehhech-MuiDataGrid-root{
  color:#222222 !important;
  opacity: 0.9;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.css-5mz6i9{
  color:#222222 !important;
  opacity: 0.9;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.sta-red{
  color:#FF596A;
}
.sta-green{
  color:#0EAD69;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 14px !important;
  top: -10px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding:5px !important;
}
