.feedbackmain {
  display: flex;
  flex-direction: column;
  margin: 50px;
}
.feedback-flex {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.candidate-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.card-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
