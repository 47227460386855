.breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  color: grey;
  margin-left: 224px;
}
.bread-crumbs{
  margin-left: 30px;
}
.breadcrumbs li {
  display: inline;
  margin: 0 5px;
  font-family: var(--pf-medium);
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.breadcrumbs li:last-child {
  color: var(--this-primary);
}
.breadcrumbs span::after {
  content: " > ";
  margin: 0 5px;
  color: #201a4159; /* Color of the ">" icon */
}
.jb-p-title {
  font-family: var(--pf-medium);
}
.jb-p-deg {
  font-family: var(--pf-regular);
}
.sort-opt{
  background: #f4f4f6;
  height:48px;
  border-radius: 8px;
  color: #40444F;
  font-size:12px;
  font-weight:700;
  padding:10px;
  display:flex;
  gap:20px;
  justify-content:space-between;
  align-items:center;
  cursor: pointer;
}
.sort-option{
  background-color: #8653B6;
  color:#fff;
  cursor: pointer;
}
/* Mobile */
@media (max-width: 576px) {
  .jb-p-title {
    font-size: 14px;
  }

  .jb-p-deg {
    font-size: 12px;
  }
  .jp-diag-title,
  .jp-diag-body,
  .qa-holder {
    padding: 10px !important;
  }
  .pdf-open-btn {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background: white;
    padding: 15px 2px;
  }
  .vp-m-header{
    flex-wrap: wrap;
  }
  .vp-m-header p,  .vp-m-header div{
    margin-bottom: 0;
    margin-top: 0!important;
  }
  .vp-1, .vp-2, .vp-footer, .vp-footer >div{
    width: 100%;
  }
  
}
.profile-pic {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
}
.btn-outline,
.btn-outline:hover,
.btn-outline:focus {
  border-radius: 10px;
  position: relative;
  font-weight: normal;
  font-size: 14px;
  color: black;
  padding: 7px 10px;
  cursor: pointer;
  border: 1px solid #362a414d;
  font-family: var(--pf-medium);
}
.apd-view-profile{
  color: var(--this-secondary);
  font-family: var(--pf-medium);
  font-size: 16px;
}
/* Tab & Desktop */
@media only screen and (min-width:576px) and (max-width:992px) {
  .ap-cl{
    padding: 10px;
  }
}
@media (min-width: 576px) {

  .breadcrumbs {
    padding: 20px 0;
  }
  .jb-p-title {
    font-size: 18px;
  }
  .jb-p-deg {
    font-size: 14px;
  }
  .ap-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid #F1F1F1;
    border-radius: 16px;
    padding: 16px 24px;
    height:80px;
  }
  .ap-card > div {
    text-align: center;
  }
  .ap-card .ap-image {
    width: 27%;
  }
  .ap-card .ap-deg {
    width: 30%;
  }
  /* .ap-card .ap-status {
    width: 10%;
  } */
  .ap-card .ap-ques {
    width: 24%;
  }
  .ap-card .ap-applicant {
    width: 19%;
  }

  .ap-image .name,
  .ap-deg .deg,
  .t-ques {
    margin: 0;
  }
  .ap-pos {
    font-size: 12px;
    font-family: var(--pf-regular);
    color: var(--this-primary-lite);
    font-weight: 900;
    text-align: left;
    margin: 0;
  }
  .apd-card .ap-image{
    width: 40%;
  }
  .apd-card .ap-deg{
    width: 45%;
  }
  .apd-vp-container{
    width: 15%;
    text-align: right;
  }
  .apd-body{
    max-height: 500px;
  }
  .ap-resume iframe{
    /* height: auto!important; */
    max-height: 500px;
    margin: 0;
  }
  .filter-options{
    position: absolute;
    background: white;
    z-index: 9999;
    margin-top: 45px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .filter-profile{
    left:140px;
  }
  .filter-exp{
    left:330px;
  }
  .filter-loc{
    left:550px;
  }
  .filter-head{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F4F4F6;
    padding: 10px 10px 0px 10px;
    padding-bottom: 10px;
  }
  .filter-head img{
    cursor: pointer;
    width:20px;
  }
  .filter-tit{
    color: #362A41;
    font-size: 18px;
    font-weight: 700;
  }
  .filter-opt-cont{
    color: #362A41;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px 0 20px;
    display: flex;
    gap:10px;
    align-items: center;
    margin-bottom: 10px;
  }
  .filter-opt-cont label{
    margin-bottom: 0 !important;
  }
  .filter-opt-cont input{
    width:22px;
    height: 22px;
  }
  .reset-btn{
    border: 1px solid #362A411A;
    border-radius: 8px;
    color: #362A41;
    height:43px;
  }
}
@media (min-width: 1300px) {
  .ap-card .ap-image {
    width: 26%;
  }
  .ap-card .ap-deg {
    width: 32%;
  }
  /* .ap-card .ap-status {
    width: 10%;
  } */
  .ap-card .ap-ques {
    width: 19%;
  }
  .ap-card .ap-applicant {
    width: 18%;
  }

  .apd-card .ap-image{
    width: 40%;
  }
  .apd-card .ap-deg{
    width: 45%;
  }
  .apd-vp-container{
    width: 15%;
    text-align: right;
  }
  
}
.star-main{
  position: relative;
  display: flex;
}