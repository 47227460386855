.conver-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50px;
}

.conver-div {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
}

.conver-img-left {
  display: flex;
  align-items: center;
  margin: 3px;
}

.conver-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; /* Needed for absolute positioning of new messages count */
  width:100%;
}

.conver-name {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.conver-last-message {
  display: flex;
  justify-content: space-between; /* Aligns items on both sides */
  align-items: center; /* Centers items vertically */
  color: gray;
  width:98%;
}
.mainaccess{
  display: flex;
  justify-content: space-between; /* Aligns items on both sides */
}
.message-timestamp {
  font-size: 0.8em; /* Smaller font size for the timestamp */
  color: gray; /* Optional: Change color to make it less prominent */
  margin-left: 10px; /* Space between message text and timestamp */
  position: absolute;
  right:0;
  bottom:0;
}

.new-messages-count {
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  margin-left: 10px;
}
.last-div-count {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.searchMessage{
  margin-left: 10px;
    margin-top: 10px
}