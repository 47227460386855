.promo-list {
  margin: 5rem;
}
.action-buttons {
  display: flex;
  justify-content: space-around;
}

.edit-button {
  background: none;
  position: absolute;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #49454f;
  margin-left: 70px;
}
.delete-button {
  background: none;
  position: absolute;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #49454f;
}

.edit-button:hover,
.delete-button:hover {
  color: #007bff;
}
.promo-btn {
  width: 180px;
  background-color: #362a41;
  color: #fff;
  padding: 10px;
  border: 1px solid #362a41;
  border-radius: 8px;
}
.promo-btn2 {
  background-color: #362a41;
  color: #fff;
  padding: 10px;
  border: 1px solid #362a41;
  border-radius: 8px;
}
.promo-btn-main {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: end;
  margin-bottom: 20px;
}
.separate-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.user-input-container {
  display: flex;
  flex-direction: column;
}

.user-input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.remove-user-btn {
  margin-left: 10px;
}

.add-user-btn {
  margin-top: 10px;
}
.open-button {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  top: 14px;
}
