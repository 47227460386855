.header {
  font-size: 16px;
  font-weight: 700;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  margin: auto;
  background: #f4f4f6;
  padding: 18px;
  width: 330px;
}
.footer {
  background-color: rgba(54, 42, 65, 1);
  color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}
.contents {
  color: #707070;
  font-weight: 500;
  padding: 20px;
  margin: auto;
  max-width: 330px;
  text-align: center;
}
/* .contents data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: center;
  margin: auto;
} */
.contents .title {
  font-size: 24px;
  margin-bottom: 20px;
}
.data {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  max-width: 500px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #707070;
}
/* .datas {
  left: 350px;
} */

.pay-head {
  position: relative;
  top: 5px;
}
.get-help a{
  color: rgba(149, 92, 202, 1);
  text-decoration: underline;
  text-underline-offset: 3px;
  position: relative;
  top:5px;
}
.foot-logo-wrap {
  text-align: center;
}
.foot-logo {
  margin: 20px 0 20px 0;
  height: 125px;
}
.header-logo {
  padding: 20px;
}
.header-logo img{
  height:40px;
  width:150px;
}
