.status,
.days,
.time {
  font-size: 12px;
  margin-bottom: 5px;
}
.status {
  margin-top: 0px;
}
.ats-card-wrap {
  margin-top: 10px;
}
.days {
  margin-left: 5px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.time {
  margin-left: 7px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.handIcon {
  width: 20px;
  height: 13px;
  margin-right: 4px;
}
.locationIcon {
  width: 12px;
  height: 14px;
  margin-left: 5px;
}
.card_right {
  width: auto;
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 25px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #656565;
}
.card_right p {
  margin-top: -5px;
}
.card_right p > title {
  background-color: #202020;
}
.card_rights {
  width: auto;
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 20px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #656565;
}
.card_rights p {
  margin-top: -5px;
}
.ats-job-title {
  color: #656565;
  font-size: 12px;
  font-weight: 900;
  margin-top: -10px;
}
.order .status {
  color: #202020;
  padding: 3px 10px;
  border-radius: 18px;
  position: relative;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: 600;
  font-family: Inter-Medium, sans-serif;
}
.done .status {
  /* background: #c8ebdd; */
  color: #494949;
  padding: 3px 10px;
  border-radius: 18px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #202020;
}
.pending .status {
  /* background: #cfd8f0; */
  color: #494949;
  padding: 3px 10px;
  border-radius: 18px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #202020;
}
.waiting .status {
  /* background: #eac8d7; */
  color: #494949;
  padding: 3px 10px;
  border-radius: 18px;

  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #202020;
}

.drag_container {
  min-height: 80vh;
  background: #ffffff;
  margin: 5px;
  border-radius: 12px;
}
.drag_column {
  width: 100%;
  float: left;
  display: flex;
  flex-grow: inherit;
  flex-wrap: wrap;
  /* padding: 30px; */
}
.drag_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 12px;
  position: relative;
  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 10px;
    width: 130px;
  }
}
.App-Card-out {
  border-top: 7px solid #1eb4dd;
  margin: 35px 20px 0 10px;
  border-radius: 15px;
  width: 103px;
}
.Short-Card-out {
  border-top: 7px solid #e5b738;
  margin: 35px 20px 0 10px;
  border-radius: 15px;
  width: 103px;
}
.Card-out {
  border-top: 7px solid #36bf6d;
  margin: 35px 20px 0 10px;
  border-radius: 15px;
  width: 103px;
}
.Card-outs {
  border-top: 7px solid #da4452;
  margin: 35px 20px 0 10px;
  border-radius: 15px;
  width: 103px;
}
.Card-outer {
  width: 113px;

  @media (max-width: 500px) {
    width: 150px;
  }
}
.ats-location {
  color: #000000;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: 900;
  margin-top: 20px;
}
.ats-location img {
  margin-right: 10px;
}
.small-box {
  width: 140px;
  background: #ffffff;
  border-radius: 12px;
  @media (max-width: 500px) {
    width: 360px;
    border-radius: 12px;
  }
}

.checkboxInput {
  position: absolute;
  margin-top: -210px;
  margin-right: 30px;
  width: 12px;
  height: 12px;
  backface-visibility: hidden;
  animation: checked 200ms ease 1;
}

.checkboxInput label:before {
  width: 16px;
  height: 16px;
  border: 3px solid #ffffff !important;
  margin-left: -2px;
}

.overlay-popup {
  background: rgba(49, 49, 49, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.interview {
  position: absolute;
  /* right:30px;
    width:140px;
    top:185px; */
  bottom: 0px;
  top: -13px;
  right: 220px;
}
/* .addemails,.Error{
  position:relative;
  top:15px;
  margin-left:10px;
  margin-top:10px;
  padding:5px 20px;
} */
.profile-img {
  border: 2px solid grey;
  width: 45px;
  border-radius: 50%;
  filter: grayscale(50%);
}
.button2ats {
  width: 100px;
  height: 40px;
  color: white;
  border-radius: 128px;
  background: #31b968;
  position: relative;
  top: 15px;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
}
.card-wrapper {
  opacity: 0.3; /* Default opacity */
}

.card-wrapper.visible {
  opacity: 1; /* Opacity when visible */
}
.imageAts_company {
  width: 30px;
  height: 30px;
  border-radius: 25px;
}
.data-company {
  display: flex;
  gap: 10px;
}
.pending-flex {
  display: flex;
  gap:20px;
  justify-content: space-between;
  margin: 20px;
  top: 160px;
  position: relative;
  background: #ededed;
  margin-left: 4rem;
  margin-right: 4rem;
  padding: 11px;
  border-radius: 10px;
}
/* .pending-int{
  top:135px;
} */
.pending-push {
  margin: 20px;
  display: flex;
  gap: 20px;
}
.image-push {
  width: 150px;
  height: 150px;
}
.flex-button {
  position: relative;
  display: flex;
}
.closeFeedback {
  padding: 7px;
  background: #362a41;
  border-radius: 10px;
  color: white;
}
@media (max-width:1024px){
  .closeFeedback {
    font-size: 14px;
    padding:0 10px;
    width:200px;
  } 
}
@media (max-width:560px){
  .pending-flex {
    top:0px;
    display: block;
    margin:20px 64px 0 64px;
  }
  .closeFeedback {
    font-size: 14px;
    padding:10px;
    width:200px;
    margin: 10px 0;
  } 
}
@media (min-width:600px) and (max-width: 1024px) {
  .pending-flex{
    top:185px;
  }
}