.aptitude-candidate {
  display: flex;
  align-items: center;
  margin: 35px;
}

.content-wrapper {
  padding: 20px; /* Optional: Add padding around the content */
  text-align: center; /* Center-align text within the wrapper */
  background: white;
  border-radius: 20px;
  width: 100%;
}
.header-one {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.header-two {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}
.header-three {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}
.header-one span{
  color:#362A41;
}
.apt-tick{
  text-align: center;
  font-weight: 600;
}
.apt-mark-wrap{
  border: 0.2px solid grey;
  border-radius: 4px;
  padding:0px 10px 10px 10px;
  margin-top: 20px;
}
.apt-mark{
  color:grey;
  margin-bottom: 5px;
}
.passed {
  color: green !important;
  border: 1px solid green;
  border-radius: 10px;
  margin-left: 1px;
  margin-right: 1px;
  padding:5px 10px;
  margin-left: 10px;
}
.failed {
  color: red !important;
  border: 1px solid grey;
  border-radius: 10px;
  margin-left: 1px;
  margin-right: 1px;
  padding:5px 10px;
  margin-left: 10px;
}
.button-apt {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.attempt-history {
  display: flex;
}
.time-limit {
  position: absolute;
  margin-top: 30px;
}
.main-limit {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 500px;
}
.main-limit span{
  font-size: 14px;
}
.instruction-one {
  display: flex;
  padding: 5px;
}
.instruction-list{
  text-align: left;
    margin: 1rem;
}
