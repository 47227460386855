.toaster-message {
    position: fixed;
    top: 20px;
    right: 0%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #764F90;
    color: white;
    border:2px solid white;
    font-weight: 800;
    border-radius: 10px;
    /* opacity: 0; */
    transition: opacity 0.3s ease-in-out;
    width:400px;
  }
  
  .toaster-message.show {
    opacity: 1;
  }