.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 30px;
}

.question-card {
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.sub-btn{
  background-color: #362A41;
  padding:10px;
  border: 1px solid #362A41;
  border-radius: 8px;
  color:#FFF;
  font-size: 16px;
}
.sub-btn:hover{
  background-color: #FFF;
  border: 1px solid #362A41;
  color:#362A41;
}
.question-card h3 {
  margin-bottom: 10px;
}

.question-card ul {
  list-style-type: none;
  padding: 0;
}

.question-card ul li {
  margin-bottom: 5px;
}
.aptitude-container {
  position: relative;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.start-test {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 5rem;
}
.button-center-aptitude {
  display: flex;
  justify-content: center;
  margin: 5rem;
}
.main-button-submit {
  display: flex;
  justify-content: end;
  margin-right: 20px;
}
.delete-aptitude-list-icon {
  display: flex;
  justify-content: center;
  position: relative;
}
