.pending-flex {
    display: flex;
    justify-content: space-around;
    margin: 20px;
  }
  .pending-push {
    margin: 20px;
    display: flex;
    gap: 20px;
  }
  .image-push {
    width: 150px;
    height: 150px;
  }
  .flex-button {
    position: relative;
    display: flex;
  }