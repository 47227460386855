.buttonTaster {
  width: 150px;
  background-color: #362a41;
  border-radius: 128px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 50px;
  margin: 15px 0;
}
.css-1p3m7a8-multiValue {
  background-color: #f4e8ff !important;
}
.css-1u9des2-indicatorSeparator {
  width: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.buttonTaster:hover {
  background: white;
  color: #362a41;
  border: 1px solid #362a41;
}
.inputTaster {
  background: white;
  border-radius: 10px;
  border: 0px;
  padding: 14px 20px;
  outline: none;
  width: -webkit-fill-available;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-overflow: ellipsis;
}
.mandatory-question {
  color: red;
}

.mandatory-star {
  color: red;
  margin-left: 2px; /* Add some space between the question text and the star */
}
.react-toggle--checked .react-toggle-track {
  background-color: #955cca !important;
}
.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #955cca !important;
}

.buttonColor {
  background-color: #362a41;
  border: 2px solid transparent; /* Add a transparent border */
  color: white; /* Text color */

  /* Transition for a smooth effect */
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.buttonColor:hover {
  background-color: white; /* White background on hover */
  border-color: #362a41; /* Purple border on hover */
  color: #362a41; /* Text color on hover */
}
.MuiButtonBase-root .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: #362a41;
}
.job-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd; /* Add a border for clarity */
  padding: 10px;
  margin: 10px;
}

.left-section {
  flex: 1; /* Takes up all available space */
  padding: 10px; /* Add padding for spacing */
}

.right-section {
  flex: 1; /* Takes up all available space */
  padding: 10px; /* Add padding for spacing */
}
.button3 {
  background-color: #362a41;
  border-radius: 10px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding: 7px 10px;
  cursor: pointer;
  width: 100px;
  height: 43px;
}

.button5 {
  background-color: #fff;
  border-radius: 10px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #362a41;
  border-radius: 8px;
  border: 0.5px solid grey;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  gap:10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.button3:disabled {
  background-color: lightgray; /* Change the background color for disabled state */
  color: gray; /* Change the text color for disabled state */
  cursor: not-allowed; /* Change the cursor for disabled state */
  height: 43px;
}
.button4 {
  background-color: rgba(50, 249, 130, 1);
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  cursor: pointer;
  width: 20%;
}

.button4:disabled {
  background-color: lightgray; /* Change the background color for disabled state */
  color: gray; /* Change the text color for disabled state */
  cursor: not-allowed; /* Change the cursor for disabled state */
}
/* .active {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px; 
  background-color: #955cca; 
  text-decoration: underline;
} */
.input-with-icon {
  position: relative;
}

.icon-inside-input {
  position: absolute;
  top: 50%;
  margin-left: 10px; /* Adjust this value as needed */
  transform: translateY(-50%);
  color: #000; /* Icon color */
  cursor: pointer;
}
.MuiBadge-root {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
  background: var(--this-secondary);
  color: white;
  border-radius: 5px;
  font-family: var(--pf-regular) !important;
  font-size: 12px;
  letter-spacing: 2px; /* Adjust the letter-spacing as needed */
}
/* .def .MuiDialogContent-root {
  padding: 5px 24px;
} */
.defs .MuiDialogContent-root {
  padding: 5px 24px;
}
.custom-file-upload {
  border: 1px dashed #c6cad2 !important;
  /* display: flex; */
  justify-content: center;
  margin-left: 0px;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
  background: transparent;
  /* height:73px; */
  width: 100%;
  /* border-radius: 5px; */
}
.custom-file-upload img {
  width: 24px;
  height: 24px;
}
input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.pending-flex {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}
.pending-push {
  margin: 20px;
  display: flex;
  gap: 20px;
}
.image-push {
  width: 150px;
  height: 150px;
}
.flex-button {
  position: relative;
  display: flex;
}
