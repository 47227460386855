.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px;  */
  /* padding: 50px; */
}

.card {
  display: flex;
  padding: 50px;
}

.left-section,
.right-section {
  /* flex: 1; */
  padding: 10px 20px;
}
.right-section {
  border: 2px solid #f4f4f6;
  border-radius: 8px;
  max-width: 300px;
  width: fit-content;
}
.left-section {
  display: flex;
  gap: 20px;
}
.left-section img {
  width: 100px;
  height: 100px;
}
.img-card-div {
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  border-radius: 80px;
}
.dataOne {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.dataOne2 {
  /* display: flex;
  justify-content: space-around;
  margin-top: 30px; */
}
.buttonDone {
  background: white;
  width: 10%;
  height: 40px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  /* opacity: 0.5; */
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #e3e2e6;
}
.feed-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.feed-name {
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
  margin: 20px 0 20px 0;
}
.feed-desig {
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(79, 73, 83, 1);
}
.feed-address {
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(168, 161, 172, 1);
  margin-top: 5px;
  display: flex;
}
.react-stars-wrapper-07870080650049431,
.react-stars-wrapper-04813702305554266,
.react-stars-wrapper-044381329597614627,
.react-stars-wrapper-07234251143323573,
.react-stars-wrapper-024613487332869566 {
  position: absolute !important;
  left: 200px !important;
}
/* .table{
  border:1px solid #F4F4F6;
  border-radius:8px !important;
} */
.table td {
  border: none;
}
.table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(even) {
  background-color: #fff;
}
.feed-label-name {
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin: 10px 0 5px 0;
}
.feedback-input {
  width: 225px;
  font-size: 10px;
  color: #362a41 !important;
  font-weight: normal !important;
}
.feedback-details {
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(79, 73, 83, 1);
}
.star-icon {
  display: flex;
  gap: 5px;
}
.star-icons {
  margin-top: 10px;
}
.table-class {
  /* border: 1px solid rgba(241, 241, 241, 1); */
  border-radius: 16px;
  margin-top: 20px;
}
.table-bor {
  border-radius: 8px;
  box-shadow: 2px 2px 2px 2px;
}
.button-main-download {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
