.sidebar {
  position: relative;
}
.sidebar .nav-item .nav-link {
  display: flex;
  align-items: center;
  font-family: var(--pf-medium);
  padding: 25px 15px 25px 40px;
  border: 2px solid #fff;
  border-radius: 8px;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-position: 18px center;
}
.sidebar .nav-item .nav-link.active,
.sidebar .nav-item .nav-link:active {
  color: #ffffff !important;
  background-color: var(--this-primary) !important;
  text-align: left !important;
}
.sidebar .nav-item .nav-link:hover,
.sidebar .nav-item .nav-link:focus {
  border: 2px solid #f4e8ff !important;
  color: #362a41;
  background-color: #f4e8ff;
}
.sidebar .nav-item .nav-link.iCalendar {
  background-image: url(../images/icons/calendar.svg);
}
.sidebar .nav-item .nav-link.active.iCalendar,
.sidebar .nav-item .nav-link.iCalendar.active {
  background-image: url(../images/icons/CalendarActive.svg);
  background-color: #fff;
}
.sidebar .nav-item .nav-link.iProfile {
  background-size: 30px;
  background-position-x: 5px;
}
.sidebar .nav-item .nav-link.active.iProfile,
.sidebar .nav-item .nav-link.iProfile:active {
  background-color: #fff;
}
.iProfile img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.sidebar .nav-item .nav-link.iInterviews {
  background-image: url(../images/icons/Interview.svg);
}
.sidebar .nav-item .nav-link.active.iInterviews,
.sidebar .nav-item .nav-link.iInterviews:active {
  background-image: url(../images/icons/InterviewActive\ \(2\).svg);
}
.sidebar .nav-item .nav-link.iATS {
  background-image: url(../images/ATSNormal.svg);
  background-size: 20px;
}
.sidebar .nav-item .nav-link.active.iATS,
.sidebar .nav-item .nav-link.iATS:active {
  background-image: url(../images/ATShover2.svg);
  background-size: 20px;
}

.sidebar .nav-item .nav-link.iJobs {
  background-image: url(../images/icons/Jobs.svg);
}
.sidebar .nav-item .nav-link.active.iJobs,
.sidebar .nav-item .nav-link.iJobs:active {
  background-image: url(../images/icons/JobsActive.svg);
}
.sidebar .nav-item .nav-link.iMjobs {
  background-image: url(../images/icons/nav-mjobs.svg);
}
.sidebar .nav-item .nav-link.active.iMjobs,
.sidebar .nav-item .nav-link.iMjobs:active,
.sidebar .nav-item .nav-link.iMjobs:focus,
.sidebar .nav-item .nav-link.iMjobs:hover {
  background-image: url(../images/icons/nav-mjobs-active.svg);
}
.sidebar .nav-item .nav-link.iOrganisation {
  background-image: url(../images/icons/Organisation.svg);
}
.sidebar .nav-item .nav-link.active.iOrganisation,
.sidebar .nav-item .nav-link.iOrganisation:active {
  background-image: url(../images/icons/OrganisationActive.svg);
}
.sidebar .nav-item .nav-link.iAptitude {
  background-image: url(../images/icons/Star\ Medal.svg);
}
.sidebar .nav-item .nav-link.active.iAptitude,
.sidebar .nav-item .nav-link.iAptitude:active {
  background-image: url(../images/icons/svg_star.svg);
}

.sidebar .nav-item .nav-link.iUser {
  background-image: url(../images/icons/AdminUser.svg);
}
.sidebar .nav-item .nav-link.iCompany {
  background-image: url(../images/icons/AdminCom.svg);
}
.notes-margin {
  margin-left: 8%;
  border-left: 1px solid #666;
  height: 10%;
}
.assessment-margin {
  margin-left: 10%;
  text-align: center;
}
.nav-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 84vh;
  justify-content: space-between;
  margin-top: 60px;
  padding-bottom: 20px;
}
.am {
  margin-left: 2px;
  font-weight: normal;
}
.width {
  width: 50px;
  font-weight: normal;
  margin-left: -5px;
}

.transport-margin {
  margin-left: 14%;
}
.att-margin {
  margin-left: 9%;
}
.pa-margin {
  margin-left: 10%;
}
.stu-margin {
  margin-left: 4%;
}
.transport-margin {
  margin-left: 16%;
}
.de-margin {
  margin-left: 8%;
}
.pro-margin {
  margin-left: 8%;
}
.logo-color {
  color: #4f4953;
}
.time-margin {
  margin-left: 17%;
}
.col-margin {
  margin-left: 2%;
}
.a-margin {
  margin-left: 12%;
}
.work-margin {
  margin-left: 11%;
}
.co-margin {
  margin-left: 10%;
}
.acc-margin {
  margin-left: 13%;
}
.main-margin {
  margin-left: 5%;
}
.mr {
  margin-left: 2%;
}
.logout {
  display: block;
  margin-right: 10px;
  margin-left: 10px;
  color: red;
  background-color: white;
  padding: 10px;
  margin-bottom: 30px;
}
.logoutuser {
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  color: red;
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}
.notes {
  margin-bottom: 5%;
}
.logo {
  width: 70%;
  width: 210.19px;
  height: 160.68px;
  left: 1%;
  margin-top: 15%;
}

.logo-container {
  text-align: center;
}
.sb-main-logo {
  width: 80%;
  height: 65px;
  object-fit: contain;
}

.nav-weight {
  font-weight: normal;
  margin-left: 8px;
}
.pro-name {
  margin-top: 5px;
}
.prof-name {
  display: none;
}

.profile-name:hover .prof-name {
  display: block;
  position: absolute;
  top: 50px;
  right: 100px;
  background-color: #362a41;
  padding: 5px;
  border-radius: 8px;
  color: #fff;
}
.nav-weight1 {
  font-weight: normal;
  margin-left: -8px;
}
.ml {
  margin-left: -5px;
}
.mll {
  margin-left: 10px;
}
.ar {
  margin-left: 8px;
}
.co {
  margin-left: 13px;
}
.tm {
  margin-top: -20px;
}
#content {
  background-color: #f6f6f6;
  min-height: 100vh;
}
/*line */
.vector {
  position: absolute;
  width: 2px;
  height: 20%;
  left: 30px;
  top: 392px;
  background-color: #f8f9fc;
}
.vector:active {
  background-color: #4f4953;
}
.vector:focus {
  background-color: #4f4953;
}
.vector:target {
  background-color: #4f4953;
}
.sidebar {
  position: fixed;
  height: 100%;
  box-sizing: initial;
}
.nav-link {
  background-color: #ffffff;
}

.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus {
  background-color: var(--this-primary) !important;
  text-align: left !important;
}

.nav-link {
  height: 40px;
}
.form-control {
  border: none !important;
}
.icon-hover:hover {
  background-color: #f8f9fc;
}
.logout {
  float: right;
  display: block;
  position: relative;
  top: -20px;
  margin-right: 10px;
  margin-left: 10px;
  color: red;
  background-color: white;
  padding: 6px;
  margin-bottom: -7px;
}
.nav .nav:active .nav:focus .nav:hover {
  background-color: #4f4953 !important;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 1rem;
  width: 14rem;
  text-align: left !important;
}
.html {
  scrollbar-width: normal;
  scrollbar-color: #362a41;
}
.html::-webkit-scrollbar {
  width: 0px;
}

.html::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
  height: 5px;
}
.html::-webkit-scrollbar-track {
  background-color: #362a41;
}
.t-i-search {
  position: absolute;
  top: 50%;
  right: 10px;
  border-radius: 8px;
  padding: 8px;
  width: 32px;
  height: 32px;
  background: #fff;
  transform: translateY(-50%);
}
.setting-modal {
  border-radius: 24px !important;
}
.o-desk {
  font-family: Inter-Medium, sans-serif;
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--this-fourth);
}
.modalHeader {
  padding: 20px 20px;
}
.mheader {
  padding: 10px 20px;
}
.modHeader {
  display: flex;
  gap: 20px;
  padding: 10px 20px;
}
@media (max-width: 576px) {
  .nav-desktop,
  .o-desk {
    display: none !important;
  }
  .top-header {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .th-title {
    font-family: "Inter-Bold";
    font-size: 20px;
    font-weight: normal;
    position: relative;
    color: black;
    margin-bottom: 0;
  }
  .t-i-search {
    top: 40%;
    transform: translate(-45%, -10px);
  }

  .otp-group {
    max-width: 290px;
    column-gap: 14px;
  }
  .otp-input {
    border: 0;
    background-color: var(--input-bg);
    font-size: 25px;
  }
  #content {
    padding-bottom: 50px !important;
  }
  .mobile-footer {
    background-color: white;
    border-top: 1px solid #e1dae7;
    padding: 16px 5px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  .mobile-foot {
    position: fixed;
    right: 0;
    bottom: 10px;
    z-index: 10000;
  }
  .mb-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .prof-setting{
    position: absolute;
    top:-75px;
    right:20px;
  }
  .mb-link-img.active,
  .mb-link.active .mb-link-img.default {
    display: none;
  }
  .mb-link.active .mb-link-img.active {
    display: block;
  }
  .navbar-m {
    display: none;
  }
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0 !important;
  }
  .img-mobile {
    width: 35px;
    height: 35px;
    border-radius: 30px;
  }
  .int-prev-wrap {
    display: block;
    justify-content: flex-end;
  }

  /* .img-mobile.active {
    border: 1px solid #955cca;
  } */
}
@media (min-width: 576px) {
  .top-header,
  .o-mobile {
    display: none !important;
  }
  .sidebar .sidebar-brand {
    height: auto;
    padding: 10px;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    top: 0;
  }

  .setting-modal {
    width: 347px !important;
    height: 200px;
    top: 50px;
    right: 35px;
    position: fixed !important;
  }
  .setting-modal-title {
    width: 347px;
    height: 50px;
    padding: 19px, 16px, 19px, 25px;
    border: 0px, 0px, 1px, 0px;
    border-bottom: 1px solid #f4f4f6;
  }
  .in-content-wrapper {
    padding: 10px 40px;
  }
}
.profiles-name{
  margin-top: 2px;
  color:#4f4953;
  font-weight: 400;
}
/* @media (max-width: 1024px) {
  .profiles-name{
    display: none;
  }
} */
@media (min-width: 992px) {
  .main-content-wrapper {
    margin-left: 230px;
  }
}

.tab-menu{
  position: absolute;
  left:70px;
  top:20px;
}
.nav-close{
  position:absolute;
  color:white;
  font-size:24px;
  background: #362a41;
  padding: 10px;
  border-radius: 6px;
}
@media (min-width: 1024px) {
  .tab-menu{
    display: none;
  }
}
@media (min-height: 701px) and (max-height: 768px) {
  .nav-wrapper {
    min-height: 83vh;
  }
}
@media (min-height: 500px) and (max-height: 700px) {
  .nav-wrapper {
    min-height: 81vh;
  }
}
.MuiPaper-root {
  margin-top: 0 !important;
}
.ats-app-det-content {
  display: flex;
  justify-content: space-around;
}
.ats-pro-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ats-app-btn-wrapper {
  height: 43px;
  top: 14px;
  left: 117px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 8px;
  background: #c6cad2;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #362a41;
  font-size: 16px;
  font-weight: 700;
}
.ats-inputs-wrap {
  margin-top: 20px;
}
.ats-int-mail {
  display: inline-flex;
  align-items: center;
  margin: 5px;
  background-color: #f4e8ff;
  padding: 5px;
  border-radius: 8px;
  font-size: 12px;
}
.ats-int-mail-close-icon {
  margin-left: 10px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.ats-input-place {
  color: #888ca5;
  font-size: 12px;
  font-weight: 600;
}
.ats-int-time-wrap {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.ats-int-times-wrap {
  width: 50%;
}
.ats-int-option {
  display: flex;
  gap: 10px;
}
.ats-int-option > input {
  width: 24px;
}
.ats-int-option > label {
  margin-top: 10px;
}
.modalBody,
.modalFooter {
  padding: 20px;
  background-color: white;
}
.modalHeader img {
  /* position: absolute; */
  top: 10px;
  right: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.sub-apt {
  font-size: 20px;
  font-weight: 600;
}

.modalBody {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}
.mbody {
  max-height: 390px;
  padding: 20px;
  background-color: white;
  overflow-y: auto;
}
.mBody{
  max-height: 372px;
}
@media screen and (max-width: 1400px) {
  .mBody {
    padding: 10px 20px;
    /* max-height: 90vh; */
  }
  .mbody{
    max-height: 384px;
  }
}
@media (max-width: 1200px) {
  .modalbody{
    max-height: 500px;
    height:fit-content;
  }
  .sidebar .sidebar-brand {
    width:190px;
  }
}
@media (max-width: 1024px) {
  .modalbody{
    max-height: 450px;
    height:fit-content;
  }
}
@media (max-width: 820px) {
  .modalbody{
    max-height: 870px;
    height:fit-content;
  }
}
@media (max-width: 768px) {
  .modalbody{
    max-height: 720px;
    height:fit-content;
  }
}
@media screen and (max-height: 633px) {
  .modalBody {
    max-height: 390px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1950px) {
  .modalBody {
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 24px 24px;
}
.modalfooter {
  padding: 10px;
  background-color: #fff;
}
.modalfooters {
  padding: 30px 20px;
}
.modalFooters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-top: 1px solid var(--this-fourth);
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  background-color: #f4e8ff;
  border-radius: 8px;
}
.mTitle {
  color: var(--this-primary);
  font-family: var(--pf-medium);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.modalTitle {
  margin-top: 10px;
}
.modTitle {
  display: flex;
  gap: 10px;
}
.head-img {
  margin-top: -5px;
}
.open-message-div {
  width: 347px !important;
  height: 300px !important;
  border-radius: 24px !important;
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  background-color: white;
  z-index: 9999;

}



.open-message-div.search {
  right: 365px !important; /* Adjust this value to the width of openMessage + some margin */
  background-color: white;
  width: 350px !important;
  z-index: 9999
}
.open-message-div-mobile {
  border-radius: 24px !important;
  position: fixed !important;
}
.chat-message-container {
  max-height: 400px;  /* Adjust the height based on your design */
  overflow-y: auto;   /* Enables scrolling */
  padding: 10px;
  box-sizing: border-box;    /* Ensures padding doesn’t affect the max height */
  margin-top: 10px; /* Optional: Add spacing above the messages */
}

/* Optional: Style for conversation items */
.open-message-div.expanded {
  max-height: 400px; /* Height when expanded (adjust based on content) */
}

.open-message-div.collapsed {
  max-height: 50px; /* Only show the header */
}

.open-message-div-chat {
  width: 500px !important;
  height: auto !important;
  border-radius: 24px !important;
  position: fixed !important;
  bottom: 0px !important;
  right: 0px !important;
  background-color: white;
  z-index: 9999
}
.open-message-div-chat-mobile {
  width: 500px !important;
  height: auto !important;
  border-radius: 24px !important;
}
.open-message-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Inter-Medium, sans-serif;
  position: relative;
  margin: 0.5rem;
}
@media (min-width:600px) and (max-width: 768px) {
  .modalBody{
  max-height: 630px;
  }
}
@media (min-width:769px) and (max-width: 820px) {
  .modalBody{
  max-height: 800px;
  height:fit-content;
  }
}
@media (min-width:821px) and (max-width: 1024px) {
  .modalBody{
  max-height: 400px;
  height:fit-content;
  }
}
@media (min-width:1025px) and (max-width: 1200px) {
  .modalBody{
  max-height: 435px;
  height:fit-content;
  }
}
