.zoom-btn{
   font-size: 48px;

}
.btn{
    padding:8px;
    color: white;
}

.primary{
    background: #00b0ff;
}
.secondary{
    background: darkgrey;
}
.file-btn{
    display: none;
}
.container-menu{
    width:360px;
    margin: auto;
    background: white;
    height:520px;
    border-radius: 10px;
    margin-top: 24px;
}
.myp-edit-scroll{
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    margin-top: 20px;
    padding:0 10px;
}
.myp-edit-plus{
    margin:0px 20px 0 10px;
}
.p-4{
    padding-top: 8px;
}
.close-btn{
    margin-left: auto;
}
.options{
    padding-top: 36px;
}