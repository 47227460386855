.shimmer-card {
  display: flex;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to right, #fdfdfd 8%, #fdfdfd 18%, #fdfdfd 33%);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.shimmer-card-left {
  flex: 0 0 auto; /* Fix left side */
  margin-right: 20px; /* Spacing between image and text */
}

.shimmer-card-right {
  flex: 1 1 auto; /* Allow right side to grow */
}

.shimmer-img {
  width: 100px; /* Example width for left image */
  height: 100px; /* Example height for left image */
  background-color: #ddd; /* Example background color for image placeholder */
  border-radius: 50%; /* Example border radius for circular image */
}

.shimmer-title {
  height: 20px; /* Example height for title */
  background-color: #ccc; /* Example background color for title placeholder */
  margin-bottom: 10px; /* Example spacing between elements */
}

.shimmer-text {
  height: 14px; /* Example height for text */
  background-color: #eee; /* Example background color for text placeholder */
  margin-bottom: 6px; /* Example spacing between elements */
}

.shimmer-icon {
  width: 20px; /* Example width for icon */
  height: 20px; /* Example height for icon */
  background-color: #ccc; /* Example background color for icon placeholder */
  margin-left: auto; /* Align icon to the right */
}

.shimmer-card-bottom {
  display: flex;
  align-items: center;
}

/* Adjust as needed based on your design */
