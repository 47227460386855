.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.messageTop {
  display: flex;
  align-items: center; /* Ensures vertical alignment of content */
  justify-content: space-between; /* Ensures space between left and right */
  gap: 10px;
}
.messageUserAvatar {
  width: 40px; /* Set the width of the avatar */
  height: 40px; /* Set the height of the avatar */
  border-radius: 50%; /* Make the avatar circular */
  object-fit: cover; /* Ensures the image is contained within the circle */
  margin-left: 10px; /* Adjust space between the avatar and the content */
  cursor: pointer; /* Makes the avatar clickable */
}

.message.own .messageUserAvatar {
  margin-left: 0; /* Adjust avatar positioning when the message is owned by the user */
}
.messageLeft {
  display: flex;
  align-items: center;
}

.messageRight {
  margin-left: auto; /* Pushes the user avatar to the right */
  display: flex;
  align-items: center;
}

.messageImg,
.messageImgs {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.messageText {
  padding: 8px 12px; /* Add padding for better text spacing */
  background-color: #1877f2;
  color: white;
  max-width: 300px;
  display: inline-block; /* Ensures the text block takes only the space it needs */
  font-size: 14px;
  font-weight: 500;
  position: relative;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); /* Create the outline effect */
  border: none; /* Remove border if there was any */
  border-radius: 8px; /* Slight radius for soft edges */
}

.message.own .messageText {
  background-color: rgb(245, 241, 241); /* Change background color for own messages */
  color: black;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); /* Ensure outline stays consistent for own messages */
}


.chat-input {
  padding: 18px;
}

.mes-rep {
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
}

.messageBottom {
  font-size: 12px;
  margin-top: 5px;
}

.time-ago {
  font-size: 12px;
}

.message.own {
  align-items: flex-end;
}

/* .message.own .messageText {
  background-color: rgb(245, 241, 241);
  color: black;
} */

.main-chat-message {
  margin: 1rem;
}

.img-width-message {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.chatBoxBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.chatModal {
  max-height: 160px;
}

.left-side {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.emoji-wrap {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  overflow: auto;
}

.emoji-close {
  position: absolute;
  z-index: 1000;
  right: 3px;
  margin-top: 10px;
  background-color: #362a41;
  color: #fff;
  border-radius: 50%;
  padding: 4.5px 6px;
  font-size: 10px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.selectedInfo {
  margin: 0rem 2rem;
}

.file-name {
  font-size: 12px;
}

.att-close {
  position: absolute;
  z-index: 1000;
  right: 8px;
  margin-top: 0px;
  background-color: #362a41;
  color: #fff;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 10px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.mes-time {
  color: #a8a1ac;
}

.skin-tone {
  right: 5px !important;
}

.right-side {
  margin-left: auto;
}

.footer-header {
  flex: 1;
  margin-right: 10px;
  cursor: pointer;
}

.drop-select {
  cursor: pointer;
}

.chatSubmitButton {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.iconsContainer {
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.deleteIcon,
.replyIcon {
  cursor: pointer;
}

.deleteIcon:hover,
.replyIcon:hover {
  color: red;
}

.messageTop:hover .messageText {
  margin-top: -4px;
}
.messageActions{
  position: relative;
  display: flex;
  gap: 10px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .chatBoxBottom {
    padding-bottom: 20px;
  }

  .messageTop {
    flex-direction: column;
    align-items: flex-start; /* Stacks elements vertically on smaller screens */
  }

  .messageLeft {
    margin-bottom: 10px; /* Adds spacing between image/file and text on mobile */
  }
}
