.header-layout {
  background: white;
  position: fixed;
  z-index: 1000;
  width:100vw;
  margin-left: -210px;
}
.desk-head-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:8px;
}
.custom-sugg {
  margin-top: -20px;
}
.job-nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 250px 0 15px;
  background-color: white;
  position: fixed;
  z-index: 999;
  width: 100%;
  margin-top: 70px;
}
.job-nav-int{
  display: flex;
  justify-content: end;
}
.job-nav-containers {
  margin-top: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
  padding:0 40px;
  margin-left: 190px;
}
.job-nav {
  display: flex;
  gap: 40px;
}
.job-app-wrap {
  max-height: 73vh;
}
.job-card-app-wrap{
  display: flex;
  gap:10px;
  justify-content: center;
  min-height: 100vh;
}
.job-app-wraps {
  position: relative;
  top: 160px;
  background-color: #fff;
  border-radius: 24px;
  max-width: 645px;
  margin:20px 0;
  transition: all 0.4s;
}
.job-prev-btn {
  display: flex;
  gap:10px;
  justify-content: center;
  align-items: center;
  padding: 10px 0 10px 0;
}
.job-nav-link {
  color: var(--this-third);
  font-family: var(--pf-medium);
  padding: 30px 0;
  cursor: pointer;
  position: relative;
}

.job-nav-link.active {
  color: #362a41;
}
.job-nav-link.active:after {
  content: "";
  position: absolute;
  left: 20%;
  bottom: 4px;
  width: 50%;
  border-radius: 8px;
  height: 4px;
  background-color: #955cca;
}
.post-btn {
  position: relative;
  padding: 12px 24px; /* Changed padding format */
  border-radius: 8px;
  gap: 8px;
  background-color: rgba(54, 42, 65, 1);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center; /* Center text vertically */
  font-family: var(--pf-medium);
}
.underline {
  background-color: #955cca;
  border-radius: 4px;
  height: 120px;
  width: 4px;
}
.under-line{
  background-color: #955cca;
  border-radius: 4px;
  height: 95px;
  width: 4px;
  margin-top: 15px;
}
.underlines {
  margin-top: 10px;
}
.job-card-wrap {
  margin: 12px 0;
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  padding: 24px 17px 17px 0px;
  max-width: 503px;
  min-width: 503px;
  overflow: hidden;
  transition: all 0.55s;
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.job-card-wrapper {
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  padding: 24px 17px 17px 17px;
  max-width: 503px;
  min-width: 503px;
  max-height: 200px;
  margin: auto;
  overflow: hidden;
  transition: all 0.9s;
  cursor: pointer;
}
.job-card-wrappers {
  padding: 10px;
}
.search-int {
  max-width: 400px;
}
.myp-int-wrap {
  border: 2px solid #f4f4f6;
  display: block;
}
.job-des-height {
  overflow: auto;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 10px 0;
  background-color: #f4f4f6;
  gap: 10px;
  padding: 0 6px;
}
.job-des-height {  
  overflow: hidden; /* Prevents overflow */  
  transition: height 0.7s ease; /* Transition for height */  
}  

  

.job-des-height.expanded {  
  height: auto; /* Height when expanded */  
}  

.job-readmore {  
  cursor: pointer; /* Shows pointer for clickable */  
}  
.cFSDxL,
.grYyPn {
  border-radius: 50%;
  padding: 5px;
}
.rmdp-day.rmdp-today span {
  background-color: #362a41 !important;
}
.rmdp-arrow {
  color: #362a41 !important;
}
.rmdp-week-day {
  color: #362a41 !important;
}
.react-select__menu {
  z-index: 9999 !important; /* Set a high z-index value */
}

.react-datepicker-popper {
  z-index: 0 !important; /* Set a high z-index value */
}
.react-datepicker {
  margin-left: 40px;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  z-index: 0 !important;
}
.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}
.react-datepicker__day-names {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(189, 189, 189, 1);
}
.react-datepicker__day {
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  width: 42px;
  height: 42px;
  padding: 10px;
}
.react-datepicker__day-name {
  width: 42px;
}
.react-datepicker__day--today {
  background-color: #f6f6f6;
  border-radius: 50%;
  color: #000001;
}
.react-datepicker__current-month {
  color: rgba(130, 130, 130, 1);
  font-weight: normal;
  font-size: 20px;
}
.react-datepicker__triangle::after,
.react-datepicker__triangle::before {
  display: none !important;
}
@media (max-width: 1400px) {
  .react-datepicker__day {
    font-size: 14px;
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .react-datepicker__day-name {
    width: 36px;
    margin: 0;
  }
  .react-datepicker__current-month {
    font-size: 16px;
  }
  /* .job-nav-container{
    width: 84%;
  } */
  
}
/* @media (min-width:821px) and (max-width: 1400px) {
  .header-layout {
    width: 81%;
  }
} */
/* @media (min-width:1401px)and (max-width:2000px){
.header-layout{
  width: 85%;
}
} */
/* @media (min-width:2001px)and (max-width:3000px){
  .header-layout{
    width: 90%;
  }
  } */
  @media (max-width:992px){
    .header-layout{
      margin-left: 0;
    }
  }
  @media (min-width:992px){
  .header-layouts{
    margin-left:-210px;
  }
  .exp-job{
    margin-top: -20px;
  }
  }
@media (max-width: 1280px) {
  .job-app-wraps {
    /* width: 90%;
    left: 5%; */
    overflow: auto;
    max-height: 72vh;
  }
  .job-app-wrap {
    /* position: relative; */
    overflow: auto;
  }
  .job-nav{
    gap:20px;
  }
  
   .job-nav-container {
    width: 100%;
  }
  .job-nav-container {
    width: 100%;
    font-size: 12px;
    padding:0 250px 0 15px;
    justify-content: space-between;
  }
  .main-header-text{
    margin-left: 10px;
  }
}
@media (min-width:1025px) and (max-width:1200px) {
  .job-nav-contain {
    gap:26%;
  }
}
@media (min-width:768px) and (max-width:820px) {
  .job-card-wrap {
    display: none;
  }
  .job-app-wraps{
    max-height: 90vh;
    margin-top: 160px;
    position: inherit;
  }
  .job-card-app-wraps{
    display: flex;
    gap:5px;
    justify-content: start;
    min-height: 100vh;
  }
}


/* @media (min-width:768px) and (max-width:820px) {
  .job-nav-container {
    display: none;
  }
} */
@media (min-width:768px) and (max-width:1024px) {
  .job-nav-contains {
    gap:7%;
  }
  .desk-head-wrap{
    position: absolute;
    right:10px;
  }
}
@media (min-width:821px) and (max-width:1024px) {
  .desk-head-wrap{
    position: absolute;
    right:30px;
  }
}
@media (min-width:769px) and (max-width:820px) {
  .job-nav-contains {
    gap:15%;
  }
  .job-card-app-wraps{
    display: flex;
    gap:30px;
    justify-content: start;
    min-height: 100vh;
  }
}
@media (min-width:1025px) and (max-width:1180px) {
  .job-nav-contains {
    gap:19%;
  }
  
}
@media (min-width:821px) and (max-width:1024px) {
.job-nav-containers{
  width:97%;
}
}
@media (max-width:992px) {
  .job-nav-containers{
    margin-left: 0;
  }
}

/* .react-datepicker__header__dropdown, .react-datepicker__header__dropdown--scroll{
  color:#362A41 !important;
} */
.react-datepicker__navigation-icon::before {
  border-color: #362a41;
}
.react-datepicker-popper {
  z-index: 0 !important;
}
.react-datepicker__day--in-range {
  background-color: #362a41;
  border-radius: 50%;
  color: #fff;
}
.react-datepicker-popper {
  transform: translate3d(-30px, 0px, 0px) !important;
  inset: 165px auto auto -10px !important;
  padding-left: 20px;
}
.job-date-picker {
  border: 1px solid grey !important;
}
.react-datepicker__input-container {
  display: none;
  margin-left: 10px;
  top: 85px;
  left: 120px;
}
.job-content-wrapper {
  display: flex;
  padding: 0px;
  border-radius: 24px;
  min-width: 470px;
}
.share-job-title {
  cursor: pointer;
  color: #362a41;
  width: auto;
  font-family: var(--pf-medium);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.share-company-title {
  color: #9b939f;
  font-size: 14px;
  font-weight: 500;
}
.share-prowrap {
  background: #f4e8ff;
  padding: 5px 10px;
  border-radius: 28px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share-int-wrap {
  width: 80%;
  margin-left: 100px;
}
.share-setpro {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}
.share-probtn {
  top: 114px;
  left: 1104px;
  padding: 10px;
  gap: 0px;
  border-radius: 28px;
  background: #362a41;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
}
.job-title {
  cursor: pointer;
  color: #8653B6;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 700 !important;
  width: auto;
  font-family: var(--pf-medium);
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 5px;
}
.job-titles {
  cursor: pointer;
  color: #362A41;
  font-weight: 800 !important;
  width: auto;
  font-family: var(--pf-medium);
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 5px;
}
.company-title {
  color: #9B939F;
  font-size: 14px;
  font-family: var(--pf-regular);
  margin-bottom: 1px;
}
.timestamp {
  font-size: 10px;
  font-family: var(--pf-regular);
  color: var(--this-primary-lite);
  margin-top: 10px;
}
.app-card {
  border-radius: 24px;
}
.share-job-details {
  display: flex;
  padding: 20px 0px;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
}
.job-details-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.repo-pos{
  margin-top: 105px;
}
.job-details {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
  margin-top: 15px;
}
.job-details-card{
  justify-content: space-between;
}
.job-readmore{
  background-color: #FFF;
  border-bottom: 1px solid #F4F4F6;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 2px 4px 0px #00000040;
  margin-top: -10px;
  display: flex;
  width:100%;
  padding:5px;
  transition: all 2s;
  color:#362A41;
  cursor: pointer;
  height:43px;
  padding:13px 17px;
}
.job-readmore img{
  width:24px;
  margin:-5px 15px;
}
.job-readmore:hover{
  text-decoration: underline;
  text-underline-offset: 2px;
}
.about-mail {
  justify-content: flex-start;
}
.job-details2 {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  position: relative;
  margin-top: 10px;
}
.icon-title {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #4f4953;
  font-family: var(--pf-regular);
}
.icon-title {
  display: flex;
  align-items: center;
  gap: 5px;
}
.job-sal{
  font-size: 24px;
  color:#8653B6;
  font-weight: 900;
}
.icon-title-lo {
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #4f4953;
  font-family: var(--pf-regular);
  cursor: pointer;
}
.prof-name-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.prof-name-header img{
  margin-left: 0px;
}
.location-hover {
  display: none;
}
.icon-title-lo:hover .location-hover {
  display: flex;
  position: absolute;
  bottom: 30px;
  background-color: #362a41;
  padding: 5px;
  border-radius: 8px;
  color: #fff;
}
.jt-icon {
  color: grey;
  width: 20px;
}
.date-toggle-wrapper .react-toggle {
  margin-top: 8px;
}
.prefer-wrap {
  display: flex;
}
.prefer-wrap span {
  position: relative;
  cursor: pointer;
}

.applicant-wrap {
  display: flex;
  justify-content: flex-end;
}
.applicant-text {
  color: var(--this-secondary-strong);
  font-family: var(--pf-medium);
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 6px;
  padding: 8px 25px 8px 10px;
  background-image: url(../../images/icons/chevron-right.svg);
  background-repeat: no-repeat;
  background-position: 97% center;
  border-radius: 8px;
  cursor: pointer;
}
.applicant-text:hover {
  background-color: var(--this-fourth);
}

.job-preference {
  font-family: var(--pf-regular);
  font-size: 14px;
  font-weight: normal;
  color: var(--this-third);
}
.job-preference-pill {
  background: #f4e8ff;
  padding: 8px 10px;
  border-radius: 5px;
  font-family: var(--pf-regular);
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: var(--this-third);
  padding: 7px;
}

.job-pref-fill {
  background: #f4e8ff;
  border-radius: 5px;
  font-family: var(--pf-regular);
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: var(--this-third);
  padding: 5px 10px;
  margin-top: 5px;
}
.j-3-c {
  display: flex;
}
.j-3-c-wrapper {
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px var(--this-primary) 1a;
  padding: 30px 120px;
}
.button3 {
  font-family: var(--pf-medium);
}
.job-divider {
  border: 1px solid #f7f7f8;
  width: 100%;
}
/* Define a CSS class for the active state */
.j-3-c-wrapper.active {
  position: relative;
  border-radius: 24px;
  background: #eeeeee;
  box-shadow: 0px 4px 8px 0px var(--this-primary) 1a;
  padding: 30px 60px;
}
.j-3-h-text {
  font-family: var(--pf-regular);
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--this-primary);
}
.j-3-text {
  font-weight: normal;
  font-size: 28px;
  color: #8653b6;
  margin: 10px 0 10px 0;
  text-align: center;
}
.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-title,
.modal-title-sub,
.modal-b-title {
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: var(--this-primary);
}
.modal-b-title {
  text-align: center;
}
.share-btn {
  position: relative;
  background: #fff;
  width: 40px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(221, 223, 228, 0.5);
  border-radius: 8px;
  cursor: pointer;
}
.social-btn-holder {
  text-align: center;
}
.s-icon-holder {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddfe480;
  border-radius: 10px;
  margin-bottom: 10px;
}
.s-icon {
  color: #9a949f;
  cursor: pointer;
}
.sb-text {
  color: var(--this-third);
  font-size: 12px;
  font-family: var(--pf-medium);
  margin-top: 10px;
}
.delete-shield {
  background-color: #ffdae0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.ap-cl {
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  padding: 16px;
  margin-top: 9rem;
}
.ap-cls {
  position: relative;
  border-radius: 24px;
  background: #ffffff;
  padding: 24px;
  margin-top: 12.5rem;
}
.sort-head{
  color: #362A41;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}
.ap-card {
  display: flex;
  padding: 10px;
  border: 1px solid #f1f1f1;
  border-radius: 16px;
  margin-bottom: 15px;
}
.ap-image .name,
.ap-deg .deg,
.t-ques {
  margin: 18px 0 0 0;
  font-family: var(--pf-medium), sans-serif; /* Use Inter-Medium if available, otherwise fall back to sans-serif */
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: var(--this-primary);
  line-height: 19.2px;
  flex-wrap: nowrap;
}
.apd-ques {
  color: var(--this-third);
  font-family: var(--pf-regular);
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
.apd-ans {
  color: var(--this-primary);
  font-family: var(--pf-medium);
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
}
.bookmark-btn-container {
  cursor: pointer;
}
.bookmark-icon {
  width: 40px;
  cursor: pointer;
}
.job-btn,
.withdraw-btn {
  font-family: var(--pf-medium);
  background-color: var(--this-primary);
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}
.bs-btn {
  padding: 5px 12px;
  font-size: 14px;
}
.share-book {
  padding: 8px;
}
.share-signbtn {
  width: 200px;
}
.job-btn:hover {
  background-color: #fff;
  color: #362a41;
  border: 1px solid #362a41;
}
.withdraw-btn {
  background-color: white;
  border: 1px solid var(--this-primary);
  color: var(--this-primary);
}
.react-toggle-track-x {
  display: none;
}
.doc-submit-row {
  display: flex;
  gap: 10px;
  background: #f4f4f6;
  height: 88px;
  border-radius: 8px;
}
/* mobile */
@media (max-width: 380px) {
  .ap-applicant > div,
  .ap-image .name,
  .ap-deg .deg,
  .t-ques {
    font-size: 10px !important;
  }
}
/* tab & desktop */

@media (min-width: 1024px) and (max-width: 1440px) {
  .job-card-wrap {
    min-width: 403px;
  }
  .job-details2 {
    gap: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .job-app-wraps {
    /* left: 56%; */
    /* width: 42%; */
    margin-right: 20px;
  }
}
@media (min-width: 601px) and (max-width: 820px) {
  .job-app-wraps {
    top:95px;
    width:100%;
    left:0;
    border-radius: 0;
    }
}
@media only screen and (min-width: 1950px) {
  .job-app-wraps{
    /* left:31%; */
    /* width:67%; */
    max-height: 73vh;
  }
}

@media (min-width: 576px) {
  .job-card-container,
  .in-content-wrapper {
    padding: 10px 5px 10px 0;
    max-height: 99vh;
    overflow: scroll;
  }
  .sort-wrap{
    padding:15px;
  }
  .job-card-wrapper {
    margin-top: 12px;
    box-shadow: 0px 4px 8px 0px var(--this-primary) 1a;
    /* border: 2px solid #f4f4f6; */
  }
  .job-content-wrapper {
    gap: 20px;
  }
  .save-wrap {
    margin-top: 0;
  }

  .company-logo-container {
    width: 60px;
    height: 60px;
  }
  .company-logo-containers {
    width: 40px;
    height: 40px;
    margin-top: 25px;
  }
  .company-logo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid grey;
  }
  .c-logo {
    border: none;
  }
  .job-title-wrapper {
    margin-top: 10px;
  }
  .job-title {
    font-size: 16px;
  }
  .job-title-wrap {
    display: flex;
    gap: 20px;
    padding: 10px 20px;
  }
  .company-title {
    width: auto;
    font-size: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  .date-toggle-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    /* top: -70px; */
  }
  .date-toggle-wrappers {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .date-badge-wrapper {
    flex-shrink: 0;
  }
  .date-badge-wrapper span {
    font-size: 13px;
  }
  .job-details-container {
    margin: 0 0 10px 85px;
  }
  .job-details-containers {
    display: flex;
    width: 100%;
    gap: 37px;
    justify-content: space-between;
    padding: 0 10px;
  }
  .bs-trash {
    margin: 0 20px 0 0;
  }

  .prefer-wrap {
    align-items: center;
    gap: 10px;
  }
  .prefer-wrap span {
    top: 20px;
  }
  .jc-footer {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
  .j-3-c {
    padding: 10px 40px;
    margin-top: 15px;
    justify-content: space-between;
  }
  .j-3-c-wrapper {
    width: calc(88% / 3);
    padding: 15px;
  }
  .j-3-c-wrapper.active {
    padding: 15px;
  }
  .j-3-text {
    line-height: 38px;
  }
  .job-divider {
    margin-top: 10px;
  }
  .modal-title-sub {
    line-height: 22px;
  }
  .modal-b-title {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0em;
    position: relative;
  }

  .share-btn {
    /* left: 250px; */
    margin-top: 11px;
  }
  .delete-job-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ap-cl {
    height: auto;
    box-shadow: 0px 4px 8px 0px var(--this-primary) 1a;
  }
  .ap-qr-container {
    gap: 20px;
  }
  .ap-ques-box,
  .ap-resume {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 24px;
  }
  .ap-ques-box {
    width: 40%;
    flex-shrink: 0;
  }
  .ap-resume {
    flex-grow: 1;
  }
  .apd-head {
    color: #000;
    font-family: var(--pf-medium);
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
  }
  .apd-body {
    padding: 16px 8px;
    background-color: var(--this-fourth);
    border-radius: 16px;
  }
  .apd-qa-container {
    margin-bottom: 15px;
  }
  .apd-ques {
    margin-bottom: 8px;
  }
  .doc-submit-row {
    height: 88px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1024px) {
  .breadcrumbs {
    margin-left: 16px;
  }
  .in-content-wrapper,
  .job-card-container {
    padding: 20px 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1023px) {
  .job-nav-container {
    padding: 0 20px;
    margin-top: 90px;
  }
}
.date-toggle-wrapper, .date-toggle-wrappers {
  margin-left: auto;
}
.react-toggle-track-check {
  display: none;
}
.dh-setting-container svg {
  color: var(--this-primary);
}
.card-center {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}
.nodata-center {
  position: absolute;
  top: 29%;
  left: 47%;
}
.manage-nodata{
  display: flex;
  margin: auto;
  max-width:200px;
  height: 200px;
  width:100%;
  margin-top: 50px;
}
.nodata-info {
  margin-top: 10px;
  width: 50%;
}
.job-prev-back{
  width:40px;
  height:40px;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  margin-top: 20px;
  padding:4px;
}
@media (max-width: 576px) {
  .header-layout {
    width: 100%;
    margin-left: 0;
    position: fixed;
    top:0;
  }
  .nodata-center {
    left: 22%;
    top: 30%;
    max-width: 200px;
  }
  .search-section {
    width: 100%;
    position: relative;
    padding: 10px 0px 15px 0px;
  }
  .main-header-text {
    display: none !important;
  }
  .in-content-wrapper {
    padding: 0 15px;
  }
  .job-card-container {
    padding: 15px;
  }
  .job-card-wrapper {
    padding: 15px;
    margin-bottom: 20px;
    
  }
  .job-card-wrap {
    display: none;
  }
  .job-content-wrapper {
    gap: 15px;
    margin-bottom: 6px;
  }
  .company-logo-container {
    padding: 10px;
    background-color: #f4f4f6;
    border-radius: 12px;
    height: 70px;
    flex-shrink: 0;
  }
  .company-logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
  }
  .job-nav-container {
    padding: 0 16px;
    width: 100%;
    margin-left: 0px;
    position: relative;
    bottom: 10px;
  }
  .job-nav > div {
    padding: 25px 0px;
    font-size: 14px;
  }
  .job-nav {
    gap: 20px;
  }
  .job-title {
    font-family: var(--pf-medium);
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 3px;
  }
  .icon-title {
    font-size: 10px;
  }
  .job-title-wrapper {
    padding-top: 5px;
  }
  .company-title {
    font-size: 10px;
    margin-bottom: 8px;
  }

  .job-details {
    flex-wrap: wrap;
    gap: 10px !important;
  }
  .job-details2 {
    gap: 10px;
    margin-left: -10px;
  }
  .jc-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }
  .prefer-wrap {
    align-items: center;
    gap: 10px 13px;
  }
  .job-preference,
  .job-preference-pill {
    font-size: 12px;
    padding: 5px;
  }
  .job-preference-pill {
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .j-3-c {
    padding: 15px;
    margin-top: 15px;
    justify-content: space-between;
  }
  .j-3-c-wrapper {
    width: calc(88% / 3);
    padding: 15px;
  }
  .j-3-c-wrapper.active {
    padding: 15px;
  }
  .j-3-h-text,
  .j-3-text {
    font-size: 14px;
    font-family: var(--pf-medium);
  }
  .j-3-text {
    margin-top: 20px;
  }
  .job-divider {
    margin: 10px 0;
  }
  .modal-title,
  .modal-title-sub {
    font-size: 16px !important;
    font-family: var(--pf-medium) !important;
  }
  .modal-title-sub {
    margin-bottom: 8px;
  }
  .modal-b-title,
  .d-title {
    font-size: 16px !important;
    font-family: var(--pf-medium) !important;
  }
  .button3,
  .button5,
  .job-btn,
  .withdraw-btn {
    padding: 10px 15px !important;
    width: auto !important;
    height: auto !important;
    font-family: var(--pf-medium);
    font-size: 14px !important;
  }
  .bs-trash {
    transform: translate(-10px, -9px);
  }
  .prev-btn-wrap{
    display: flex;
    justify-content: end;
    margin-top: 5px;
  }
  .share-btn-container {
    width: 100%;
  }
  .share-btn {
    margin-left: auto;
  }
  .s-icon-holder {
    width: 40px;
    height: 40px;
  }
  .s-icon {
    width: 20px;
  }
  .sb-text {
    font-size: 10px;
  }
  .ap-cl {
    padding: 15px;
  }
  .ap-card {
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 10px;
  }
  .ap-card > div:nth-child(1),
  .ap-card > div:nth-child(2) {
    margin-bottom: 20px;
  }

  .ap-image,
  .ap-ques {
    width: 60%;
  }
  .ap-applicant,
  .ap-deg {
    width: 40%;
  }
  .ap-image .name,
  .ap-deg .deg,
  .t-ques,
  .apd-ans {
    font-family: var(--pf-medium);
  }

  .ap-ques svg,
  .grade-svg {
    width: 20px;
  }
  .ap-applicant > div {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
  .apd-head {
    font-size: 14px;
    font-family: var(--pf-medium);
    color: black;
    display: flex;
    align-items: center;
    gap: 0 8px;
    margin-bottom: 15px;
  }
  .apd-ques {
    font-family: var(--pf-light);
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 16px;
  }
  .apd-ans {
    padding-left: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .doc-submit-row {
    flex-wrap: wrap;
  }
  .doc-title {
    font-size: 12px !important;
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  .nodata-center {
    left: 27%;
  }
}
.star-main {
  position: relative;
  display: flex;
}
@media (min-width: 577px) {
  .hide-url {
    display: none;
  }
}
