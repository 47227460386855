@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
    background-color: #000101;


}

.lp-wrapper {
    --main-bg: #000101 !important;
    box-shadow: 0px -17px 250px -83px #8F16FD4A;
    /* --text-gradient-bg: linear-gradient(180deg, #ffffff 0%, #999999 100%); */
    --font-primary: "Poppins", sans-serif;
    background-color: var(--main-bg);
    font-family: var(--font-primary);
    --tc-primary: rgba(255, 255, 255, 0.7);
    overflow-x: hidden;
}

.lp-wrapper p {
    font-family: var(--font-primary);
}

.wrapper {
    background-color: #000101;
}

.ip-navbar .navbar-nav {
    background-color: transparent;
    height: auto;
    padding: 0;
    border-radius: 0;
    border-right: 0;
}

.ip-navbar .navbar-brand {
    width: auto;
    height: auto;
}

.ip-navbar .nav-link {
    background: linear-gradient(180deg, #ffffff 0%, #999999 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 400;
    height: auto;
}

.btn-t-primary,
.btn-t-primarie,
.btn-t-primarie-head

/* .btn-t-primary:focus  */
    {
    font-size: 14px;
    font-weight: 600;
    background-image: url(../images/landing/btn_bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    color: #FFF;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    line-height: 1.5;
}

.btn-t-primary:hover {
    text-transform: none;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(0, 0, 0, 0) 100%),
        radial-gradient(40.23% 100% at 50% 0%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),
        radial-gradient(54.41% 77.91% at 51% 100%, #7914D5 0%, rgba(52, 0, 100, 0) 100%);
    font-size: 16px;
}

.btn-t-primarie:hover, .btn-t-primarie-head:hover{
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(0, 0, 0, 0) 100%),
        radial-gradient(40.23% 100% at 50% 0%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),
        radial-gradient(54.41% 77.91% at 51% 100%, #7914D5 0%, rgba(52, 0, 100, 0) 100%);
    border: 1px solid;

}

.ip-navbar .btn-t-primary,
.btn-t-primarie, .btn-t-primarie-head {
    padding: 10px 20px;
}

.lp-banner {
    background-image: url(../images/landing/home-banner.svg);
    width: 100%;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: top;
    min-height: 900px;
    z-index: 3;
}

.banner-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 450px;
}

.banner-title>span {
    display: block;
}

.banner-title {
    font-size: 64px;
    font-weight: 400;
    background: linear-gradient(180deg, #FFFFFF 19.27%, #171717 110.31%);
    ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
}

.banner-text-container p {
    color: var(--tc-primary);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}

.banner-text-container .btn-t-primary {
    padding: 10px 35px;
}

.jcount-row {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.jc-divider {
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #7979793B;
    height: 120px;
}

.jc-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    color: var(--tc-primary);
    text-align: center;
}

.jc-number {
    font-size: 40px;
    font-weight: 500;
    background: linear-gradient(180deg, #FFFFFF 22.4%, #868686 92.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.jc-wrapper {
    /* background-image: url(../images/landing/Ellipse\ 3.svg); 
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-position: top;  */
    padding-top: 0px;
    margin-top: -230px;
    margin-bottom: 50px;
    z-index: 2;
}

.xw-container {
    margin-top: 40px;
}

.xw-container-wrapper {
    margin-bottom: 70px;
}

.xw-row,
.xf-row {
    display: flex;
    justify-content: space-between;
}

.xw-row>div {
    width: calc(95%/2);
}

.xw-short-card,
.xw-long-card {
    border: 2px solid #7171713d;
    background: #79797921;
    padding: 30px 30px 0 30px;
    border-radius: 24px;
    margin-bottom: 20px;
}

.xw-long-card {
    padding: 0;
}

.xw-c {
    padding: 30px;
}

.xw-i {
    padding-right: 30px;
}

.xw-i img {
    width: 100%;
    max-height: 422px;
    border-bottom-left-radius: 24px;
}

/* .ellipse-wrapper{
    position: relative;
    left:30%;
    bottom:10px;
} */
.ellipse {
    margin-top: -1250px;
    height: 1000px;
    width: 100%;
}

.ellipse2 {
    margin-top: -1050px;
    height: 1150px;
}

.ellipse-wrapper3 {
    display: flex;
    justify-content: center;
    margin-top: -50px;
}

.ellipse3 {
    margin-top: -330px;
    height: 1150px;
    /* margin-left: 200px; */
}

.xw-price-wrapper {
    margin-top: -950px;
    background-color: #000101;
    background-repeat: no-repeat;
    width: 100%;
    background-position: top;
    padding-bottom: 20px;
    background: rgba(121, 121, 121, 0.23);
    /* backdrop-filter: blur(37.75px); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 16.37%);

}

.xw-main-title {
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    margin-top: 130px;
    margin-bottom: 40px;
    background: linear-gradient(180deg, #FFFFFF 22.4%, #212121 92.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.xw-main-title2 {
    margin-top: 180px;
    padding-top: 30px;
}

.xw-title {
    font-size: 24px;
    font-weight: 500;
    color: white;
}

.xw-content {
    font-size: 16px;
    font-weight: 400;
    color: var(--tc-primary);
}

.xf-row {
    align-items: center;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    position: sticky;
}

.xf-row .xw-content {
    font-size: 24px;
}

.xf-c {
    width: 40%;
}

.xf-i {
    width: 60%;
    position: relative;
}

.xf-i2 {
    height: 600px;
    margin-left: 90px;
}

.xf-ic1 {
    position: absolute;
    top: 30px;
    right: 195px;
}

.xf-ic2 {
    position: absolute;
    top: 115px;
    right: 180px;
}

.xf-ic3 {
    position: absolute;
    top: 195px;
    right: 170px;
}

.xf-ic4 {
    position: absolute;
    top: 275px;
    right: 160px;
}

.xf-ic5 {
    position: absolute;
    top: 355px;
    right: 150px;
}

.xf-i2c1 {
    position: absolute;
    top: 110px;
    left: 110px;
}

.xf-i2c2 {
    position: absolute;
    top: 180px;
    left: 110px;
}

.xf-i2c3 {
    margin-top: 50px;
    position: absolute;
    top: 210px;
    right: 70px;
}

.xf-i2c4 {
    margin-top: 50px;
    position: absolute;
    top: 290px;
    left: 110px;
}

.xf-title {
    font-size: 14px;
    color: #B8B8B9;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
}

.xf-i-text {
    margin-bottom: 20px;
}

.tenX {
    background-image: url(../images/landing/10X.png);
    background-repeat: no-repeat;
    background-size: 480px;
    background-position: 17% 150px;
    /* margin-top: -100px; */
}

.xakal-arc2 {
    background-image: url(../images/landing/Ellipse\ 98.svg);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position-y: bottom; */
    min-height: 600px;
}

.music-icon {
    /* display: flex;
    justify-content: flex-end;
    margin-top: -80px; */
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
}

.btns-wrapper img {
    /* max-width: 1440px; */
    width: 100%;
    margin: auto;
    height: 600px;
    /* margin-top: -200px; */
}

.price-wrappers {
    width: 283px;
    height: 440px;
    background: #7979793B;
    border-radius: 24px;
    padding: 10px;
    background: rgba(121, 121, 121, 0.23);
    backdrop-filter: blur(37.75px);
    border-radius: 24px;
    /* box-shadow: 0px 181px 250px -212px rgba(143, 22, 253, 0.29); */
    border-top: 0.727275px solid rgba(255, 255, 255, 0.46);
}

.price-card {
    background: #797979;
    background: rgba(121, 121, 121, 0.23);
    color: #FFF;
    color: #B8B8B9;
    border-radius: 24px;
    border: 1px;
    padding: 15px;
    margin: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%,
        rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%;
    cursor: pointer;
}

.price-cards {
    color: #FFF;
    border-radius: 24px;
    padding: 15px;
    margin: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-top: 50px;
    text-align: center;
    letter-spacing: 1px;
    /* border: 0.2px solid #FFF; */
    background: linear-gradient(180deg, #8F16FD 0%, #34085D 100%),
        radial-gradient(54.73% 100% at 50% 0%, rgba(249, 242, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
    background: radial-gradient(54.73% 100% at 50% 0%, rgba(249, 242, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #8F16FD 0%, #34085D 100%);

}

.talk {
    margin-top: 185px;
}

.price-card img {
    margin-right: 10px;
}

.price-trial {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 2px;
    color: #B8B8B9;
    margin: 20px 0 0 20px;
}

.price-free {
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    color: #FFF;
    margin: 0px 0px 10px 20px;
}

.price-free span {
    font-size: 18px;
    color: #B8B8B9;
    display: inline-flex;
    line-height: 52px;
}

.plan {
    background-color: transparent;
    margin-top: 50px;
    border: 1px solid #7979793B;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 1px;
}

.price-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #B8B8B9;
    margin: 0 20px;
}

.price-wrapper {
    /* margin-left: 14%; */
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

/* .btns-wrapper{
    background-image: url("../images/landing/LANDING\ PAGE\ BOTTOM.png");
    background-position: center;
    margin-top: 100px;
    background-repeat: no-repeat;
} */
.price-container {
    margin-right: 20px;
    /* position: relative; */
}

.price-containers {
    margin-right: 20px;
    /* position: relative; */
}

.recom {
    margin-top: -40px;
    text-align: center;
    height: 50px;
    background-color: #7914D5;
    border-radius: 24px;
    background: linear-gradient(180deg, #8F16FD 0%, #34085D 100%),
    radial-gradient(54.73% 100% at 50% 0%, rgba(249, 242, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    width:235px;
    margin-left: 20px;
    align-items: center;
    color:rgba(255,255,255,1);
    z-index:0;
    /* position: absolute; */
    /* opacity: 0.5; */
}
.recom-text{
    padding-top: 10px;
}
.recom img {
    z-index: 1;
}

.growth {
    margin-top: 0px;
    z-index:999;
    /* opacity: 2; */
    margin-top: -10px;
}

.explore {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-offset: 10px;
    cursor: pointer;
    /* background: radial-gradient(630.95% 6471.61% at 113.33% -116.67%, #921EFF 14.98%, #E3C7FF 40%, #ECD9FF 53%, #D1A7FA 66%, #B574F1 100%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected, rgba(255, 255, 255, 0.7); */
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
        radial-gradient(630.95% 6471.61% at 113.33% -116.67%, #921EFF 14.98%, #E3C7FF 40%, #ECD9FF 53%, #D1A7FA 66%, #B574F1 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid #E3C7FF;
    width: 100px;
}
.explore-pro {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-offset: 10px;
    cursor: pointer;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
        radial-gradient(630.95% 6471.61% at 113.33% -116.67%, #921EFF 14.98%, #E3C7FF 40%, #ECD9FF 53%, #D1A7FA 66%, #B574F1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid #E3C7FF;
    width: 120px;
}
.navbar-lan{
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}
.btns {
    width: 521px;
    height: 81px;
    font-size: 24px;
    border-radius: 24px;
    position: relative;
    bottom: 450px;
    margin-top: -900px;
    /* margin-bottom: 50px; */
}

.btns:hover {
    font-size: 24px;
    border: 3px solid #FFF;
    transition: none;
}

.faq-wrapper {
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
    color: #000101;
}

.xakal-Faq {
    color: white !important;
    background-color: #000101 !important;
    line-height: 24px;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    margin-top: 30px;

}

/* .xw-faq {
    margin-top: -100px;
} */

.MuiAccordionSummary-expandIconWrapper {
    color: #FFF !important;
    background-color: #000101;
    border-radius: 50%;
}

.xakal-Faqs {
    color: #B8B8B9 !important;
    font-size: 16px;
    line-height: 24px;
}

.xakal-Faq-ans {
    /* border-bottom: 1px solid #ccc; */
    padding-bottom: 10px;

}

.xakal-Faq-ans img {
    padding-top: 10px;
    width:100%;
}

.accordion-contents {
    animation: scroll-out 1s ease-out;
    padding: 0px 10px 10px 10px;
    max-width: 1200px;
    margin: auto;
    margin-top: 10px;
}

.accordion-content {
    padding: 0px 10px 10px 10px;
    border-bottom: 1px solid #ccc;
    transition: 300ms cubic-bezier(0.25, 0.8, 0.25, 1);
    animation: scroll 1.2s ease-in;
    transition: all 1s;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
}

@keyframes scroll {
    0% {
        transform: translateY(600px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes scroll-out {
    100% {
        transform: translateY(-305px);
    }

    0% {

        transform: translateY(0);
    }
}

.copyright {
    margin-top: -60px;
    padding-bottom: 20px;
}

.copy-rights {
    margin-top: 50px;
}

.navbar-toggler-icon {
    background-color: #FFF !important;
    width: 40px;
    height: 40px;
    border-radius: 8px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    overflow: hidden;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* .xakalfor-wrapper{
    min-height: 100vh;
    overflow: scroll;
} */
@media (max-width:576px) {
    .banner-title {
        font-size: 30px;
    }

    .xw-main-title {
        font-size: 24px;
        background: linear-gradient(220deg, #FFFFFF 22.4%, #212121 92.71%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: #FFF;
        margin-top: 30px;
    }

    .xw-row {
        display: block;
    }

    .xw-row>div {
        width: calc(100%/1);
    }

    .xf-row {
        display: block;
    }

    .xf-c,
    .xf-i {
        width: 100%;
    }

    .xf-i2 {
        margin: 20px 0;
    }

    .jcount-row {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .jc-title {
        font-size: 12px;
    }

    .jc-number {
        font-size: 16px;
    }

    .faq-wrapper {
        margin: 20px;
    }

    .btns {
        width: 250px;
        height: 50px;
        font-size: 16px;
        border-radius: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .price-wrapper {
        margin-left: 50px;
    }

    .price-container {
        margin-top: 30px;
    }

    .price-containers {
        margin-right: 20px;
        margin-top: 100px;
    }

    .growth {
        margin-top: 50px;
    }
}

@media (max-width:992px) {
    .btn-t-primarie-head {
        display: none;
    }
}

@media (min-width: 992px) {
    .ip-navbar .navbar-nav {
        gap: 0 40px;
    }

    .scrolled {
        position: fixed !important;
        background-color: #0c0d0e;
        height:70px;
        width: 65%;
        border: 1px solid rgba(255, 255, 255, 0.15);
        top: 0px;
        padding: 0;
        /* margin-top: 5px; */
        left: 18%;
        border-radius: 100px;
    }

}

@media (min-width:600px) {
    .xf-row {
        max-height: 100vh;
        padding:100px;
    }
}
/* @media (max-width: 1000px) {
  .lp-wrapper .ip-navbar {
    width: auto !important;
    margin-left: auto !important;
  }
} */

section{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    animation: animateBg 50s linear infinite;
}
@keyframes animateBg {
    0%,100%
    {
        transform:scale(1);
    }
    50%
    {
        transform:scale(1.2);
    }
}
.span1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1)  ;
    
}
.span1::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
}
@keyframes animate {
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 0.5;
    }
    70%
    {
        opacity: 0.5;

    }
    100%
    {
        transform: rotate(315deg) translateX(-1500px);
        opacity: 0;

    }
    
}

@keyframes animated {
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 0.6;
    }
    70%
    {
        opacity: 0.6;

    }
    100%
    {
        transform: rotate(315deg) translateX(-1500px);
        opacity: 0;

    }
    
}

@keyframes animatedo {
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 0.25;
    }
    70%
    {
        opacity: 0.25;

    }
    100%
    {
        transform: rotate(315deg) translateX(-1500px);
        opacity: 0;

    }
    
}

.span1:nth-child(1){
    opacity: 0.25;
    top: 0;
    right: 0;
    left:initial;
    animation-delay:0 ;
    animation-duration: 5s;
    animation:  animate 3s linear 2 forwards;
}

/* span:nth-child(2){
    top: 0;
    right: 80px;
    left:initial;
    animation-delay:0.2s;
    animation-duration: 3s;
} */

.span1:nth-child(2){
    opacity: 0.5;
    top: 80px;
    right: 0px;
    left:initial;
    animation-delay:0.4s ;
    animation-duration: 2s;
    animation:  animated 3s linear 2 forwards;
}

/* span:nth-child(4){
    top: 0;
    right: 180px;
    left:initial;
    animation-delay:0.6s;
    animation-duration: 1.5s;
} */

.span1:nth-child(3){
    opacity: 0.75;
    top: 0;
    right: 400px;
    left:initial;
    animation-delay:0.8s;
    animation-duration: 2.5s;
    animation:  animatedo 3s linear 2 forwards;
}

/* span:nth-child(6){
    top: 0;
    right: 600px;
    left:initial;
    animation-delay:1s ;
    animation-duration: 3s;
} */
.span1:nth-child(4 ){
    opacity: 0.1;
    top: 300px;
    right: 0px;
    left:initial;
    animation-delay:1s ;
    animation-duration: 1.75s;
    animation:  animated 3s linear 2 forwards;
}

/* span:nth-child(8){
    top: 0px;
    right: 700px;
    left:initial;
    animation-delay:1.4s ;
    animation-duration: 1.25s;
} */

.span1:nth-child(5){
    opacity: 1;
    top: 0px;
    right: 1000px;
    left:initial;
    animation-delay:0.75s ;
    animation-duration: 2.25s;
    animation:  animatedo 3s linear 2 forwards;
}

/* span:nth-child(10){
    top: 0px;
    right: 1000px;
    left:initial;
    animation-delay:2.75s ;
    animation-duration: 2.25s;
} */




