/* TabSwitcher.css */

.toggleSwitchs {
  position: relative; /* Changed from relative to absolute for better centering */
  margin-top: 10rem;
  top: 26%;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 10px; /* Space between tabs */
  /* width: 570px; */
  margin: auto;
  background: #e6e6eb;
  padding: 8px;
  border-radius: 72px;
  color: #362a41;
  font-size: 18px;
  max-width: 570px;
  height:77px;
  cursor: pointer;
}
.switchClass {
  margin-top: 11rem;
}
.toggleSwitchss {
  margin-top: 40px;
  position: absolute; /* Changed from relative to absolute for better centering */
  left: 50%; /* Center horizontally in the middle of the container */
  transform: translate(
    -50%,
    -50%
  ); /* Offset by half of its width and height to center */
  align-items: center;
  display: flex;
  gap: 20px; /* Space between tabs */

  background: #e6e6eb;
  padding: 8px;
  border-radius: 30px;
  color: black;
}
.manage-wrap {
  display: flex;
  justify-content: space-between;
}
.manage-wrap .o-desk {
  margin-top: 5px;
}
.tabs {
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  padding: 0 20px;
}

.tabs.active {
  background-color: #fff;
  color: #362a41;
  border-radius: 30px;
  width:260px;
  height:61px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.content-switch {
  padding: 20px;
  flex-grow: 1; /* Ensure it takes available space */
}
.content-switches{
  margin-top: 60px;
}
@media  screen and (max-width:1024px) {
  .toggleSwitchs {
    width:70%;
    font-size: 16px;
  }
}
@media  screen and (max-width:820px) {
  .switchClass{
    margin-top: 12rem;
  }
}