.app-container {
  text-align: center;
  margin: 20px;
}
.progress-wrapper{
  margin-top:10px;
  border-radius: 8px;
  /* padding:8px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25); */
  display:flex;
  gap:10px;
}
.progress-bar {
  width: 100px;
  height: 13px; /* Adjusted height for a thinner progress bar */
  background-color: #eee;
  border-radius: 5px;
  overflow: hidden;
  /* margin: 10px 0; */
}

.progress-bar-slider {
  height: 100%;
  background-color: #8902be; /* Green color for the progress */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align the star to the right end */
  position: relative;
}

.star {
  /* color: white; */
  font-size: 13px;
}
