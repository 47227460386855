.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-banner {
  background: #5c338d;
  width: 300px;
}
.quote {
  position: relative;
  gap: 10px;
  display: flex;
  margin: 1rem;
  margin-left: 6rem;
}
.button-banner {
  background: #5c338d;
  border-radius: 10px;
  width: 50%;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-banner:hover{
  background-color: #FFF;
  border: 1px solid #5c338d;
  color:#5c338d;
}
.banner-div-interview {
  position: relative;
  display: flex;
  justify-self: center;
  margin-top: 20px;
  margin-left: 8rem;
}
.banner-image {
  width: 88%;
  height: 358px;
  margin-left: 19px;
  position: relative;
  bottom: 12px;
}
.why-choose {
  position: relative;
  text-align: center;
}
.para-button {
 text-align: center;
}
.banner-icon{
  margin-top: 10px;
    width: 30px;
}
.icon-lefts{
  display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 10px;
}