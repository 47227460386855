.company-card-row{
    display:flex;
    flex-wrap: wrap;
    gap: 20px 14px;
}
.company-card-row>div{
    width:32%;
}
.company-card {
  background-color: white;
  border-radius: 16px;
}
.top-grad-bg {
  width: 100%;
  height: 74px;
  object-fit: cover;
  border-radius: 16px;
}
.company-cont{
    margin-top: -18px;
    padding: 0 5px 5px 5px;
}
.cpp-container{
    text-align: center;
    margin-bottom: 10px;
}
.cpp-container >span{
    background-color:white;
    display: inline-block;
    padding: 3px;
    border-radius: 50%;
    box-shadow: 0px 4px 16px 0px #362A411F;
}
.company-profile-pic {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.oc-title{
    font-size: 16px;
    font-family: var(--pf-medium);
    color: #362A41;
    text-align: center;
    cursor: pointer;
}
.oc-cont{
    font-size: 12px;
    text-align:center;
    font-family: var(--pf-regular);
    color: var(--this-primary-lite);
}
@media (max-width:500px){
    .company-card-row{
        margin-top: 160px;
        gap:20px 70px;
    }
    .company-card{
        min-width: 170px;
    }
    .oc-title{
        font-size: 12px;
    }
}
@media (min-width:600px) and (max-width:1200px){
    .company-card-row{
        margin-top: 180px;
    }
}