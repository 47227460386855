html {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: var(--pf-medium), sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  overflow-x: hidden !important;
  /* overflow-y: auto !important; */
  padding: 0 !important;
}
.heading-weight {
  font-family: "Inter-Bold" !important;
  font-weight: normal;
}

.heading-size {
  font-size: 16px !important;
}

.button-size {
  font-size: 14px !important;
}

.grid-cont {
  font-family: "Inter-Bold" !important;
  font-weight: normal;
  font-size: 12px !important;
}
.label-weight {
  font-family: "Inter-Bold" !important;
  font-weight: normal;
}

.download {
  color: black;
}

.vertical-border {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
}
.vertical-borders {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
}

.vertical-border-head {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
  border-top-width: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Inter-Bold";
  src:
    local("Inter-Bold"),
    url("./fonts/Inter/Inter-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "Inter-Regular";
  src:
    local("Inter-Regular"),
    url("./fonts/Inter/Inter-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Inter-Medium";
  src:
    local("Inter-Medium"),
    url("./fonts/Inter/Inter-Medium.ttf") format("opentype");
}

:root {
  --pf-thin: "Inter-Regular";
  --pf-light: "Inter-Regular";
  --pf-regular: "Inter-Regular",sans-serif;
  --pf-medium: "Inter-Medium",sans-serif;
  --pf-bold: "Inter-Bold",sans-serif;
  --this-primary: #362a41;
  --this-primary-strong: #1f1a24;
  --this-primary-lite: #a8a1ac;
  --this-secondary: #955cca;
  --this-secondary-strong: #714f90;
  --this-third: #4f4953;
  --this-fourth: #f7f7f8;
  --this-fifth: #ff5969;
  --this-fifth-strong: #ff405f;
  --this-border: 1px solid #f4f4f6;
  --this-border-2: 1px solid #362a411a;
}

.admin {
  margin-top: 150px;
  padding-left: 10%;
  padding-right: 5%;
  overflow: auto;
}
.admins {
  padding-left: 10%;
  padding-right: 5%;
  overflow: auto;
}
.MuiBox-root > .ToolbarItem-root:nth-child(6) {
  display: none;
}

