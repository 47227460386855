.dh-setting-container .dropdown-menu{
    left: unset;
    right:0;
    top: 35px;
    border-radius: 24px;
    box-shadow: 0px -4px 16px 0px #00000014;
    padding: 0;
    min-width: 190px;
    overflow: hidden;
}
.dh-setting-container .dropdown-toggle::after{
    display: none;
}
.dh-setting-container .dropdown-item, .dh-setting-container .dropdown-item:active{
    font-size: 14px;
    font-family: var(--pf-medium);
    color: var(--this-primary);
    padding: 20px 10px 20px 50px;
    border-bottom: var(--this-border);
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 20px;
    cursor: pointer;
    background-color: white;
}

.iCpass{
    background-image: url(../images/icons/cpass.svg);
}
.iCbank{
    background-image: url(../images/bank.svg);
}
.iChelp{
    background-image: url(../images/help.svg);
}
.iLogout, .dh-setting-container .iLogout:active{
    background-image: url(../images/icons/logout.svg);
    color: var(--this-fifth)!important;
}
.btn-cancel, .btn-cancel:hover, .btn-cancel:active{
    background-color: white;
    color: var(--this-primary)!important;
    border: var(--this-border-2);
}
.btn-logout, .btn-logout:hover, .btn-logout:active{
    background-color: var(--this-fifth-strong);
    color: white;
}
.modalFooter .btn{
    font-family: var(--pf-medium);
    border-radius: 8px;
    padding: 8px 20px;
}
.dh-modal .modalBody p{
    font-family: var(--pf-regular);
    font-size: 16px;
    letter-spacing: 0.5px;
}
.desk-header{
    background-color: white;
}
.search-section-container {
    /* min-width: 500px; */
    height:56px;
    margin: auto;
    padding: 0 0 0 95px;
  }
  .search-section-containers {
    min-width: 560px;
    height:56px;
    margin: auto;
    padding: 0 0 0 95px;
  }
@media only screen and (min-width: 1440px)  {
    .search-section-container {
        min-width: 560px;
        height:56px;
        margin: auto;
        padding: 0 0 0 0px;
      }
}

@media (min-width:760px) and (max-width:1024px) {
    .search-section-container {
        /* min-width: 230px; */
        margin:0 auto 0 10px;
      }
    .search-section-containers {
        min-width: 370px;
        margin:0 auto 0 50px;
    }
}
@media (min-width:1024px) and (max-width:1180px) {
    .search-section-container {
        /* min-width: 330px; */
        padding: 0;
      }
    .search-section-containers {
        min-width: 470px;
    }
    
}
@media (min-width:770px) and (max-width:820px) {
    
    .search-section-containers {
        margin:0 20px 0 100px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 992px) {
    .desk-header {
      padding: 20px 20px !important;
      margin-left: 0px;
    }
    .main-header-text {
        margin-left: 5px;
      }
    .search-section-container, .search-section-containers{
        padding:0;
    }

}
@media only screen and (max-width: 576px) {
    .desk-header {
      display: flex;
      margin-bottom: 20px;
    }
    .search-section-container, .search-section-containers {
        min-width: 300px;
        margin:0 20px 0 20px;
        padding:0;
      }
}
@media (min-width: 993px) {
    .desk-header{
        margin-left: 210px;
    }
}
@media (min-width: 576px) {
    .desk-header {
        padding: 10px 40px 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: var(--this-border);
      }
      .desk-setting-icon{
        width:24px;
        height:24px;
        margin-left: 20px;
      }
      .main-header-text {
        color: var(--this-primary);
        font-family: var(--pf-bold);
        font-size: 20px;
      }
      .main-header-texts{
        margin-left: 25px;
      }
      .search-section {
        width: 100%;
        height: 50px;
        position: relative;
        top: 0px;
        border: 1px solid whitesmoke;
        border-radius: 10px;
        font-style: italic;
      }
}
@media (min-width:768px) {
    .dh-setting-container .dropdown-menu{
        width: 230px;
    }
}
