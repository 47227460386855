.int-row {
  display: flex;
  --primary-lite-2: #f3eef8;
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: 165px; */
}
.int-rows:nth-child(1) {
  margin-top: 165px;
}
.int-row-man:nth-child(1){
  margin-top: 245px;
}
.int-book-container {
  padding: 15px 10px 15px 10px;
}
.int-container{
  padding-bottom:7px;
}
.int-name{
  display: flex;
  gap:10px;
  margin-top: 5px;
  color: #4f4953;
  font-size: 14px;
  font-weight: 100 !important;
}
.int-names{
  color: #4f4953;
  font-size: 14px;
  font-weight: 400 !important;
  margin-bottom: 20px;
}
.int-by{
  color:#4f4953;
  font-size: 14px;
  opacity: 0.5;
}
.int-dur-cost{
  display: flex;
  gap:20px;
  color: #8653B6;
  font-size: 24px;
  font-weight: 900;
  margin: 20px 40px;
}
.int-dur-cost img{
  width:20px;
}
.int-dur-img{
  margin-bottom: 24px;
}
.int-dur-cost-mon{
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
}
.int-container {
  width: 100%;
  background-color: white;
  gap: 10px;
  cursor: pointer;
  transition: all 0.9s;
}
.int-contain {
  width: 38%;
  display: flex;
  background-color: white;
  gap: 10px;
  cursor: pointer;
  padding: 10px 10px 15px 10px;
  border-radius: 16px;
  transition: all 0.55s;
}
.int-contains {
  width: 36%;
}
.int-img-container {
  width: 30%;
}
.read-more {
  color: blue;
  cursor: pointer;
}
.des-text {
  color: #666666;
  font-size: 14px;
  line-height: 1.6;
}
.int-img {
  height: 140px;
  border-radius: 16px;
  object-fit: cover;
  padding: 5px;
  text-align: center;
  padding-top: 60px;
  background-color: #bbaccd;
  color: #fff;
  text-transform: uppercase;
}
.int-content-container {
  width: 100%;
  margin-left: 0px;
}
.int-manage{
  padding-top:10px;
}
.date-select{
  display: none;
}
.int-cont-contain {
  width: 50%;
  padding: 6px;
}
.skill-wrap {
  display: flex;
  justify-content: space-between;
}
.int-title-row {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 12px;
  border-bottom: 1px solid grey;
  width: 100%;
}
.search-int-title-row{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 12px;
  border-bottom: 1px solid grey;
  width: 100%;
}
.title {
  cursor: pointer;
  color: var(--this-primary);
  font-family: var(--pf-medium);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 5px;
  color:#8653B6;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.titles{
  margin-bottom: 0;
}
.int-titles {
  cursor: pointer;
  display: flex;
}
.int-cont {
  line-height: 18px;
  font-size: 13px;
  padding-right: 30px;
}
.int-creator {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 12px 0;
  background: #F4F4F6;
  padding:5px;
  border-radius: 8px;
}
.image-shrink {
  border-radius: 50%;
}
.pen-int-creat {
  width: 100%;
}
.int-details {
  margin: -1px 0 0 8px;
  width:50%;
}
.int-tit-save {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ver-line{
  width:1px;
  height:60px;
  background:grey;
  margin:0 10px 0 -10px;
}
.int-cre-lite {
  font-size: 16px;
  font-family: var(--pf-regular);
  color: var(--this-primary-lite);
  display: inline-block;
  margin-right: 5px;
}
.int-cre-title {
  font-size: 14px;
  font-family: var(--pf-medium);
  color: var(--this-third);
  font-weight: 400 !important;
}
.int-title-row .timestamp {
  margin-left: auto;
}
.int-book-container {
  width: 20%;
  background-color: var(--primary-lite-2);
}
.int-book-contain {
  width: 15%;
  padding: 10px 10px 15px 10px;
  border-radius: 0 24px 24px 0;
  background-color: var(--primary-lite-2);
}
.int-creatorShrink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
.int-time-ago {
  bottom: 12px;
  position: relative;
  margin-left: auto;
}
.int-book-containers {
  display: none;
}
.int-book-containershrink {
  /* display: flex;
  justify-content: space-between;
  margin-top: 50px; */
  /* margin-top: 10px; */
}
.int-preview {
  position: fixed;
  top: 165px;
  /* width: 70%; */
  /* right: 2rem; */
  transition: all 1s;
}
.int-previews {
  position: fixed;
  top: 175px;
  width: 95%;
  margin: 20px 15px;
  transition: all 1s;
}
.int-previews-p {
  position: fixed;
  top: 115px;
  width: 40%;
  right: 75px;
  transition: all 3s;
  /* border: 3px solid #f4f4f6; */
  border-radius: 24px;
}
.int-previews-pr {
  position: fixed;
  top: 630px;
  width: 30%;
  right: 75px;
  transition: all 3s;
  /* border: 3px solid #f4f4f6; */
  border-radius: 24px;
}
.int-previews-opr {
  position: fixed;
  top: 560px;
  width: 30%;
  right: 75px;
  transition: all 4s;
  /* border: 3px solid #f4f4f6; */
  border-radius: 24px;
}
.int-prev-cont {
  width: 100%;
  border-radius: 0 0 24px 24px;
}
.int-creator-img {
  width: 70px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.int-creat-img{
  width:60px;
  margin-bottom: 10px;
}
.rm-btn {
  color: rgba(98, 98, 245, 1);
}
.prefer-wrap {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.prefer-wraps {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.search-prefer-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.job-preference {
  color: var(--this-primary-lite);
  font-family: var(--pf-regular);
  font-weight: normal;
  font-size: 13px;
}
.job-preferences {
  color: var(--this-primary-lite);
  font-family: var(--pf-regular);
  font-weight: normal;
  font-size: 12px;
}
.image-shrink {
  width: 64px;
  height: 64px;
  margin-top: 15px;
}
.job-preference-pill {
  font-size: 13px;
  padding: 10px;
  display: flex;
  gap: 15px;
}
.job-pref-plus {
  cursor: pointer;
}
.b-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.int-slot{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.b-slots {
  display: flex;
  justify-content: center;
  height: 100%;
}
.b-slots .timer {
  background-color: #bbaccd;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border-radius: 16px;
  padding: 3px 10px;
  font-size: 12px;
  color: white;
  font-family: var(--pf-medium);
}
.b-slot .timer {
  background-color: #bbaccd;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  font-family: var(--pf-medium);
}
.timer > img{
  color:#fff;
}
.clock-icon {
  width: 16px;
}

.b-price {
  font-size: 16px;
  color: var(--this-primary);
  text-align: center;
  margin-top: -5px;
  margin-bottom: 5px;
}
.b-priceshrink {
  font-size: 24px;
  color: var(--this-primary);
  display: flex;
  margin-top: 5px;
}
/* booked interview */
.b-slot.bi .timer {
  border-radius: 5px;
  background-color: var(--this-secondary);
  padding: 5px;
  font-size: 11px;
  margin: 10px 0;
}
.b-slots.bi .timer {
  border-radius: 5px;
  background-color: var(--this-secondary);
  padding: 5px;
  font-size: 11px;
  margin: 10px 0;
}
.int-book-wrap {
  display: flex;
  justify-content: center;
}
.book-timer {
  background-color: #f6f6f6;
  color: #362a41;
}
.int-re-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}
.int-re-wrap-in {
  display: flex;
  gap: 5px;
}
.int-re-wrap-in > img {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}
.apt-test-btn {
  background-color: #362a41;
  color: #fff;
  border: 1px solid #362a41;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.apt-test-btn:hover {
  background-color: #fff;
  color: #362a41;
  border: 1px solid #362a41;
}
.b-slot.bi .clock-icon {
  width: 20px;
}
.bi-dt {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-family: var(--pf-medium);
  color: var(--this-primary);
  margin-top: 8px;
}
.dt-icon {
  width: 26px;
}
.int-slider-wrap {
  height: 140px;
}
.int-slider-wraps {
  display: none;
}
.avail-day {
  display: flex;
  gap: 16px;
  margin-left: -10px;
  margin-bottom: 10px;
}
.job-card-container {
  margin-top: 0px;
  overflow: hidden;
}
/* .job-card-containers{
  margin-top: 0px;
} */
@media (min-width: 1400px) {
  .job-card-container {
    padding: 20px 85px;
  }
}
.int-slider-wrapper {
  width: 40%;
  position: relative;
  overflow: hidden;
}

.int-slider-img {
  width: 100%;
  height: 150px;
  /* object-fit: cover;   */
}
.int-slider-wrapper,
.int-slider-img,
.int-video-container {
  border-radius: 16px;
  position: relative;
  display: flex;
  /* top:50px; */
}
.int-slider-image {
  position: relative;
}
.int-slider-images {
  width: 200px;
  height: 150px;
}
.int-slider-wrapper .slick-dots {
  bottom: -50px;
}
.int-slider-wrapper .slick-dots li {
  margin: 0;
}
.int-slider-wrapper .slick-dots li button:before {
  font-size: 10px;
  color: #dddfe4;
  opacity: 1;
}
.int-slider-wrapper .slick-dots li.slick-active button:before {
  font-size: 16px;
  color: var(--this-primary);
  opacity: 1;
}
.int-slider-wrapper .slick-prev:before,
.int-slider-wrapper .slick-next:before {
  display: block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.int-slider-wrapper .slick-prev:before {
  content: "";
  background-image: url(../../images/icons/chervron-left-primary.svg);
}
.int-slider-wrapper .slick-next:before {
  content: "";
  background-image: url(../../images/icons/chervron-right-primary.svg);
}
.int-video-container {
  background-repeat: no-repeat;
  background-size: cover;
}
.int-video-container .play-icon {
  width: 40px;
}
.int-slider-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 1500px) {
  .int-slider-wrapper {
    width: 40%;
  }
  .int-slider-wrapper {
    height: max-content;
  }
  .int-content-container {
    width: 100%;
  }
}
.boost-your-availability {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-left: 6rem;
  margin-right: 6rem;
  margin-top: 20px;
  top: 150px;
}
.close-pointer {
  cursor: pointer;
}
.interweek {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
  right: 35px;
  gap: 10px;
}
.share-saved-interview {
  position: relative;
  top: 5px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  cursor: pointer;
}
.pending-flex {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}
.pending-int{
  margin-top: 60px;
  margin-bottom: 30px;
  padding-top: 20px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  gap:20px;
  justify-content: space-between;
  padding: 20px;
}
.pending-push {
  margin: 20px;
  display: flex;
  gap: 20px;
}
.image-push {
  width: 150px;
  height: 150px;
}
.flex-button {
  position: relative;
  display: flex;
}

.asses-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4f4953;
  transform: translate(-50%, -50%);
  padding: 5rem;
  margin-left: 50%;
  margin-top: 10%;
  width: 100%;
}
.asses-cons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4f4953;
  transform: translate(-50%, -50%);
  padding: 5rem;
  margin-left: 50%;
  margin-top: 25%;
  width: 100%;
}
.asses-cont {
  margin-top: 20px;
}
.asses-start {
  display: flex;
  margin-top: -8%;
  justify-content: center;
}
.css-3qtu8e {
  background-color: #f4e8ff !important;
}
.css-14zd9k-MuiGrid-root > .MuiGrid-item {
  margin-left: 50px;
}
li {
  margin-top: 10px;
}
.css-kz1208 > .MuiGrid-item {
  margin-left: 35px;
}
/* .int-btn-wrap{
  display: flex;
} */
.css-21ve8j.Mui-selected {
  color: #fff !important;
  border: 1px solid #362a41 !important;
  background-color: #362a41 !important;
}
.css-21ve8j.Mui-selected:hover {
  color: #362a41 !important;
  border: 1px solid #362a41 !important;
  background-color: #fff !important;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 0 !important;
}
.asses-quess {
  color: #362a41;
  margin-top: -50px;
}
.asses-ques {
  color: #4f4953;
  margin-top: -10px;
}
.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff !important;
  border: 1px solid #362a41 !important;
  background-color: #362a41 !important;
}
.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  color: #362a41 !important;
  border: 1px solid #362a41 !important;
  background-color: #fff !important;
}
.asses-btn {
  width: 180px;
  background-color: #362a41;
  color: #fff;
  padding: 10px;
  border: 1px solid #362a41;
  border-radius: 8px;
}
.asses-btn:hover {
  color: #362a41;
  background-color: #fff;
  border: 1px solid #362a41;
}
.apt-wrap {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: end;
  margin-bottom: 20px;
}
.buttonOne {
  background: #362a41;
  border-radius: 10px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.buttonOne:hover{
  background-color: #fff;
  color:#362a41;
  border: 1px solid #362a41;
}
.buttonTwo {
  background: #dbd9dd;
  border-radius: 10px;
  height: 40px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.card-border {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px #00000040;
  padding: 14px;
  margin: 10px 50px 20px 0;
}
.card-image-company {
  width: 64px;
  height: 64px;
  border-radius: 50px;
}
.interview-exp {
  position: relative;
  display: flex;
  gap: 21px;
}
.int-comp-name{
  color: #4f4953;
  font-size: 14px;
  font-weight: 400;
}
.interview-exps {
  position: relative;
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.interview-expss {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding:10px 0 0px 0;
  border-top: 1px solid #f7f7f8;
}
.top-exp {
  margin-top: 10px;
  padding:24px 24px 0px 24px;
}
.top-exps{
  padding:0 24px 0 24px;
}
.int-det{
  display: flex;
  gap:10px;
}
.int-prev{
  display: flex;
  gap:50px;
}

.shimmer {
  background: linear-gradient(
    to right,
    #f6f6f6 8%,
    #f0f0f0 18%,
    #f3eef8 33%
  ) !important;
  background-size: 1000px 100% !important;
  animation: shimmer 3s linear infinite forwards !important;
}
.shrink-book-div {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
@keyframes shimmerAnimation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@media only screen and (min-width:821px) and (max-width: 1024px) {
  .int-preview {
    position: fixed;
    top: 165px;
    width: 40%;
    right: 18px;
    transition: all 0.5s;
  }
  .int-previews {
    position: fixed;
    top: 165px;
    width: 40%;
    right: 18px;
    transition: all 1.5s;
  }
  .int-tab{
    display: none;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .int-preview {
    position: fixed;
    top: 275px;
    width: 40%;
    right: 18px;
    transition: all 0.5s;
  }
  .int-previews {
    position: fixed;
    top: 185px;
    width: 40%;
    right: 18px;
    transition: all 0.5s;
  }
}
@media only screen and (min-width: 1366px) {
  .int-preview {
    position: fixed;
    top: 225px;
    width: 45%;
    right: 18px;
    transition: all 0.5s;
  }
  .int-previews {
    position: fixed;
    top: 175px;
    width: 45%;
    right: 18px;
    transition: all 0.5s;
  }
}
@media only screen and (min-width: 1441px) {
  .int-preview {
    position: fixed;
    top: 275px;
    width: 50%;
    right: 18px;
    transition: all 0.5s;
  }
  .int-previews {
    position: fixed;
    top: 175px;
    width: 50%;
    right: 20px;
    transition: all 0.5s;
  }
}
@media only screen and (min-width: 1950px) {
  .int-previews, .int-preview{
    width:68%;
  }
}
.icon-left {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
  cursor: pointer;
}
.loading-div {
  margin-top: 161px;
}
@media (max-width: 576px) {
  .prefer-wraps {
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
  }
  .int-rating{
    font-size: 12px;
  }
  .int-rating img{
    width:16px;
    margin-top: -5px;
  }
  .boost-your-availability {
    top:0;
    margin:auto;
    padding:0 15px;
    justify-content: center;
  }
}
.classone {
  width: 100px;
  height: 100px;
}
.closeBanner {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width:100%;
}
.main-banner {
  position: relative;
  display: flex;
  gap: 100px;
}
.font-image-size {
  font-size: 15px;
  margin-top: 5px;
}
.content-message{
  position: absolute;
    margin-top: 30px;
}
.button-div-pending-banner{
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  
}
.button-div-pending-banner span{
  margin:10px;
  cursor: pointer;
}
@media (max-width: 820px) {
  .int-rows:nth-child(1){
    margin-top: 195px;
  }
  .pending-flex{
    top:185px;
  }
  .int-preview{
    top:215px;
    margin-left: 10px;
  }
}
@media (max-width: 576px) {
  .job-preference-pill {
    padding: 5px;
  }
  .int-rows:nth-child(1) {
    margin-top: 15px;
  }
  .int-preview{
    top:195px;
    width:85%;
  }
  .int-previews{
    width:90%;
  }
  .int-container{
    padding: 0;
  }
  .int-creator{
    background: transparent;
    padding: 0;
  }
  .int-title-row{
    border-bottom: 0;
  }
  .int-prev, .int-det{
    display: block;
  }
}