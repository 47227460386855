.button2 {
    background: #362a41;
    border-radius: 10px;
    width: fit-content;
    color: white;
    border: 1px solid #362a41;
  }
  /* .button2 span{
    margin-left: 3px;
  } */
  /* .btn2{
    width:119px;
  } */
  .button2:hover {
    background: white;
    border: 1px solid #362a41;
    color: #362a41;
    font-weight: bold;
  }
  @media (max-width:1440px){
    .button2{
      width:fit-content;
    }
  }
  .button3 {
    background: #362a41;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button3:hover {
    background: white;
    border: 1px solid #362a41;
    color: #362a41;
    font-weight: normal;
  }
  .spiceLogo{
    width: 30px;
    height: 30px;
    border-radius: 40px;
  }