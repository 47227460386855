.order-main {
  width: 679px;
  top: 20px;
  left: 20%;
  border-radius: 24px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  margin-bottom: 40px;
}
.order-img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}
.order-pad {
  padding: 25px;
}
.order-part {
  display: flex;
  gap: 10px;
}
.imgAvatar {
  width: 313px;
  height: 172px;
  top: 88px;
  left: 24px;
  border-radius: 16px;
}
.separate-order {
  display: flex;
  gap: 0px;
  height: 64vh !important;
}
.payment-summary {
  margin: 20px 25px 25px 25px;
}
.promo-label {
  position: relative;
  display: flex;
}
.promo-button {
}
.promo-error {
  color: #ff596a;
  font-size: 12px;
  border-radius: 0 0 8px 8px;
  background: #ff487333;
  text-align: center;
  padding: 8px;
}
.promo-apply {
  color: #25aa5a;
  font-size: 12px;
  border-radius: 0 0 8px 8px;
  text-align: center;
  padding: 8px;
  background: #25aa5a33;
}
.os-name {
  font-size: 16px;
  margin-top: 5px;
}
.os-int-wrapper {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}
.promo-dis {
  color: #25aa5a;
}
.price-container {
  width: 84%;
}
.price-wrap {
  display: flex;
  justify-content: space-between;
}
.conbtn {
  width: 280px;
  margin-top: 20px;
}
.icon-wrapper {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}
.edit-icon {
  width: 16px;
  height: 16px;
  margin-top: 10px;
}
.edit-icon2 {
  width: 16px;
  position: absolute;
  top: 285px;
  left: 280px;
  height: 16px;
}
.file-edit-icon {
  margin-top: 0px;
  width: 16px;
  height: 16px;
}
.file-icon {
  margin-top: -10px;
}
.file-wrapper {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.file-wrappers {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  position: relative;
}
.file-wrappers img {
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 2;
}
.message-area {
  background: #f4f4f6;
  border-radius: 8px;
  width: 280px;
  height: 60px;
  margin-top: 10px;
  font-size: 12px;
  padding: 10px;
  position: relative;
}

.con-pay {
  position: absolute;
  bottom: 26px;
}
