.service-btn {
  background: white;
  border-radius: 10px;
  width: auto;
  height: 40px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  border: 1px solid #362a41;
  /* opacity: 0.5; */
  padding: 3px;
}

.service-btn2:hover {
  background: white;
  border: 1px solid #362a41;
  color: #362a41;
  font-weight: bold;
}
.service-btn2 {
  background: #362a41;
  border-radius: 10px;
  height: 40px;
  color: white;
  opacity: 1;
  padding: 10px;
}
.interviewImage {
  width: "556px";
  height: "280px";
  top: "8px";
  left: "8px";
  border-radius: "12px";
}
.shared-part {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 80px;
  margin-left:10%;
}
.shared-part-int {
  display: flex;
  gap: 40px;
  width: 90%;
}
.share-int-phrase{
  color: #8652b6;
  width:170%;
}
.share-tit-wrap {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  padding: 10px 20px 0 20px;
}
.shared-part-inner {
  width: 70%;
  max-width: 570px;
}
.int-content {
  text-align: left;
  margin-left: 20px;
  color: #A8A1AC;
  opacity: 0.5;
}
.duration-ser {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  color: #362a41;
  font-size: 12px;
  border: 1px solid #c6cad2;
}
.time-ago {
  margin-top: 15px;
  font-size: 14px;
  display: flex;
  gap: 10px;
}
.time-ago > img {
  width: 30px;
}
.share-by {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}
.duration-wrap {
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
}
.duration-wrap img {
  margin-right: 5px;
  width: 15px;
}
.imgs img {
  height: 295px;
}
.header-name-close {
  cursor: pointer;
  background: #fff;
  width: 32px;
  height: 32px;
  padding: 6px;
  border: 1px solid rgba(221, 223, 228, 0.5);
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.int-heads{
  color: #4f4953;
  font-size: 16px;
  font-weight: 700;
  margin: 24px 0 10px 0;
}
.int-comp-name{
  color:#8652b6;
  font-size: 14px;
  font-weight: 700;
}
.int-desig{
  color: #4f4953;
  font-size: 12px;
  font-weight: 200;
}
.int-head-start{
  margin-top: 10px;
}
.close-icon-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-part-head {
  display: flex;
  gap: 20px;
}
.int-prev-wrap {
  background-color: #fff;
  border-radius: 24px 24px 0 0;
}
.style-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.timeago {
  margin-top: 5px;
  color: #a8a1ac;
  font-size: 12px;
}
.duration-box {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
  background: #362a41;
  padding: 10px;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
}
.icon-grid {
  position: relative;
  display: grid;
  cursor: pointer;
}
.what-grid {
  cursor: pointer;
}
.interview-imgProfile {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
