/* Style for the accordion container */
.accordion {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Style for the accordion items */
.accordion-item {
  border-bottom: 1px solid #ccc;
}

/* Style for the accordion header */
.accordion-header {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
}

/* Style for the arrow icon */
.arrow {
  margin-left: auto;
  transition: transform 0.3s ease;
}

/* Open state for the arrow icon */
.arrow.open {
  transform: rotate(180deg);
}
.closing {
  animation: scroll-close 1s linear;
}

@keyframes scroll-close {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
/* Style for the accordion content */
.accordion-content-otp {
  animation: scroll 1s ease-in;
  /* height:100%; */
  border-bottom: 1px solid #ccc;
  display: block;
}
.accordion-contents {
  animation: scroll-out 1.2s ease-out;
  padding: 0px 10px 10px 10px;
}
.accordion-content {
  padding: 0px 10px 10px 10px;
  border-bottom: 1px solid #ccc;
  transition: 300ms cubic-bezier(0.25, 0.8, 0.25, 1);
  animation: scroll 1.2s ease-in;
  transition: all 1s;
  /* display: none; */
  /* height: 0.5px; */
}
.accordion-header:focus + .accordion-content {
  transform: scale(1, 1);
  opacity: 1;
}
.accordion-content.active {
  display: block;
  height: 100%;
  /* transition: all 1s ease-in; */
  animation: scroll 1.2s ease-in;
}
.accordion-content.close {
  animation: scroll-out 1.2s ease-in;
}
@keyframes scroll {
  0% {
    transform: translateY(-35px);
  }
  100% {
    transform: translateY(calc(0px * 1));
  }
}
@keyframes scroll-out {
  100% {
    transform: translateY(-35px);
  }
  0% {
    transform: translateY(0);
  }
}
.margin {
  position: relative;
  bottom: 5px;
  margin-left: 30px;
}
