.tile-container {
  display: flex;
  justify-content: center;
  margin: 2rem;
  gap: 10px;
  cursor: pointer;
}

.tile {
  width: 300px;
  height: auto;
  margin: 10px;
  background-color: #ffffff;
  transition: transform 0.3s ease;
  border-radius: 18px;
  position: relative;
}
.tile.active{
  box-shadow: 2px 8px 10px 0px rgba(76, 41, 103, 0.56);

}
.span-text {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.span-texts {
  font-size: 16px;
  flex: 3;
}
.title-numbers {
  font-size: 20px;
  color: #362a41;
}
.tile-number {
  position: absolute;
  left: 50%; /* Center the number horizontally */
  transform: translateX(-50%);
  top: 50%;
  font-size: 30px;
  color: #362a41;
}
.tile:hover {
  transform: scale(1.1);
}

.tab-container {
  display: flex;
  justify-content: space-around;
  background: #ffff;
  margin: 2rem;
  border-radius: 10px;
}

.tab {
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  color:#4f4953;
  font-size: 18px;
}

.tab.active {
  background: #fff;
  border-radius: 48px;
  padding:10px 20px;
}
.separted-dashboard {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin: 1rem;
}

.interview-list-container {
  display: flex;
  width:95%;
  justify-content: space-around;
}
.pie-ch{
  margin-top: 20px;
}
@media screen and (max-width:1440px){
.interview-lists-cont {
  display: block;
}
}
.interview-left-container {
  background: #ffff;
  width: 300px;
  border-radius: 30px;
}
.interview-right-container {
  background: #ffff;
  width: 300px;
  border-radius: 30px;
}
.date-filter{
  position: relative;
    display: flex;
    justify-content: end;
    margin: 1rem;
}