.cardContainer {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.post-title {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}
.contentDiv {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.slick-car {
  margin-top: 50px;
}
.profileImage {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 12px;
}

.textContent {
  flex: 1;
  margin-top: 10px;
}
.thumb{
  margin-bottom: 5px;
}
.react-quill{
  margin-top: 20px;
}
.article-wrap{
  margin: 20px;
}
.textContents {
  color: #362a41;
}
.textContentCard {
  /* flex: 1; */
}
.text-post-content {
  color: #4f4953;
  margin-top: 20px;
}
.inputField {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-top: 4px;
  background-color: #f6f6f6;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.option {
  flex: 1;
  text-align: center;
  padding: 8px 0;
  border-radius: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
  margin: 0 4px;
  position: relative;
  cursor: pointer;
  padding: 10px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: #4f4953;
}

.character {
  position: relative;
  top:5px;
  display: flex;
  justify-content: flex-end;
  color: #ccc;
}

.buttonDiv {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.mes-time {
  color: #ccc;
  font-weight: 700;
}
.button1 {
  background: #362a41;
  font-family: var(--pf-medium);
  border-radius: 10px;
}

.dialogPaper {
  width: 347px !important;
  height: auto !important;
  border-radius: 32px !important;
  position: fixed !important;
}
.dialogPapers {
  width: 500px !important;
  height: auto !important;
  border-radius: 32px !important;
  position: fixed !important;
}
.dialogContent {
  margin: 10px;
}

.toggleContainer {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 10px;
  padding:20px;
}
.toggle-wrap{
  margin-top: -5px;
}

.dialogButtonContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: 10px;
}
.dialogButtonContainer button{
  margin: 10px;
}
.scheduleButtons{
  position: absolute;
  bottom: 0px;
  right:0;
  margin-top: 20px;
}
.back-btn{
  position: relative;
  left: 0px;
}
.social-post-btn{
  position: relative;
  left: 0px;
  background-color: #362a41;
  padding: 10px;
  color: #FFF;
  border-radius: 8px;
}
.scheduleOptions {
  position: relative;
  display: flex;
  justify-content: center;
}
.scheduleTime {
  position: relative;
  left:-100px;
  display: flex;
  align-items: center;
  margin: 10px;
}
.send-btn{
  margin-top: 7px;
}
.scheduleTime label{
  margin: 10px 10px 0 0;
}
.image-carousel {
  margin: 15px 0;
}

.carousel-slide img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 4px;
}

.interactions {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 10px;
}
.no-post {
  color: #362a41;
  font-size: 16px;
  margin: 20px;
}
.like-button,
.comment-button {
  border: none;
  cursor: pointer;
  padding-top: 5px;
}

.like-button {
  background-color: #007bff;
  color: white;
}
.comment-wrap {
  border: 1px solid grey;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.comment-text {
  font-size: 10px;
}
.comment-button {
  width: 48px;
  color: white;
  display: flex;
  justify-content: center;
}
.inputComment {
  position: relative;
  display: flex;
  gap: 10px;
}
.cardContainers {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}
.cardContainers-grid {
  max-width: 48%;
  min-width: 48%;
}
.cardContainers-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tab-name {
  color: #362a41;
}
.optionsContainerss {
  margin-top: 16px;
}
.toggleFixed {
  margin-top: 20px;
  gap: 10px;
  position: relative;
  display: flex;
}
@media (max-width: 1024px) {
  .tab-name {
    font-size: 10px;
  }
}
.active-button {
  background-color: #4caf50;
  color: white;
}

.disabled-button {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed; /* Indicates the button is disabled */
}
.buyContainer {
  display: flex;
  margin: 10px;
  justify-content: space-between;
}
.imageEbook {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

