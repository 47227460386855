.input-wrapper{
    margin-top:20px;
}

.input-wrapper2{
    margin-top: -50px;
}

.input-wrapper3{
    margin-top:40px;
}