.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none!important;
}

.fc-direction-ltr {
    background: #FFFFFF ;
    border-radius:24px;
}
.fc-daygrid-dot-event{
    display: block;
    font-size: 10px;
}
.fc-daygrid-event{
    white-space: wrap;
}
.fc-daygrid-event-dot{
    position:relative;
    top:10px;
    display: none;
}
.fc .fc-popover{
    margin-left:-110px;
}
.fc-event-title{
    margin-top: 5px;
}
.fc .fc-toolbar.fc-header-toolbar {
    padding:24px;
    background: #362A41;
    border-radius: 24px 24px 0px 0px;
}
.fc .fc-toolbar-title {
   color:#FFFFFF;
}
.fc .fc-button-primary {
    background: #362A41 !important;
    border:1px solid #FFFFFF;
    text-transform: capitalize!important;
}
.fc .fc-button{
    background: #362A41 !important;
    border:1px solid #FFFFFF !important;
    text-transform: capitalize!important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active{
    background: #FFFFFF !important;
    border:1px solid #764F90;
    color:#764F90 !important;
    text-transform: capitalize!important;
}
.fc .fc-button-primary:disabled {
    background: #362A41 !important;
    border:1px solid #FFFFFF;
}
.fc .fc-button:hover{
    background: #FFFFFF !important;
    border:1px solid #764F90;
    color:#764F90 !important;

}
.fc-scrollgrid-sync-inner > a,.fc-scrollgrid-sync-inner ::before {
 color:#4F4953 !important;
 text-decoration: none ;
}
table{
    margin-left: 0px;
}
.fc table {
    border-spacing: 0px;
}
#calender a{
    color:#4F4953 !important;
    text-decoration: none !important;
    padding:5px;
}
.fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width)/2) solid #D04C8D;
}
.fc-h-event .fc-event-main {
  background: #764F90 !important;
}
.fc .fc-daygrid-more-link{
    margin-top: -10px;
    overflow:visible;
}
.fc-event-time, .fc-event-title {
    padding: 0 1px;
    white-space: pre-line;
}
.mc-wrapper{
    padding: 10px 40px;
}
.mc-wrapper .fc-direction-ltr{
    font-family: var(--pf-medium);
    background-color: transparent;
}
.mc-wrapper .fc .fc-toolbar.fc-header-toolbar{
    margin: 0 25px;
}
.mc-wrapper .fc .fc-view-harness{
    background-color: white;
    border-radius: 32px;
    overflow: hidden;
}
.mc-wrapper .fc-theme-standard .fc-scrollgrid{
    border: 0;
}
.mc-wrapper .fc-theme-standard td, .mc-wrapper .fc-theme-standard th{
    border-color: #EEEFF1;
}
.mc-wrapper .fc-theme-standard th{
    font-size: 20px;
    padding: 0px 0px 0 2px;
}
.mc-wrapper .fc .fc-event{
    background-color:#FCE7F1;
    padding:10px 10px;
    border-radius: 4px;
    height:fit-content;
    width:fit-content;
}
.mc-wrapper .fc .fc-event:hover{
    box-shadow: 0px 16px 32px 0px #D5D9DBC9;
}
.mc-wrapper .fc-event-title{
    font-size: 13px;
    font-family: var(--pf-regular);
    color: #525252;
    /* position: relative;
    margin-top: 15px;
    margin-left: -60px; */
}
.fc-theme-standard td{
    scrollbar-width: 0;
}
.fc .fc-timegrid-slot-minor {
    border-top-style: solid;
}
.mc-wrapper .fc-event-time{
    font-size: 10px;
    color: #9F6983;
}
@media (min-width:1200px){
    .mc-container{
        width: 930px;
        margin: 30px auto;
    }
}
.date-picker{
    width:100%;
    content: " "!important;
    border-radius: 16px;
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.MuiDataGrid-cell svg{
    position: absolute;
    left:100px;
    margin-right:20px;
}
.MuiPickersLayout-contentWrapper > div:nth-child(2){
    display: none;
}
.react-datepicker-poppers{
    position: relative !important;
    width:fit-content;
    margin-top: -40px;
    inset:0 !important;
}
@media screen and (max-width: 480px) {
    .fc .fc-toolbar.fc-header-toolbar{
       display: flex;
       flex-direction: column;
       gap:8px;
       font-size: 12px;
       padding:10px;
    }
    .mc-container{
        width: 98%;
        margin: 30px auto;
    }
    .fc table{
        font-size: 12px;
    }
    .mc-wrapper .fc-theme-standard th{
        font-size: 12px;
        padding: 0px 0px 0 2px;
    }
    .fc .fc-popover{
        margin-left:0px;
        top:30px !important;
    }
}
