/* chatmain.css */

.chat-main-container {
    z-index: 1000;
    padding: 8px 8px 0 0;
    cursor: pointer;
  }
.image-avatar-message{
    width: 24px;
    height: 24px;
    margin-left: 35px;
    margin-top: -10px;
    opacity: 0.8;
}  
.image-avt{
  margin-left: 10px;
}
.chat-name{
  color: #4F4953;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.prof-img{
  border-radius: 50%;
}
.unread-message-count {
  position: absolute;
  top: 14px; /* Adjust to move the count up */
  right:315px;
  background-color: green;
  color: white;
  border-radius: 50%; /* Make it circular */
  padding: 2px 4px; /* Add some padding */
  font-size: 8px;
}
.unread-message-counts {
  position: absolute;
  top: 14px; /* Adjust to move the count up */
  right: 235px;
  background-color: green;
  color: white;
  border-radius: 50%; /* Make it circular */
  padding: 2px 4px; /* Add some padding */
  font-size: 8px;
}
@media (max-width:1024px){
  .unread-message-count{
    right:193px;
    top:-2px;
  }
  .unread-message-counts{
    top:-2px;
    right:275px;
  }
}
/* @media (max-width:820px){
  .unread-message-count{
    right:170px;
    top:22px;
  }
  .unread-message-counts{
    top:25px;
    right:255px;
  }
} */