.reschedule-open {
  cursor: pointer;
}
.reschedule-main-div {
  position: relative;
  display: flex;
  left:20%;
  gap: 20px;
  margin:20px 0;
}
.openDate {
  position: relative;
  display: flex;
  justify-self: center;
  gap: 30px;
}
.request-button {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}
.re-head-wrap{
  display: flex;
  gap:20px;
}
.re-head{
  color:#362A41;
  font-size: 18px;
  margin-top: 10px;
}
.re-subhead{
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.re-date-head{
  color:#4F4953;
  font-size: 14px;
  margin: 5px 0;
}
.re-date{
  font-size: 14px;
  color:#4F4953;
  margin-top: 5px;
}
.re-sel-date{
  font-size: 12px !important;
  border-radius: 8px;
}
.resch-reas{
  margin-top: 150px;
}