.question-button {
  width: 40px;
  background-color: white;
  color: #362a41;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer; /* Add cursor pointer for better UX */
}
.question-button:hover {
  width: 40px;
  background-color: #362a41;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.question-buttons {
  width: 40px;
  background-color: #362a41;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer; /* Add cursor pointer for better UX */
}
.button-main-question {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: center;
}
