/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: var(--this-primary);
  /* --primary-font: "Poppins", sans-serif; */
  --font-m: 14px;
  --font-l: 16px;
  --input-bg: #f4f4f6;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* margin-top: 10px; */
}

body,
html {
  height: 100%;
  font-family: var(--pf-medium);
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: var(--pf-medium);
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
.onboard-usertype {
  display: block;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  text-align: center;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
}
.onboard-usertype > img {
  width: 50px;
  border-radius: 50%;
}
.onboard-input {
  position: absolute;
  top: -20px;
  right: 4px;
  width: 20px;
  height: 20px;
}
.onboard-usertype > label {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  cursor: pointer;

}
.onboard-wrapper {
  display: flex;
  gap: 10px;
}
.onboard-usertype:hover {
  color: white;
}

.onboard-usertype:hover,
.onboard-usertype.selected {
  box-shadow: 2px 8px 10px 0px rgba(76, 41, 103, 0.56);
  color: white;
}

.onboards-usertype.selected {
  color: white;
}
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}
input:-moz-placeholder {
  color: #adadad;
}
input::-moz-placeholder {
  color: #adadad;
}
input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}
textarea:-moz-placeholder {
  color: #adadad;
}
textarea::-moz-placeholder {
  color: #adadad;
}
textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
  padding:0 10px;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
      [ Utility ]*/
.txt1 {
  font-family: var(--pf-medium);
  font-size: 15px;
  color: #999999;
  line-height: 1.5;
}

.txt2 {
  font-family: var(--pf-medium);
  font-size: 15px;
  color: #57b846;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
      [ login ]*/
/* .samplebg{
        background-color: #4e73df;
      } */
.limiter {
  width: 100%;
  margin: 0 auto;
}
.login-wrapper {
  background-color: #fff !important;
  /* height:100vh; */
  margin: 0;
  /* max-width: 1440px;
  margin: auto; */
}
.login-wrappersShare {
  background-color:#f7f7f8;
  margin: 0;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1023px) {
  .logo-in {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .waveBg {
    background: url(../images/wave.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    width: 100%;
    min-height: 100vh;
    margin: 0;
  }
  .wave {
    position: fixed;
    width: 111%;
    /* height: 923px; */
    left: 191.73px;
    /* top: -197.5px; */
    /* background: var(--this-primary); */
    mix-blend-mode: multiply;
    /* box-shadow: inset 11px -10px 136px #000000; */
  }
  .logo-in {
    position: absolute;
    right: 0px;
    width: 1130px;
  }
}

/*------------------------------------------------------------------
      [ Form ]*/

.login100-form {
  width: 100%;
}
.font-face-title {
  font-family: var(--pf-medium);
}
.login100-form-title {
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 46px;

  color: var(--this-primary);
  margin: 25px 0px;
}

/*------------------------------------------------------------------
      [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border: 1px solid #b9b7ba;
  border-radius: 10px;
  margin-bottom: 20px;
}

.font-face-gm {
  font-family: var(--pf-medium);
  font-size: 16px;
}
.input100 {
  font-family: var(--pf-medium);
  font-size: 17px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 376px;
  height: 69px;
  background: transparent;
  padding: 0 5px;
  border-radius: 10px;
}
.input101 {
  font-family: var(--pf-medium);
  font-size: 17px;
  color: #555555;
  line-height: 1.2;
  display: block;
  height: 49px;
  background: transparent;
  padding: 0 5px;
  border-radius: 5px;
}
.input102 {
  font-family: var(--pf-medium);
  font-size: 17px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 150px;
  height: 39px;
  background: transparent;
  padding: 0 5px;
  border-radius: 5px;
}
.verify {
  display: flex;
  gap: 30px;
  margin: 30px 0 20px 20px;

  /* width:800px; */
}
.valid {
  color: #57b846;
  font-size: 14px;
  margin: 5px 0;
}
.invalid {
  color: rgba(196, 196, 196, 1);
  margin: 5px 0;
  font-size: 14px;
}
#message6 {
  border: 2px solid var(--this-primary);
  border-radius: 16px;
  padding: 20px;
  position: absolute;
  width: 350px;
  height: 110px;
  padding: 10px;
  top: 365px;
  left: 460px;
  background: #1f1a24;
  animation: fadeOutAnimation ease 6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeOutAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.connect {
  color: var(--this-primary);
  font-size: 30px;
  position: absolute;
  top: 430px;
  left: 790px;
}
.connect1 {
  color: var(--this-primary);
  font-size: 30px;
  position: absolute;
  top: 610px;
  left: 700px;
}
.connect2 {
  color: var(--this-primary);
  font-size: 30px;
  position: absolute;
  top: 480px;
  left: 740px;
}
.check {
  border: 1 px solid green;
  background-color: green;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 7px;
}
.uncheck {
  /* border: 1px solid var(--this-fifth); */
  background-color: rgba(196, 196, 196, 1);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 7px;
}
.otp {
  margin: 10px 0 0 0px;
  font-family: var(--pf-medium);
  font-size: 22px;
  color: var(--this-secondary);
  font-weight: 800;
  letter-spacing: 3px;
}
.verify-text {
  margin: 20px 0 0 0px;
  font-family: var(--pf-medium);
  font-size: 16px;
  color: var(--this-secondary);
  font-weight: 800;
  letter-spacing: 1px;
  /* position: relative;
  right:70px; */
}
.mdl-open {
  background-color: #b9b7ba;
  opacity: 0.92;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 2;
}
.content {
  width: 400px;
  height: 150px;
  background: #fefefe;
  border-radius: 20px;
  padding: 50px 50px;
  position: absolute;
  top: 100px;
  left: 350px;
}
.content2 {
  width: 600px;
  height: 200px;
  background: #fefefe;
  border-radius: 20px;
  padding: 25px 50px;
  position: absolute;
  top: 200px;
  right: 200px;
  left: 40%;
  z-index: 2;
  font-size: 16px;
  font-weight: normal;
  font-family: var(--pf-medium);
}
.content p {
  color: green;
  font-size: 20px;
}
.content button {
  font-size: 16px;
  font-family: var(--pf-regular);
  font-weight: normal;
  margin: 10px 0 0 100px;
}
.content2 p {
  font-size: 16px;
  font-family: var(--pf-regular);
  font-weight: normal;
}
.modal-btn {
  padding: 10px 20px;
  border: 1px solid var(--this-primary);
  background: var(--this-primary);
  color: white;
  font-size: 16px;
  border-radius: 10px;
  /* margin: 18px; */
}
.modal-btn2 {
  padding: 10px 20px;
  border: 1px;
  background: white;
  color: var(--this-primary);
  margin-left: 40px;
  font-size: 16px;
  border-radius: 10px;
  /* margin: 18px; */
}
.modal-btn:hover {
  background: white;
  color: var(--this-primary);
}
.modal-btn2:hover {
  background: var(--this-primary);
  color: white;
}
.back {
  color: var(--this-primary);
  font-size: 40px;
  border: 1px solid #555555;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-family: var(--pf-medium);
  font-weight: normal;
  position: absolute;
  left: 20px;
  top: 20px;
}
.back2 {
  color: var(--this-primary);
  font-size: 40px;
  border: 1px solid #555555;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-family: var(--pf-medium);
  font-weight: normal;
  position: absolute;
  left: 270px;
  top: 20px;
}
.back i {
  position: relative;
  top: 15px;
  left: 10px;
}
.back2 i {
  position: relative;
  top: 15px;
  left: 10px;
}
/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #4e73df;
}

.focus-input100::after {
  font-family: var(--pf-medium);
  font-size: 18px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.has-error {
  border-color: #ff1111;
  border-width: 2px;
}

.input100:focus + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

/*------------------------------------------------------------------
      [ Button ]*/
.container-login100-form-btn {
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.container-login100-form-btn a {
  width: 100%;
}
.login-form-btn {
  border: 1px solid #714f90;
  background: #714f90;
  border-radius: 10px;
  font-family: var(--pf-medium);
  padding: 0 20px;
  height: 50px;
  width: 150px;
  color: #fff;
  font-weight: normal;
  font-size: 20px;
  margin-top: -20px;
}
.login-form-btn:hover {
  background: none;
  border: 1px solid #714f90;
  color: #714f90;
}
.login100-form-btn {
  font-family: var(--pf-medium);
  font-size: 14px;
  /* color: #fff; */
  line-height: 1.2;
  text-transform: uppercase;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  background: var(--this-primary);
  border-radius: 8px;
  color: #fff;

  /* box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5); */

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: #fff;
  color: var(--this-primary);
  border: 1px solid var(--this-primary);
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}
.login101-form-btn {
  font-family: var(--pf-medium);
  font-size: 14px;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 150px;
  height: 50px;
  background: var(--this-primary);
  border-radius: 8px;
  color: #fff;

  box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.login101-form-btn:hover {
  background: none;
  border: 1px solid var(--this-primary);
  color: var(--this-primary);
}
.error-message,
.error-msg {
  font-size: 0.7rem;
  font-family: var(--pf-regular);
  font-weight: normal;
  color: var(--this-fifth);
  margin-top: 0.2rem;
}
/*------------------------------------------------------------------
      [ Alert validate ]*/

.validate-input {
  position: relative;
}
.login-title {
  text-align: center;
  margin: 38px 0px 45px 0px;
  color: "#B9B7BA";
  font-size: 12px;
  font-family: var(--pf-medium);
  font-weight: normal;
}
.login-title2 {
  text-align: center;
  margin: 28px 0px 25px 0px;
  color: "#B9B7BA";
  font-size: 12px;
  font-family: var(--pf-medium);
  font-weight: normal;
}
.hr {
  border: 1px solid grey;
}
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 720px) and (max-width: 849px) {
  .login100-form-title {
    font-size: 40px;
  }
}
@media (min-width: 619px) and (max-width: 720px) {
  .container-login100-form-btn {
    display: block;
  }
}
@media (min-width: 540px) and (max-width: 619px) {
  .container-login100-form-btn {
    display: block;
  }

  .login100-form-title {
    font-size: 40px;
  }
}
@media (min-width: 420px) and (max-width: 540px) {
  .login100-form-title {
    font-size: 25px;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  .login100-form-title {
    font-size: 25px;
  }
}
.let-getStarted {
  font-size: 46px;
  font-family: var(--pf-medium);
  color: var(--this-primary);
  font-weight: normal;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}
.let-getStarted span {
  display: block;
}
.lets-getStarted {
  font-size: 38px;
  font-family: var(--pf-medium);
  color: var(--this-primary);
  font-weight: normal;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  position: relative;
}

.textreg,
.textpass {
  font-style: normal;
  font-family: var(--pf-medium);
  /* font-weight: 700; */
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: var(--this-third);
  /* text-transform: capitalize; */
  margin-bottom: 10px;
  text-align: left;
}

.login-input {
  display: flex;
  height: 48px;
  width: 365px;
  padding: 0px 20px 0px 14px;
  align-items: center;
  border-radius: 8px;
  background: #f4f4f6 !important;
  color: #40444f !important;
}

.forgot-password {
  color: var(--this-secondary);
  font-family: var(--pf-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
  text-align: right;
  margin-bottom: 10px;
}
.remember {
  display: flex;
  gap: 20px;
  position: absolute;
  top: 555px;
  left: 115px;
}
.remember p {
  color: blue;
  margin-top: 20px;
}
.login-btn {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--this-primary);
  color: white;
  width: 100%;
}
.login-btn:hover {
  background-color: #fff;
  color: #362a41;
  border: 1px solid grey;
  font-weight: normal;
}
.login-option {
  color: #a8a1ac;
  font-family: var(--pf-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: center;
}
.social-icons {
  display: flex;
  gap: 24px;
}
.login-signupphrase {
  color: var(--this-primary);
  font-family: var(--pf-bold);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.28px;
}
.login-signupphrase span {
  color: #8652b6;
  cursor: pointer;
  font-size: 14px;
}
.login-signupphrases {
  color: var(--this-primary);
  font-family: var(--pf-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-top: 15px;
  position: relative;
  justify-content: center;
  display: flex;
}
.login-signupphrases span {
  color: #8652b6;
  cursor: pointer;
}
.foot-links{
  position: absolute;
  bottom: 0px;
  display: flex;
  gap:20px;
  justify-content: center;
  padding: 10px 60px;
  color: #8652b6;
  cursor: pointer;
}
.login-options {
  color: #a8a1ac;
  font-family: var(--pf-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: center;
}
.social-iconss {
  display: flex;
  gap: 24px;
  position: relative;
  justify-content: center;
}
.otp-group {
  display: flex;
  width: 100%;
  max-width: 245px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 40px;
  width: 50px;
  background: #f4f4f6;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.otp-inputs {
  width: 68px;
  height: 72px;
  background: #f4f4f6;
  border-radius: 8px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}
/*********  **********/
.input-holder {
  position: relative;
}
.error-text {
  font-size: var(--font-m);
}
.pass-in-check {
  display: flex;
  gap: 10px;
  margin: 5px 0;
}
.social-icons li {
  border: 1px solid #dddfe480;
  padding: 8px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icons .fa {
  cursor: pointer;
  font-size: 35px;
}
.social-icons .fa-facebook-square {
  color: #1876f3;
}
.social-icons .fa-linkedin-square {
  color: #0a66c2;
}
.sub-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
.arrow-box {
  cursor: pointer;
}
.modal-title,
.modal-title-sub,
.modal-b-title {
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: var(--this-primary);
}
.login100-form-btn {
  width: 100%;
}
.con-options {
  text-align: center;
  font-family: var(--pf-regular);
  margin-top: 15px;
}
.social-icon-position {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}
.pass-instruct {
  background-color: var(--this-primary-strong);
  padding: 15px 10px;
  border-radius: 15px;
  position: absolute;
  animation: fadeOutAnimation ease 6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.pass-hint-head {
  color: white;
  font-family: var(--pf-medium);
  margin-bottom: 0;
}
.pass-instruct h3 {
  font-family: var(--pf-medium);
  font-size: var(--font-l);
  font-weight: normal;
  color: var(--primary-color) !important;
}
.pass-in-check {
  align-items: center;
  font-size: var(--font-m);
  font-weight: normal;
  gap: 8px;
}
.pass-in-check .check,
.pass-in-check .uncheck {
  width: 7px;
  height: 7px;
  border-radius: 50px;
}
.pass-in-check p {
  margin-bottom: 0;
}
.pass-input-wrapper {
  position: relative;
}
.pass-eye-container {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.pass-eye-container svg {
  color: var(--this-primary-strong);
  width: 25px;
}
.progress-head{
  display:flex;
  gap:15%;
  justify-content:center;
  margin-left:20px;
  color:#362A41;
  font-size:12px;
}
.form-wrapper-body{
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  body {
    /* overflow-x: hidden; */
    background-color: #fff !important;
  }
  .login-wrapper {
    padding: 15px;
  }
  .progress-head{
    margin-left:50px;
  }
  .p-15 {
    padding: 15px;
  }
  .mb-m-10 {
    margin-bottom: 10px;
  }
  .mb-submit {
    margin-bottom: 40px;
  }
  .bg-m-white {
    background-color: #fff !important;
  }
  .margin-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .login-main .margin-logo {
    display: block;
    padding-left: 0;
  }
  .login-main .logo-holder {
    text-align: left;
  }
  .login-logo {
    max-width: 100%;
    height: 65px;
    width: 180px;
    margin-bottom: 20px;
  }
  .lets-getStarted {
    font-size: 24px;
    width:100%;
    top:30px;
  }
  .form-class {
    padding: 10px;
  }
  .textreg,
  .textpass {
    font-size: var(--font-m);
  }
  .input-holder {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  .wrap-input100 {
    border: 0;
  }
  .input101,
  .input101:focus {
    width: 100%;
    border: 0;
    height: auto;
    font-size: var(--font-m);
    background-color: var(--input-bg);
    padding: 15px;
  }
  .input101::placeholder {
    color: #c6cad2;
    opacity: 1; /* Firefox */
    font-style: italic;
  }

  .input101::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #c6cad2;
  }
  .verify {
    margin: 30px 0 20px 0px;
  }
  .otp-group {
    max-width: 290px;
    column-gap: 14px;
  }
  .otp-input {
    border: 0;
    background-color: var(--input-bg);
    font-size: 25px;
  }

  .login-signupphrases {
    margin-top: 22px;
  }

  .login100-form-avatar {
    text-align: left;
  }
  .let-getStarted {
    font-size: 25px;
    line-height: normal;
    margin-bottom: 20px;
  }
  .social-icons {
    justify-content: center;
  }
  .f-pass-container {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fpass-btn {
    font-size: var(--font-m);
  }
  .hr-code {
    text-align: center;
    font-family: var(--pf-light);
  }
  .mt-m-20 {
    margin-top: 20px;
  }
  .modal-title,
  .modal-title-sub {
    font-size: 16px !important;
    font-family: var(--pf-medium) !important;
  }
  .modal-b-title,
  .d-title {
    font-size: 16px !important;
    font-family: var(--pf-medium) !important;
  }
}
/* End of  mobile */
/* desktop */
@media (min-width: 576px) {
  .login-wrapper {
    position: relative;
    background-image: url(../images/BG.png);
    background-repeat: no-repeat;
    background-position: right 8px;
    background-size: contain;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  .login-wrappersShare {
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    width: 100%;
    min-height: 100vh;
    display: flex;
  }
  .form-wrapper {
    width: 40%;
    padding: 10px 10px 10px 60px;
    position: relative;
  }
  .margin-logo {
    padding: 10px 10px 30px 0;
  }
  .margin-logo .logo-holder {
    text-align: left;
  }
  .signup-main .margin-logo .logo-holder,
  .login-main .margin-logo .logo-holder {
    margin-bottom: 15px;
  }
  .logo-holder img{
    width:180px;
  }
  .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* padding-left: 16vw; */
  }
  .login-btn {
    margin-top: 25px;
  }

  .login-main .login-signupphrase {
    /* margin-top: 10px; */
    margin-bottom: 12px;
  }
  .sub-header {
    margin-bottom: 1.8rem;
  }
  .otp-timer {
    margin: 10px 0 15px 0;
    font-family: var(--pf-medium);
    font-size: 14px;
  }
  .otp-main .login-signupphrase {
    text-align: center;
    color: var(--this-primary-lite);
    font-family: var(--pf-regular);
    margin: 15px 0 25px 0;
  }
  .otp-main .login-signupphrase span {
    font-family: var(--pf-medium);
  }
  .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .error-wrapper .error-msg,
  .error-wrapper .verify-text {
    margin: 15px 0 10px 0;
  }
  .pass-instruct {
    padding: 20px 25px 18px 25px;
  }
  .pass-instruct::before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    background-image: url(../images/icons/hint-box.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: -14px;
    top: 18px;
  }
  .pass-hint-head {
    font-size: 16px;
  }
  .cp-wrapper {
    background-color: white;
    border-radius: 24px;
    width: 70%;
    margin: 20px auto;
    position: relative;
  }
  .cp-header,
  .cp-body {
    padding: 30px;
  }
  .cp-header {
    font-size: 18px;
    text-align: left;
    font-family: var(--pf-medium);
    color: var(--this-primary);
    border-bottom: var(--this-border);
  }
  /* desk */
  #message {
    top: 300px;
    left: 820px;
  }
  #message1 {
    top: 450px;
    left: 750px;
  }
  #message2 {
    top: 370px;
    left: 790px;
  }
  #message3 {
    top: 43%;
  }
  #message4 {
    top: 59%;
    right: -74%;
  }
  #message5 {
    position: absolute;
    width: 370px;
    top: 220px;
    left: 520px;
  }
  #message6 {
    top: 365px;
    left: 460px;
  }

  #message8 {
    border: 2px solid var(--this-primary);
    border-radius: 16px;
    padding: 20px;
    position: absolute;
    width: 350px;
    height: 110px;
    padding: 10px;
    top: 185px;
    left: 390px;
    background: #1f1a24;
    animation: fadeOutAnimation ease 6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  #message9 {
    padding: 10px;
    top: 135px;
    left: 420px;
    width: 350px;
    height: 215px;
  }
}

@media (min-width: 1200px) {
  .login-wrapper {
    background-size: contain;
  }
  .cp-wrapper {
    width: 50%;
  }
}
@media (min-width:768px) and (max-width:820px) {
  .login-wrapper {
    min-height: 80vh;
    background-image: none;
  }
  .login-wrapper{
    justify-content: center;
  }
  .form-wrapper{
    width:60%;
    padding: 0;
  }
  
  .let-getStarted {
    line-height: normal;
  }
  .margin-logo {
    padding: 0px 10px 50px 0;
  }
  .foot-links{
    width:100%;
  }
}
@media (min-width:821px) and (max-width:1200px) {
  .let-getStarted {
    font-size: 25px;
    line-height: normal;
  }
  .login-wrapper{
    background-size: 45% 100%;
  }
}

/* @media screen and (min-height: 750px) and (max-height: 825px) {
  #message5 {
    top: 235px;
  }
} */
/* @media screen and (min-height: 826px) and (max-height: 945px) {
  #message5 {
    top: 405px;
  }
} */
@media screen and (max-width: 600px) {
  .otp-inputs {
    width: 48px;
    height: 52px;
    background: #f4f4f6;
    border-radius: 8px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
  }
}

@media (min-width: 1300px) {
  .form-wrapper {
    width: 500px;
  }
  .form-wrapper .form-wrapper-body {
    width: 380px;
  }
  .lets-getStarted {
    font-size: 46px;
  }
  .error-wrapper {
    margin-right: 130px;
  }
  .cp-wrapper {
    width: 430px;
  }
  #message7 {
    right: -77%;
    top: 45%;
    padding: 13px 15px 14px 15px;
  }
}
.input-holder {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.wrap-input100 {
  border: 0;
}
.input101,
.input101:focus {
  font-family: var(--pf-regular);
  width: 100%;
  border: 0;
  height: auto;
  font-size: var(--font-m);
  background-color: var(--input-bg);
  padding: 15px;
}
.input101::placeholder {
  color: #c6cad2;
  opacity: 1; /* Firefox */
}
.social-icon-position .ci {
  cursor: pointer;
}
.ci {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
}
.ci-google {
  background-image: url(../images/icons/google-btn.svg);
}
.ci-facebook {
  background-image: url(../images/icons/facebook-btn.svg);
}
.ci-linked-in {
  background-image: url(../images/icons/linkedin-btn.svg);
}
.pop_ui {
  margin-top: 15px;
  margin-left: 10px;
  padding: 15px;
}
.modal-b-title .title-change {
  padding: 30px;
  margin-left: -5px;
}
