.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
}
.buttonOtp {
  background: #362a41;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-tel-input .selected-flag{
  background-color: #FFF;
  margin: -2px 0 5px 2px;
  border-radius: 6px;
}
.react-tel-input .selected-flag .arrow{
  left:10px;
}
.buttonOtp:hover {
  background: white;
  border: 1px solid #362a41;
  color: #362a41;
  font-weight: normal;
}
.side-mobile {
  position: relative;
  width: 30%;
}
.para-color{
  color: #8652b6;
  cursor: pointer;
  text-align: center;
  margin: 1rem;
}
.para-div{
  margin-top: 10px;
}
.let-getStarteds {
  font-size: 40px;
  font-family: var(--pf-medium);
  color: var(--this-primary);
  font-weight: normal;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}