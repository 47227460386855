.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container > img {
  border-radius: 100px;
  position: relative;
  top: 20px;
  width: 144px;
  height: 144px;
  border: 3px solid #fff;
  border-spacing: 3px;
  filter: drop-shadow(0px 4px 16px rgba(54, 42, 65, 0.12));
  background-color: #f7f7f8;
  padding: 5px;
}
.react-tel-input .flag-dropdown {
  border: none !important;
  /* top:4px !important;
  height:30px !important; */
  padding:0px 0 0 0px !important;
  margin:8px 4px 4px 4px;
}
/* .react-tel-input .in{
  width:28px !important;
  height:28px !important; 
} */
.About-head {
  margin-top: 40px;
}
.AboutPopup-head {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
}
.About-desc-wrapper {
  background-color: #f4f4f6;
  width: 100%;
  height: fit-content;
  padding: 20px 15px;
  border-radius: 5px;
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 22.4px */
}
.About-add-work {
  position: relative;
  top: 50px;
}
.icon-plus {
  position: initial;
  top: 8%;
  left: auto;
  margin-left: -33px;
  margin-top: -95px;
  background-color: white;
  border-radius: 50%;
  padding: 3px;
}
.image-style {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-color {
  color: #5aa4cc;
}
.containerUpload {
  width: 500px;
  height: 250px;
  margin: auto;
  align-items: center;
  background-color: #ffffff;
  transition: 0.3s ease all;
  z-index: 2;
  margin-top: 20px;
  border-radius: 10px;
}

.upload-camera {
  display: flex;
  justify-content: center;
  align-items: center;
}
.setUpload {
  position: relative;
  display: flex;
  justify-content: flex-end;
  left: -12px;
}
.text-name {
  color: black;
  position: relative;
  top: 10px;
  left: 13px;
}
.uploadButon {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 10px;
}
.btn-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.go-back {
  margin-top: 5px;
  color: #764f90;
  cursor: pointer;
}
.containerCamera {
  width: 500px;
  height: 250px;
  margin: auto;
  align-items: center;
  background-color: #ffffff;
  transition: 0.3s ease all;
  z-index: 3;
  margin-top: 20px;
}
.camera-div {
  position: relative;
  display: grid;
  gap: 14px;
  justify-content: center;
  top: 81px;
}
.capture-image {
  position: relative;
  left: 47%;
  top: -57px;
}
.photoImage {
  width: 50px;
  height: 50px;
}
.imageOne {
  position: absolute;
  left: 46%;
  top: 18%;
}
.close-icon2 {
  position: absolute;
  left: 1170px;
  top: 20px;
  cursor: pointer;
}
.close-icon3 {
  position: absolute;
  left: 1340px;
  top: 30px;
  cursor: pointer;
}
.onboards-usertype {
  display: block;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  text-align: center;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
}
.onboards-usertype > img {
  width: 50px;
  border-radius: 50%;
}
.onboards-input {
  position: absolute;
  top: -20px;
  right: 4px;
  width: 20px;
  height: 20px;
}
.onboards-usertype > label {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  cursor: pointer;
}
.onboards-wrapper {
  display: flex;
  gap: 10px;
}
.onboards-usertype:hover {
  color: white;
}

.onboards-usertype:hover,
.onboards-usertype.selected {
  box-shadow: 2px 4px 5px 0px rgba(76, 41, 103, 0.56);
  color: white;
}

.onboards-usertype.selected {
  color: white;
}
@media screen and (max-width: 600px) {
  .onboards-wrapper {
    display: block;
    gap: 10px;
  }
  .AboutPopup-head {
    font-size: 12px;
  } 
}