.editor {
    margin-right: 10em;
  }
  
  .ck-editor__editable_inline {
    min-height: 500px;
  }

.select-drop{
    color:  #C6C6C6;
    background:  #FFF;
    border-radius:8px;
    padding: 8px 12px;
    
      
}
.job-info-cont{
  display: flex;
  gap:20px;
  justify-content: center;
  padding: 20px;
  font-weight: 600;
  margin: auto;
  max-width: 580px;
}
.job-info-conts{
  color:#362A41;
  font-weight: 600;
  margin-top: 8px;
  font-size: 16px;
  margin: 20px auto;
  max-width: 580px;
  text-align: center;
}
.job-info-cont-head{
  font-size: 14px;
  color:#362A41;
  font-weight: 600;
}
b{
  padding-bottom: 20px;
}
select option:hover{
  color: #362A41; 
  padding:10px;
  background-color: #C6C6C6 !important;
  border-radius:8px;
  width: calc(100% - 8px);
  margin-left:4px;
    
}

.link-icon{
  position: relative;
  display: grid;
  cursor: pointer;
}
.share-icon{
  color:#362A41;
  width:40px;
  padding:8px;
}
.apply-btn{
  position: relative;
  top:43px;
  height:48px;
  border-radius: 0 8px 8px 0;
  padding:12px 22px;
  font-size: 12px;
  font-family: var(--pf-medium);
  background-color: var(--this-primary);
  color: white;
}
.apply-ebtn{
  position: relative;
  top:43px;
  height:48px;
  border-radius: 0 8px 0px 0;
  padding:12px 22px;
  font-size: 12px;
  font-family: var(--pf-medium);
  background-color: var(--this-primary);
  color: white;  
}
.offer-input{
  border-radius: 8px 0 0 8px;
  color:#362A41 !important;
  font-weight: 700;
  background-color: #F6F5F7;
  padding:0 7px 0 5px;
}
.offer-einput{
  border-radius: 8px 0 0 0;
  color:#4F4258 !important;
  font-weight: 700;
  background-color: #F6F5F7;
  padding:0 0px 0 10px;
  background: #f4f4f6;
  font-size: 12px;
  font-weight: 700;
  height: 48px;
  border-top:2px solid #FF596A !important;
  border-bottom: 2px solid #FF596A !important;
  border-right: 2px solid #FF596A !important;
}
.offer-einputs{
  border-radius: 8px 0 0 0;
  color:#4F4258 !important;
  font-weight: 700;
  background-color: #F6F5F7;
  padding:0 0px 0 10px;
  background: #f4f4f6;
  font-size: 12px;
  font-weight: 700;  
  border-top:2px solid #FF596A;
  border-bottom: 2px solid #FF596A;
  border-left: 2px solid #FF596A;
}

.edit-picker-time{
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 370px;
}
.job-info-wrap{
  opacity: 0;
}
.job-info-wraps{
  opacity: 1;
}
.schedule-foot{
    display: flex;
    gap: 20px;
    padding:20px 0 10px 0px;
    margin-top: 10px;
}
.schedule-footer{
  display: flex;
  gap: 20px;
  bottom: 0;
}