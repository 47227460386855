.head-container {
  background: white;
  /* margin-top: 20px; */
  /* width:100%; */
  border-radius: 20px;
  padding: 20px 40px;
  margin: 50px 40px;
  width: 65%;
}
.button {
  background: #362a41;
  border-radius: 10px;
  position: absolute;
  right: 30%;
  width: 150px;
  height: 40px;
  color: white;
}
.edit-comp-wrap{
  display: flex;
  gap: 20px; 
  margin: 20px 0;
  width: 100%;
}
.button2 {
  background: #362a41;
  border-radius: 10px;
  font-size: 16px;
  color: white;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.button2:hover {
  background: white;
  border: 1px solid #362a41;
  color: #362a41;
}
.create-int-btn {
  width: 210px !important;
}

.myprofile-head {
  display: flex;
  gap: 400px;
}
.nav-item > .active .dots {
  padding-bottom: 12px;
  background-color: rgba(50, 249, 130, 1);
}

.nav-item > Link {
  cursor: pointer;
}
.link-text {
  /* color: #fff !important; */
  font-size: 20px;
  font-weight: normal;
  margin-left: 5px;
  position: relative;
  bottom: 40px;
}
.navs {
  width: 100%;
  text-align: center;
  position: relative;
}

/* #about {
  margin-top: 60px;
} */
.navbars {
  text-align: center; /* Center align the content within the navbar */
}

#nav .nav.nav-pillss {
  display: flex; /* Display the list items in a row */
  justify-content: center; /* Center the list items horizontally */
  align-items: center; /* Center the list items vertically */
}

#nav .nav-item > .active .dots {
  padding-bottom: 12px;
  background-color: rgba(50, 249, 130, 1);
}

#nav .nav-item > Link {
  cursor: pointer;
}
/* #education-popup{
    height:1200px;
} */
/* #courses {
  margin-bottom: 300px;
} */
.myprofile-content-head {
  display: flex;
  /* gap: 10px; */
  position: relative;
  /* justify-content: center; */
}
.Add-language {
  position: relative;
  top: 45px;
  font-weight: normal;
  font-size: 16px;
}
.Add-language:disabled {
  opacity: 0.5;
}
.Add-language2 {
  position: relative;
  left: 520px;
  top: 20px;
  font-weight: normal;
  font-size: 16px;
}
.Add-language2:disabled {
  opacity: 0.5;
}
.Add-language3 {
  position: absolute;
  right: 660px;
  /* top:20px;  */
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
}

.myprofile-content {
  display: flex;
  gap: 60px;
  margin-top: 10px;
}
.resume-list {
  display: flex;
  flex-direction: column;
}

.contentone {
  margin-top: 30px;
}
.myprofile-data {
  position: relative;
  left: 50px;
}
.myprofile-data-des {
  position: relative;
  left: 30px;
}

.myprofile-content-skills {
  position: relative;
  left: 70px;
}
.myprofile-content-data {
  position: relative;
  left: 25px;
}
.myprofile-content-links {
  position: relative;
  left: 160px;
  bottom: 40px;
  margin-bottom: 15px;
}
.myprofile-content-links > a {
  position: relative;
  bottom: 10px;
}
.myprofile-content-location {
  position: relative;
  left: 40px;
}
.myprofile-content-skills p:nth-child(2),
.myprofile-content-skills p:nth-child(3),
.myprofile-content-skills p:nth-child(4),
.myprofile-content-skills p:nth-child(5) {
  margin-top: -15px;
}
.hr2 {
  background-color: blue;
  position: relative;
  bottom: 25px;
  width: 580px;
}
.hr-line {
  background-color: #fff;
  position: relative;
  top: 7px;
  width: 100px;
  height: 2px;
  /* left: 5px; */
}
.dot-wrapper {
  display: flex;
  gap: 20px;
  margin-left: 30px;
  position: relative;
  bottom: 5px;
}
.dots {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}
.dots .active {
  background-color: blue;
}
.head-content {
  border: 1px solid #362a41;
  padding: 5px 10px;
}
.modal-open {
  overflow: hidden;
}
.wrappers {
  background: rgb(189, 189, 189, 0.9);
  /* opacity:0.8; */
  width: 100vw;
  height: 100vh;
  margin-top: -30px;
  z-index: 3;
  overflow: auto;
  position: fixed;
}
.wrappers2 {
  background: rgb(189, 189, 189, 0.9);
  width: 100vw;
  height: 100vh;
  margin-top: -30px;
  z-index: 2;
  overflow: hidden;
  position: absolute;
  border-radius: 10px;
}
.wrappers3 {
  background: rgb(189, 189, 189, 0.9);
  width: 98vw;
  height: 100vh;
  margin-top: 0px;
  z-index: 2;
  overflow: hidden;
  position: absolute;
  border-radius: 10px;
  overflow-x: hidden;
}
.about-popup {
  background: #fff;
  border-radius: 20px;
  margin: 20px 520px 100px 370px;
  z-index: 1050;
  color: #362a41;
  font-weight: bold;
  padding: 20px 40px;
  width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  height: fit-content;
}

.about-end {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  /* margin: 40px; */
  cursor: pointer;
}

.input-field {
  padding: 10px 100px;
}

/* .label-name {
  font-family: "sans-seriff";
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #05204d;
}
.label-name-error {
  font-family: "sans-seriff";
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: red;
} */
/* .footer-header {
  font-family: "sans-seriff";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #05204d;
} */
.footer-headers {
  border: 1px solid red;
  color: #05204d;
}

.FormControlLabel {
  color: #4f4953;
}
.footer-headers > option,
.footer-header > option {
  color: "#362A41";
  background: #fff;
  border-radius: 8px;
  padding: 8px 12px;
}
select {
  background: #fff !important;
  border-radius: 12px;
  padding: 5px;
  font-family: Inter-Medium, sans-serif;
}
.input-space {
  margin-top: 15px;
}

.close-icon {
  position: absolute;
  right: 580px;
  top: 30px;
  font-size: 20px;
  cursor: pointer;
}

.Edu-data-wrapper {
  display: flex;
  gap: 40px;
}

.Edu-data {
  left: 150px;
  font-size: 16px;
}

.Edu-data2 {
  position: relative;
  bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.EduType {
  margin-top: 30px;
}

.hover-content-icon .hover-content {
  display: none;
}

.hover-content-icon:hover .hover-content {
  display: block;
  color: black;
  border: 1px solid black;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  left: 20px;
  bottom: 20px;
}
.company-profile {
  display: flex;
  align-items: center;
  padding: 20px;
}

.cover-photo {
  flex: 1; /* Take up the left side */
  margin-right: 20px; /* Add spacing between the cover photo and avatar */
}

.avatar {
  width: 100px; /* Define the width of the avatar */
}

.company-info {
  flex: 3; /* Take up the remaining space on the right */
}
.color-div {
  height: 150px; /* Set your desired height */
  background-color: #362a41; /* Replace with your desired background color */
  /* Add any other styles you want for the colored div */
}

.avatarImg {
  position: relative;
  width: 140px;
  border-radius: 80px;
  top: -63px;
  margin-left: 8rem;
}

.bread-crumb {
  color: #a8a1ac;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 10px 0 20px 30px;
}
.bread-crumb > span {
  margin: 0 10px;
}
.react-tel-input .form-control {
  background-color: #f4f4f4 !important;
  font-size: 12px;
  width: 100%;
  color: #4f4953;
  font-weight: normal;
}
.postedby:hover .name {
  display: block;
  background-color: #362a41;
  border-radius: 6px;
  padding: 5px;
  color: #fff;
  width: fit-content;
  margin: -20px 0 0 95px;
  z-index: 999;
}
.icon {
  display: flex;
}
.icon img {
  margin-left: 10px;
}
.xakalinfo {
  display: none;
}
.req-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
.icon:hover .xakalinfo {
  display: inline-flex;
  background-color: #362a41;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  padding: 5px;
  width: 330px;
  position: absolute;
  left: 45%;
  top: 295px;
  z-index: 999;
}
.xakalinfo span {
  margin-left: -20px;
}
.xakalinfo img {
  position: relative;
  right: 28px;
}
.xakalinfos {
  display: none;
}
.icon:hover .xakalinfos {
  display: inline-flex;
  background-color: #362a41;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  padding: 5px;
  width: 330px;
  position: absolute;
  left: 68%;
  top: 27.5%;
  /* bottom:40px; */
}
.xakalinfos span {
  margin-left: -20px;
}
.xakalinfos img {
  position: relative;
  right: 28px;
}

.button2:hover .plus-icon2 {
  display: block;
}
@-moz-document url-prefix() {
  .button2:hover .plus-icon2 {
    position: relative;
    margin-left: 5px;
  }
}
.button2:hover .plus-icon {
  display: none;
}
.plus-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 1px 1px 0 -5px;
}
.plus-icon2 {
  width: 30px;
  height: 30px;
  display: none;
  margin: 0 1px 0 -5px;
}
.css-ptkaw2-MuiDataGrid-root {
  border: none !important;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cellContent {
  color: #49454f !important;
  opacity: 0.8;
  font-weight: 600 !important;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
}
.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
  margin-top: 14px;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 17px;
}
.social-img {
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}
.DeActWrapper {
  display: flex;
  justify-content: space-between;
}
.DeActPage {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 50px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  text-transform: capitalize;
  cursor: pointer;
  background: #f4f4f6;
  padding: 20px;
  border-radius: 8px;
}
.page {
  display: none;
}
.Edit-wrapper {
  display: flex;
  justify-content: space-between;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-left: 10px;
}
.about-links {
  display: flex;
  justify-content: space-between;
  color: #a8a1ac;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  cursor: pointer;
}
.about-links > .active .about-links {
  color: green;
}
.head-link {
  display: block;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.head-link-deact {
  display: none;
}
.about-sec {
  display: block;
  width: 100%;
}
.about-sec-deact {
  display: none;
}
.about-heading {
  background-color: #2fb766;
  padding: 10px;
  border-radius: 5px;
  color: white !important;
}
.table td {
  width: 100px;
}
.manageClass-edit {
  width: 90px;
}
@media screen and (max-width: 600px) {
  .myprofile-content-head {
    display: block;
  }
}
.deact {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid #dddfe4;
  border-radius: 8px;
}
.star-main {
  position: relative;
  display: flex;
}
.companyTab {
  position: relative;
  display: flex;
  gap: 20px;
  background: white;
  padding: 15px;
  border-radius: 16px;
  height: auto;
  margin-top: 10px;
}
.company-nav-link {
  color: #4F4953;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  padding: 10px;
}

.company-nav-link.active {
  color: #8653B6;
}
.company-nav-link.active:after {
  content: "";
  position: absolute;
  left: 20%;
  bottom: 1px;
  width: 50%;
  border-radius: 8px;
  height: 4px;
  background-color: #955cca;
}
/* SideBySideSections.css */

.container-company {
  display: flex;
  justify-content: space-between;
  margin-left: 8rem;
  margin-right: 6rem;
}

.section-company {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: white; /* Set background color to white */ /* Add border radius for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better visibility */
}

.about-company {
  background-color: white;
  border-radius: 24px 0 0 24px;
}

.contact-company {
  background-color: white;
  border-radius: 0px 24px 24px 0;
}

.other-org-head{
  font-size: 24px;
  color: #000000;
  font-weight: 700;
}
.other-org-comp-name{
  font-size: 18px;
  color: #8653B6;
  font-weight: 700;
  margin: 16px 0 8px 0;
}
.other-org-web{
  color: #4F4953;
  font-weight: 500;
  font-size: 14px;
}
@media screen and (max-width:1200px){
  .edit-comp-wrap{
    display: block;
    width:100%;
  }
}