.main-share-apply {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid grey; */
  padding:10px;
}
.company-logo-container .space {
  padding: 0;
  border: 0;
}
.job-btn-wrapper {
  padding: 10px;
}
.share-job-div {
  display: flex;
  width: 90%;
  gap: 30px;
  justify-content: space-between;
}
.button-match {
  display: flex;
  justify-content: end;
  margin: 30px 10px 10px 0;
  gap:10px;
}
.button-matchs {
  display: flex;
  justify-content: space-between;
  margin: 30px 10px 10px 0;
  gap:10px;
}
.suit-share {
  margin-top: -5px;
  width: 20px;
  height: 20px;
}
.salary-share {
  width: 20px;
  height: 20px;
}
.location-suit {
  width: 20px;
  height: 20px;
}
.home-share {
  margin-top: -5px;
  width: 20px;
  height: 20px;
}
.clock-share {
  width: 20px;
  height: 20px;
}
.shared-link {
  padding:10px 5px;
  font-size: 12px;
}
.shared-links{
  color:#4F4953;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}
.terms_and_link {
  display: flex;
  justify-content: center;
  font-size: 12px;
  background-color: #F4F4F6;
}
.upload-icon{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}
.upload-icons{
  display: flex;
  gap:60px;
  align-items: center;
}
.file-wrap{
  border-radius: 8px;
  border: 1px solid #FFF;
  width:100px;
  height: 65px;
  padding: 5px 10px;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%);
  background-size: cover;
  background-repeat: no-repeat; 
  position: relative;
  top:0px;
  left:0px;
}
.app-preview{
  border-bottom: 1px solid #F4F4F6;
  padding-bottom: 10px;
}
.footer-head{
  padding:10px 20px;
  display: flex;
  justify-content: space-between;
}
.share-job-explore-btn{
height: 43px;
top: 739px;
left: 34px;
padding: 12px 24px 12px 24px;
gap: 8px;
border-radius: 8px;
color:#362A41;
border: 1px solid #362A411A;
}