/* manageSwitch.css */

.containers-switch {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width:100%;
  max-width: 1150px;
  margin: 10px auto;
  background: #f1f1f1;
  border-radius: 30px;
  padding:63px 25px 70px 21px;
  max-width:1050px;
}

.fixed-section {
  width: 55%;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden; /* Ensure the fixed section doesn’t scroll */
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  max-width: 470px;
  height:510px;
}

.scrollable-section {
  overflow-y: auto; /* Scrollable vertically */
  box-sizing: border-box;
  width:50%;
  margin: -20px 0 0 0;
}

h1 {
  margin: 0;
}

p {
  margin: 0;
}
/* Custom styling for each status */
.status-toggle .pending .react-toggle-track {
  background-color: orange !important; /* Color for pending status */
}

.status-toggle .active .react-toggle-track {
  background-color: #2DDD5E !important; /* Color for active status */
}
.react-toggle-track{
  width:36px !important;
  height:20px !important;
  box-shadow: 0px 0px 0px 4px #F4EBFF;
  margin-top: 5px;
}
.react-toggle-thumb{
  width:16px !important;
  height:16px !important;
  top:7px !important;
}
.react-toggle--checked .react-toggle-thumb{
  left:18px !important;
}
.status-toggle .archived .react-toggle-track {
  background-color: gray !important; /* Color for archived status */
}
.resume-wrapper {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing as needed */
  top: 0;
}

.resume-wrapper img {
  width: 16px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}

@media screen and (max-width:1200px){
  .containers-switch {
    display:flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
  .fixed-section, .scrollable-section{
    width:auto;
    justify-content: center;
    max-width: 100%;
    max-width: 500px;
    margin: auto;
  }
  .scrollable-section{
    margin-top: 20px;
  }
  .avail-day{
    justify-content: center;
  }
  .aval-picks {
    margin: auto;
  }
}