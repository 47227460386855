.req-name{
font-family: Inter-Medium,sans-serif;
font-size: 16px;
font-weight: normal;
color:#362A41;
line-height: 19px;
letter-spacing: 0px;
text-align: left;
margin:10px 10px;
}
.int-star-pos{
    display: flex;
    gap: 10px;
    margin-top:30px;
}
.int-host{
    color: rgba(168, 161, 172, 1);
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
}
.int-back{
    margin-right: 10px;
}
.req-icon:hover::after{
    content: attr(data-titles);
    background-color: #362A41;
    border-radius: 6px;
    color:#FFF;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    padding:5px 20px;
    text-align: left;
    position:absolute;
    bottom: 30px;
    right:0px;
    border-radius: 8px;
    width:320px;
    z-index:2;
    transition: opacity 1s;
}
.req-icon{
    border: 1px solid grey;
    border-radius: 50%;
    padding:0 5px;
    width:24px;
    height:24px;
}
.button8:hover::after{
    content: attr(data-title);
    background-color: #362A41;
    border-radius: 6px;
    color:#FFF;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    padding:5px 20px;
    text-align: left;
    position:absolute;
    top:40px;
    right:0px;
    border-radius: 8px;
    width:200px;
    z-index:2;
    transition: opacity 1s;
}
.user_info{
    background-color: aquamarine;
    padding:10px;
    color:#362A41;
    font-size: 16px;
    font-weight: normal;
    position:absolute;
    right:-20px;
    border-radius: 8px;
    width:200px;
    z-index:2;
    display: none;
}
.button8:hover .user_info{
    display: block;
}
.btn-wrappers{
    background-color: yellowgreen;
    border-radius: 12px;
    padding:5px;
}
.user_info > button{
    background-color: white;
    padding:10px;
    border-radius: 6px;
    display: flex;
    position: relative;
    left:120px;
    top:5px;
}
.profile-crieteria{
    position: absolute;
    left:85px;
}
.profile-update{
    color:#4F4953;
    font-size: 18px;
    font-weight: normal;
    border-bottom: 1px solid grey;
    padding:20px 0px;
    display: flex;
    justify-content: space-between;
}
.profile-update > img{
    rotate: 180deg;
    margin-top: -10px;
    cursor: pointer;
}
.profile-check{
    width:20px;
    height:20px;
    background-color: green;
}

.profile-uncheck{
    width:20px;
    height:20px;
    background-color: red;
}

.css-13cymwt-control {
    /* margin: 20px; */
    background-color: "#F4F4F6";
    


}
.MuiTab-textColorSecondary.Mui-selected{
    color:#362A41 !important;
}
/* .PrivateTabIndicator-colorSecondary-3{
    width:60px !important;
    left:15px !important; 
} */
/* .PrivateTabIndicator-root-1 {
    left:50px !important;
} */
.PrivateTabIndicator-root-1, .jss3, .MuiTabs-indicator{
    background-color: rgba(134, 83, 182, 1) !important;
    width:80px !important;
    /* position:relative !important; */
}
.MuiTab-root{
    min-width:80px !important;
    padding:0 !important;
}
.req-desig{
    font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-weight: normal;
color:rgba(168, 161, 172, 1);
line-height: 17px;
letter-spacing: 0.20000000298023224px;
text-align: left;
margin:0 10px;
}
.Int-title{
    font-family: Inter-Medium,sans-serif;
font-size: 16px;
font-weight: normal;
color:rgba(79, 73, 83, 1);
line-height: 19px;
letter-spacing: 0.20000000298023224px;
text-align: left;

}
.int-duration{
    font-family: Inter-Medium,sans-serif;
    font-size: 12px;
    font-weight: normal;
    color:#362A41;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    /* margin:10px 5px; */
    
}
.int-exp{
    font-family: Inter-Medium,sans-serif;
font-size: 12px;
font-weight: normal;
line-height: 14px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color:rgba(175, 175, 175, 1);
}
.int-decline{
    width: 76px;
height: 32px;
top: 150px;
left: 356px;
padding: 8px 8px 8px 7px;
border-radius: 8px;
border: 1px solid rgba(54, 42, 65, 0.1);
gap: 4px;
font-family: Inter-Medium,sans-serif;
font-size: 12px;
font-weight: normal;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color:rgba(255, 72, 115, 1);

}
.int-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
    padding:10px 0;
}
.int-head2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
    padding:10px 0;
}
.int-ser{
    position: absolute;
    right:15px;
    margin-top: -10px;
}
.int-tit-dur-wrapper{
    margin-bottom: 5px;
    margin-top: 10px;
}
.int-res{
    padding:7px;
    background-color: #362A41;
    border: 1px solid #362A41;
    color:#FFF;
    border-radius: 8px;
    font-size: 14px;
}
.thumb-img{
    cursor: pointer;
    width:20px;
     background:#000;
     border-radius:50%;
}
.profile-cont{
    display: flex;
    margin-top: -2px;
    margin-right: 10px;
}
.other-p-wrap{
    display: flex;
    justify-content: space-between;
}
.other-p-tit-line{
    width:2px;
    height: 60px;
    background-color: grey;
}
.other-p-dur-wrap{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-left: 40px;
    align-items: center;
    width: 101px;
    height: 24px;
    background: rgba(187, 172, 205, 1);
    border-radius: 8px;
    padding: 5px;
}
.other-p-dur{
    font-size: 12px;
    font-weight: normal;
    font-family: Inter-Medium,sans-serif;
    color: rgba(255, 255, 255, 1);
}
.other-p-cost{
    color: rgba(54, 42, 65, 1);
    font-weight: normal;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.other-p-time{
    font-size: 12px;
    color: rgba(168, 161, 172, 1);
    font-weight: normal;
    font-family: Inter-Medium,sans-serif;
}
@media  (max-width:600px) {
    .int-tit-dur-wrapper{
    display: block;
    }
    .int-head{
        display: block;
    }
    .int-head2{
        padding:10px 20px;
    }
    .int-ser{
        position: relative;
        margin:-50px 0 0 20px;
    }
    .profile-cont{
        margin-top: 15px;
        margin-right: 0;
    }
}
.star-people{
    width: 20px;
}
.star-average{
    font-size: 16px;
}
.star-space{
    position: relative;
    display: flex;
    justify-content: center;
}