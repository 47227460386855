.containerInterviewNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

/* Optional: styling for the notification box */
.notificationInterview {
  padding: 14px 20px;
  border-radius: 15px;
  background-color: #fff;
  max-width: 576px;
  margin: 4px auto;
  display: flex;
  align-items: center;
}
.interviewNotificationCard {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
.notificationImage {
  width: 50px;
  height: 50px;
  border-radius: 30px;
}
.all-noti-cont{
  color: #4f4953;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.all-noti-cont span{
  font-weight: 400;
}
.buttonGridInterview {
  position: relative;
  display: grid;
  align-items: center;
}
.buttonDecline {
  color: red;
  background: white;
  border: 1px solid black;
}

.buttonExpired {
  color: gray;
  background: lightgray;
  border: 1px solid darkgray;
  cursor: not-allowed;
}
.buttonInterview {
  font-family: var(--pf-medium);
  border-radius: 10px;
  height: 40px;
  opacity: 1;
  color:#FD2145;
  border: 0.5px solid #FD2145;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  gap:10px;
  align-items: center;
  justify-content: center;
}
.btn-new-int{
  border: 0.5px solid #362A41;
  color: #362A41;
  border-radius: 15px;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  width: 200px;
}
.buttonInterviews {
  background: white;
  color: #362a41;
  font-family: var(--pf-medium);
  border-radius: 10px;
  height: 40px;
  opacity: 1;
  border: "1px solid #362a41";
}
.interview-joined {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 16px; /* Optional: Adds space from the edges of the screen */
  padding: 8px; /* Optional: Adds space inside the component */
  background-color: #fff; /* Optional: Sets the background color */
  border: 1px solid #ddd; /* Optional: Adds a border for better visibility */
  border-radius: 4px; /* Optional: Rounds the corners */
}
.iconInter {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.buttInter {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.but{
  background: #362a41;
    border-radius: 10px;
    /* width: 210px; */
    height: 40px;
    color: white;
}
.no-foundata{
  position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}