.nav{
    width: 1135.5px;
    height: 50px;
    margin-right: 100px;
    margin-top: 0px;
    /* background: #FFFFFF; */
    border-radius: 4px;
    border-radius: 4px;
}
.background{
    background-color :#F6F6F6 !important; 
    
}
.background1{
    background-color :#F6F6F6 !important; 
    position:relative;
    top:0px;
    left:50px;
}
.bk{
    position:relative;
    top:80px;
    left:50px;
}
.margin {
    margin-top: -40px;
}
.shadow{
    box-shadow: 0px 16px 32px rgba(54, 42, 65, 0.32);
    /* margin-top: -50px; */
}
.frame{
    position: absolute;
width: 560px;
height: 600px;
left: 200px;
top: 30px;
background: #FFFFFF;
box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.2);
border-radius: 8px;
}
.close-modal{
    color: #F6F6F6;
    background-color: #362A41;
    margin-left: 516px;
    margin-top: 10px;
}
body.active-modal{
    overflow-y: hidden;

}
/* .modal{
    width: 100vw;
    height: 100vw;
    position: fixed;
    background-color: aqua;
    margin-top: 100px;
    margin-left: 100px;
} */
.form-in{
    display: flex;
flex-wrap: wrap;
}
.visually-hidden{
    width: 60px;
    height: 14px; 
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #4F4953;
    
}
.text-val{
    position: absolute;
width: 171px;
height: 11px;
font-family: 'Inter-Medium';
font-style: italic;
font-weight: normal;
font-size: 10px;
line-height: 108.5%;
/* identical to box height, or 11px */
color: rgba(79, 73, 83, 0.3);

}
.form-but-upload{
    margin-left: 23.5rem;
    margin-top: 2rem;
}
.form-but-leave{
    margin-left: 16rem;
    margin-top: 4rem;
}
.validation{
    margin-top: 2rem;
}
.validation2{
    margin-top: 1rem;
}
.text-leave-req{
    margin-left: 1rem;
    margin-top: 6rem;
    width: 203px;
    height: 19px;
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #714F90;

}
.head{
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #714F90;

}
.labell{
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #4F4953;
}
.tab-head-color{
    color: #B8B3BC !important;
}
.align{
margin-left: -10px;
}
.align1{
    margin-left: -110px;
    margin-top: -2%;
    width: 1000px;
}
.align2{
    margin-left: 150px;
    
}
.atstyle{
    margin-left: -3px !important;
}
.atstyle1{
    margin-left: 9px !important;
    
}
.bar{
    width: 92%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background: #FFFFFF;
    height: 80px;
    margin-top: 20px;
}
.bar1{
    height: 2000px;
}
.tabline{
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
  }
.bar3{
    width: 92%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background: #FFFFFF;
    margin-top: -5%;
    height: 90px;
    margin-top: 20px;
    margin-left: 10px;
}
.attenav{
    width: 92%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background: #FFFFFF;
    margin-top: 15px;
    height: 80px;
}
.title{
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    color: #4F4953;
    

}
.leave-title{
    width: 100px;
    height: 19px;
    margin-top: 100px;
    margin-left: 80px;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    color: #4F4953;
}
.title1{
    width: 200px;
    height: 19px;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    color: #4F4953;
}

.height{
    height: 40px !important;
}
.attendance{
    width: 143px;
    height: 15px;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #714F90;
    margin-left: 20px;
    }
.aplev{
    width: 143px;
    height: 15px;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #714F90;
    margin-left: 34px;
    margin-top: 20px;
}

.applebar{
    margin-left: 15rem;
    padding-left: 1rem; 
    background-color:#F6F6F6;
    height:100%;
    margin-top:-100px;
}
.lim2{
    background-color:#F6F6F6;
}
.transcon{
    margin-left: -35px;
}
.bartrans{
    width: 92%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    background: #FFFFFF;
    margin-left: 20px;
}
.sem{
    width: 100px;
    height: 19px;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #714F90;

 }
 .num{
    width: 105px;
    height: 14px;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #4F4953;
    
 }
 .box{
    width: 300px;
    height: 40px;
    /* border: 1px solid #B9B7BA; */
    box-sizing: border-box;
    /* box-shadow: 0px 24px 32px rgba(12, 22, 40, 0.07); */
    border-radius: 4px;
    background-color: #F6F6F6;
 }
.location{
    height: 15px;
    left: 220px;
    margin-top: -10%;
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 19px;
    color: #714F90;
}

.navprac{
    width: 100%;
    height: 80px;
    margin-right: 100px;
    margin-top: 18px;
    margin-left: 0%;
    /* position: fixed; */
    background: #FFFFFF;
   
}
.navprac1{
    width: 80%;
    height: 80px;
    margin-right: 100px;
    margin-top: 10px;
    margin-left: 0%;
    position: absolute;
    background: #FFFFFF;
   
}
.lg-nav {
    margin-left: 150px !important;
}
#sem{
    margin-top: 40px;
    margin-left: 50%;
}
.cards{
    position:relative;
    bottom:100px;
}

.to-do{
    margin-top:-230px;
}
.wrapper{
    margin-bottom:100px;
}
.box-card{
    position: relative;
    width: 1600px;
    height: 136px;
    left: -180px;
    top: 368px;
    background: #FFFFFF;
    margin-left: -590px;
}
.b-card1{
    position: relative;
    width: 1600px;
    height: 136px;
    left: 430px;
    top: 368px;
    background: #FFFFFF;
    margin-left: -590px;
}
.box-card2{
    position: relative;
    width: 1600px;
    height: 136px;
    left: 1040px;
    top: 368px;
    background: #FFFFFF;
    margin-left: -590px;
}
.box-card3{
    position: relative;
    width: 1600px;
    height: 136px;
    left: 1650px;
    top: 368px;
    background: #FFFFFF;
    margin-left: -590px;
}
.card-text{
width: 200px;
height: 17px;
font-family: 'Inter-Medium';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 108.5%;
color: #B8B3BC;

}
.text-cont{
 width: 27px;
height: 58px;
font-family: 'Inter-Medium';
font-style: normal;
font-weight: normal;
font-size: 48px;
letter-spacing: 0.02em;
color: #362A41;

}
.down{
    position: relative;
    height: 100px;
    width: 900px;
    right: 1750px;
    top: 130px;
    border-radius: 8px;
    background-color: #FFFFFF;

}
.down1{
    position: absolute;
    height: 100px;
    width: 900px;
    left: 340px;
    top: 1250px;
    border-radius: 8px;
    background-color: #FFFFFF;

}
.leave-head{
    width: 260px !important;
height: 47px;
margin-left: -160px !important;
top: 600px;

font-family: 'Inter-Medium';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;

color: #362A41;
}
.leave-button{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 24px;
width: 130px;
height: 41px;
margin-left: 400px !important;
margin-top: 200px !important;
background: #362A41;
box-shadow: 0px 16px 32px rgba(54, 42, 65, 0.32);
border-radius: 8px;

}
.stick{
   
width: 0px;
height: 128px;
margin-left: -360px !important;
margin-top: 150px !important;

border: 1px solid rgba(2, 2, 2, 0.1);
}
.leave-text{
    height: 17px;
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    
}
.ag{
   
font-family: Inter-Medium;
font-size: 18px;
font-weight: normal;
line-height: 10px;
letter-spacing: 0.02em;
text-align: left;
color: #362A41;
margin-left: 40px;
margin-top: 40px;
}
.box-card1{
    position: absolute;
    width: 368px;
    height: 240px;
    left: 880px;
    top: 368px;
    background: #FFFFFF;
    margin-left: -590px;

}
.box-card11{
    position: absolute;
    width: 368px;
    height:240px;
    left: 650px;
    top: 368px;
    background: #FFFFFF;
    
}
.box-card22{
    position: absolute;
    width:  368px;
    height: 240px;
    left: 1010px;
    top: 368px;
    background: #FFFFFF;
   
}

.bt-download{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 24px;
position: absolute;
/* width: 150px; */
height: 41px;
left: 650px;
top: calc(50% - 41px/2 - 0.5px);
background: #362A41;
box-shadow: 0px 16px 32px rgba(54, 42, 65, 0.32);
border-radius: 8px;

}
.bt-load{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    position: absolute;
    width: 180px !important;
    height: 41px !important;
    left: 720px;
    top: calc(50% - 41px/2 - 0.5px);
    background: #362A41;
    box-shadow: 0px 16px 32px rgba(54, 42, 65, 0.32);
    border-radius: 8px;
    
    }
.p{  
width: 220px;
height: 30px;
font-family: 'Inter-Medium';
font-style: normal;
font-weight: normal;
font-size: 14px;
color: #FFFFFF;
margin-left: -20px;

}
.left{
    margin-left: -1720px;
    margin-top: -80px;
    margin-bottom: 100px;
}

.search{
    width: 1135.5px;
    height: 40px;
    background: rgba(54, 42, 65, 0.04);
}
.seme{
    width: 300px;
    height: 40px;
    left: 384px;
    top: 312px;
    border: 1px solid #B9B7BA;
    box-sizing: border-box;
    border-radius: 4px;
}
.line{
    width: 1050px;
    height: 0px;
    left: 3px;
    top: 30px;
    border: -1px solid #DFDFE0;
}

.navbar-brand{
    width: 64px;
    height: 14px;
    left: 288px;
    top: 130px;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color:  #714F90;
}

.att-button{
    margin-top:200%;
}
.intern{
    margin-left: 0%;
    margin-top:3%;
}
.intern-button{
    margin-left: 1%;
    margin-top: 4%;
}
.cor{
    margin-left: 5%;
}
.transport{
    background-color: #362A41 ;
    margin-left: 2%;

}
.time-button{
    margin-top: 5%;
}
.limiter{
    max-width: 90%;
    margin-left: 0%;
}
.date{
    margin-left:11px;
}
.description1{
    margin-left:-25%;
}
.until{
    margin-left:-10%;
    margin-top: 4%;
}
.until1{
    margin-top: 5%;
}
.getresult{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 21px;
     width: 130px;
    height: 10px;
    left: 795px;
    top: calc(50% - 41px/2 + 12.5px);
    background: #362A41;
    box-shadow: 0px 16px 32px rgba(54, 42, 65, 0.32);
    border-radius: 8px;
    height: 17px;
    left: 24px;
    top: 12px; 
    font-family: Inter-Medium;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px; 
    color: #FFFFFF;

}
.linesem{
    margin-left: -1153px;
    margin-top:100px;
    width: 1040px;
    height: 0px;
    
}
.container-fluid{
    max-width: 100%!important;
    background-color :#F6F6F6 !important; 
}    
/* @media only screen and(max-width: 500px) {
.container-fluid{
    max-width: 100%!important;
    margin-left: 10%;   
 }    
.cls{
     margin-bottom: 5%; 
     margin-left: 15%;
 }
 .rs{
     margin-left: -10%;
 }
.att-button{
    margin-top: 5%;
    margin-left: 7%;
}
.intern{
    margin-left: 1%;
    max-width: 100%;
}

.trans{
    max-width: 100%;
}
.transport{
    max-width: 100%;
margin-left: 0%;
}
.box{
    max-width: 100%;
}
.limiter{
    max-width: 100%;
    
}
.until-date{
    margin-left: 100%;
}
.until{
    max-width: 100%;
    margin-left: 40%;
}
.description1{
    /* max-width: 100%; */
    /* margin-left:20%;
}
.navprac{
    width: 50%;
    height: 80px;
    margin-right: 100px;
}
} */
@media (max-width: 1000px ) { 
.new-xakal-input{
    width:80%;
    height:60px;
    border: 1px solid #B9B7BA;
    border-radius: 4px;
    padding-left:24px;
    font-size:14px;
    margin-left: 10rem !important;
}
.card-max{
    margin-left: 7rem !important;
}
.max-resp{
    margin-left: 10rem !important;
    width: 26.5rem !important;
}
.labell{
    margin-left: 10rem !important;
}
.m-l{
    margin-left: 6.3rem !important;
}
.getresult{
    margin-left: 10rem !important;
}
.navprac{
    width: 100px !important;
    margin-left: 20rem !important;
}
.navbar{
    /* margin-left: 4.5rem !important; */
    /* width: 33rem !important;    */
    margin-top: 0rem !important;
}  
.resp {
    width: 17rem !important;
    margin-left: 11rem !important;
}
.resp-th{
    margin-left: 13rem !important;
}
.deptres{
    width: 17rem !important;
    margin-left: 7rem !important;
    margin-top: 1rem !important;
}
.resp1 {
    width: 130% !important;
    margin-left: -4rem !important;
    margin-top: 1rem !important;
}
.att-max{
    margin-top: 0rem !important;
    margin-left: -10rem !important;
}
.att-max-sem{
    margin-left: -10rem !important;
}
.max-head{
    margin-left: 10rem !important;
}
.attre{
    margin-top: 1rem !important;
    width: 65% !important;
}
.assres{
    margin-left: 10rem;
    width: 35rem !important;
}
.salres{
    width: 80% !important;
    margin-left: 12rem !important;
}
.formres{
    width: 15rem !important;
    margin-left: -20rem !important;
    margin-top: 10px !important;
    height: 40px !important;
}
.oneres{
    width: 60% !important;
    margin-left: 9rem !important;
}
.checkres{
    width: 60% !important;
    margin-left: 4rem !important;
    margin-top: -2rem !important;
}
.tableres{
    width: 10rem !important;
    margin-left: 6rem !important;
}
.paysem{
    width: 20rem !important;
    margin-left: 9rem !important;
}
.pay{
    width: 120% !important;
    margin-left: -2rem !important;
    margin-top: 2rem !important;
}
.pay1{
    width: 120% !important;
    margin-left: 9rem !important;
    margin-top: -10rem !important;
}
.dropres{
    width: 32rem !important;
    margin-left: 3.3rem !important;
    }
.dropres1{
    width: 32rem !important;
    margin-left: 6.8rem !important;
}
.dro-down{
    margin-left: -2rem !important;
    margin-top: 0.70rem !important;
    width: 13rem !important;
}
.typedropres{
    width: 163px !important;
    margin-left: 4rem !important;
}
.typedropres1{
    margin-left: -13.1rem !important;
    margin-top: 8rem !important;
    width: 11.8rem !important;
}
.typedropres2{
    margin-left: -14rem !important;
    margin-top: 6.7rem !important;
    width: 11.8rem !important;
}
.typedropres3{
    margin-left: -14rem !important;
    margin-top: 2rem !important;
    width: 11.8rem !important;
}
.mdl{
    margin-left: -7rem !important;
    margin-top: -3rem !important;
    width: 12rem !important;
}
.box-res{
    margin-left: 200px !important;
    width: 130px !important;
    margin-top: -300px;
}
.box-res1{
    margin-left: -740px !important;
    width: 130px !important;
    margin-top: -150px;
}
.box-res2{
    margin-left: -740px !important;
    width: 130px !important;
}
.box-res3{
    margin-left: -740px !important;
    width: 130px !important;
    margin-top: 150px !important;
}
.down{
    width: 20px !important;
    margin-left: -900px;
}
.card-text{
    width: 100px;
}
.t-res{
    width: 50px !important;
    margin-left: -600px;
}
.p{
    margin-left: -35px;
    margin-top: -500px;
}
.idres{
    width: 60% !important;
    margin-left: -4rem !important;
}
.intres{
    width: 26.3rem !important;
    margin-left: 5.4rem !important;
    margin-top: 0.2rem !important;
}
.drop{
    width: 17rem !important;
    margin-left: 5rem !important;
    margin-top: -4px !important;
}
.dropd{
    width: 26.2rem !important;
    margin-left: -4.6rem !important;
    margin-top: 6.4rem !important;
}
.drop1{
    width: 19.7rem !important;
    margin-left: 0.9rem !important;
    margin-top: -0.5rem !important
}
.intrnalres{
    width: 160px !important;
    margin-left: 1rem !important;
    margin-top: -0.1rem !important;
}
.intrnalheadres{
    margin-left: 4rem !important;
    margin-top: -0.1rem !important;
}
.intrnalheadres1{
    margin-left: 4rem !important;
    margin-top: -0.1rem !important;
    width: 160px !important;
}
.dropsemester{
    width: 160px !important;
    margin-left: -1.6rem !important;
    margin-top: -1rem !important;
}
.dropsemester1{
    width: 160px !important;
    margin-left: -2.9rem !important;
    margin-top: -1rem !important;
}
.resup{
    margin-left: 11rem !important;
}
.choose-max{
    margin-left: 11rem !important;
}
.col-max-th{
    margin-left: 12rem !important;
}
.set-max{
    width: rem !important;
    margin-left: 8.3rem !important;
} 
.staff-max-head{
    margin-left: -17rem !important;
    margin-top: 20rem!important;
    width: 30rem !important;
}
.max-row-th
{
    margin-left: 5rem;
}
.ressp{
    width: 70% !important;
    margin-left: 9rem !important;
}  
.mdl-menu__outline1{
    margin-left: 10rem !important;
    margin-top: -0.75rem !important;
}
.year-drop{
    width: 19.8rem !important;
    margin-left: 5.9rem !important;
    margin-top: 0.3rem !important;
}
.staff-res{
    width: 20rem !important;
    margin-left: 5rem !important;
    margin-top: -2.2rem !important;
}
.edit-res{
    margin-left: -1.6rem !important;
    margin-top: 8px !important;
    width: 11.8rem !important;
}
.edit-res1{
    margin-left: -1.90rem !important;
    margin-top: 8px !important;
    width: 11.8rem !important;
}
.dept-drop{
    width: 19.9rem !important;
    margin-left: 3.4rem !important;
    margin-top: 36px !important;
}
.drop-menu-m {
    width: 20rem !important;
    margin-left: 5rem !important;
}
.sal-input{
    width: 110% !important;
    margin-left: 13rem !important;
}
.col-name {
    width: 157px !important;
}
.pass-name{
    margin-left: 11rem !important;
    width: 155px !important;
}
.deg-max-th{
    width: 11.5rem !important;
    margin-left: 8.5rem !important;
}
.parent-name{
    margin-left: 14rem !important;        
    width: 157px !important;
}
.con-name {
    width: 155px !important;
    margin-left: 12rem !important;
}
.add-date {
    margin-left: 10.3rem !important;        
    width: 155px !important;
}
.but-res{
    margin-left: 1rem !important;
}
.button-res{
    width: 50px;
}
.dep-drop {
    margin-left: -7px !important;
    width: 165px !important;
    margin-top: 9px !important;
}
.id-name{
    margin-left: 8.4rem !important;
    width: 155px !important;
}
.cls-name {
    margin-left: 9.4rem !important;        
    width: 155px !important;
}
.dob-name {
    width: 155px !important;
    margin-left: 12rem !important;
}
.delete {
    /* width: 60% !important; */
    margin-left: 2rem !important;
    height: 50px;
}
.leave-resp{
    width: 200px !important;
}
.leave-bar{
    margin-top: 1rem !important;
    width: 36rem !important;
    margin-left: 7rem!important;
}
.leave-sem{
    width: 20rem !important;
    margin-left: rem !important;
}
.leave-head{
    width: 90% !important;
    margin-left: 0rem !important;
}
.box-card1{
    margin-left: -55rem !important;
    width: 40rem !important;
    height: 150px;
    position: relative;
    margin-top: -200px;
}
.leave-lab{
    width: 180px !important;
    margin-top: 1rem !important
}
.box-card11{
    margin-left: 12.5rem !important;
    width: 150rem !important;
    height: 150px;
    position: relative;
    margin-top: -30px;
}
.box-card22{
    margin-left: -29.6rem !important;
    width: 150px;
    height: 150px;
    position: relative;
    margin-top: 140px;
}
.down1{
    width: 42rem !important;
    margin-left: -10rem !important;
}
.max-dept-th{
    margin-left: -20rem !important;
    margin-top: 20rem !important;
}
.max-dept-th1{
    margin-left: 8rem !important;
}
.pap-max{
    margin-left: -5rem !important;
}
.check-max{
    margin-left: 10rem !important;
}
.check-max1{
    margin-left: 11rem !important;
    width: 17rem !important;
}
.course-max{
    margin-left: -14rem !important;
    margin-top: 20rem !important;
}
.staff-del-max{
    margin-left: -10rem !important;
    margin-top: 20rem !important;
}
.salary-max{
    margin-left: -10rem !important;
    margin-top: 20rem !important;
}
.sal-max-th
{
    margin-left: -35rem !important;
    margin-top: 19rem !important;
}
.nav-max-th{
    width: 49rem !important;
    margin-left: 8rem !important
}
.row-max{
    margin-left: 6rem !important;
}
.row-th{
    margin-left: 10rem !important;
}
.sal-drop-max{
    width: 17.2rem !important;
    margin-left: 5.2rem !important;
}

}

@media (max-width: 440px ) { 
.row-max{
    margin-left: 0rem !important;
}

.row-th{
    margin-left: 1rem !important;
}
.max-dept-th1{
    margin-left: 4rem !important;
}
.choose-max{
    margin-left: 0rem !important;
    margin-top: 3rem !important;
}
.new-xakal-input{
    width:80%;
    height:60px;
    border: 1px solid #B9B7BA;
    border-radius: 4px;
    padding-left:24px;
    font-size:14px;
    margin-left: -0.6rem !important;
}
.check-max{
    margin-left: 0rem !important;
}
.check-max1{
    margin-left: 10rem !important;
}
.labell{
    margin-left: -0.6rem !important;
}
.getresult{
    margin-left: 1.6rem !important;
}
.max-resp{
    margin-left: 2rem !important;
    width: 10rem !important;
}
.navprac{
    width: 100rem !important;
}
.navbar{
    margin-left: 1rem !important;
    /* width: 14rem !important;     */
}
.resp {
    width: 10rem !important;
    margin-left: 1rem !important;
}
.max-row-th{
    margin-left: 1rem;
}
.deptres{
    width: 10rem !important;
    margin-left: -3rem !important;    
}
.resp1 {
    width: 130% !important;
    margin-left: -4rem !important;
    margin-top: 1rem !important;
}
.max-head{
    margin-left: 3.5rem !important;
}
.attres{
    width: 13rem !important;
    margin-left: 1rem !important;
    margin-top: 0rem !important;
}
.attress{
    width: 100% !important;
    margin-left: 2.5rem !important;
    margin-top: 50%;
}
.attre{
    margin-top: 1rem !important;
    width: 65% !important;
}
.assres{
    width: 13rem !important;
    margin-left: 2rem;    
}
.salres{
    width: 80% !important;
    margin-left: 2.6rem !important;
}
.form-btn{
    margin-top: -50px !important;
}
.formres{
    width: 100% !important;
    margin-left: -26px !important;
    margin-top: 1px !important;
    height: 30px !important;
}
.leavesem {
    width: 60% !important;
    margin-left: -2rem !important;
}  
.oneres{
    width: 60% !important;
    margin-left: 1rem !important;
}
.checkres{
    width: 60% !important;
    margin-left: 1rem !important;
    margin-top: 10rem !important;
}
.tableres{
    width: 10rem !important;
    margin-left: 6rem !important;
}
.paysem{
    width: 100% !important;
    margin-left: -2rem !important;
    /* margin-top: -10rem !important; */
}
.pay{
    width: 120% !important;
    margin-left: -2rem !important;
    margin-top: 2rem !important;
}
.pay1{
    width: 120% !important;
    margin-left: -2rem !important;
    margin-top: -10rem !important;
}
.dropres{
    width: 132px !important;
    margin-left: -7.3rem !important;
}
.dropres1{
    width: 9.5rem !important;
    margin-left: -4rem !important;
    margin-top: 0rem !important;
}
.sal-drop-max{
    width: 11.3rem !important;
    margin-left: 6.6rem !important
}
.dro-down{
    margin-left: -2rem !important;
    margin-top: 0.70rem !important;
    width: 13rem !important;
}
.typedropres{
    width: 163px !important;
    margin-left: 4rem !important;
}
.typedropres1{
    margin-left: -13.1rem !important;
    margin-top: 8rem !important;
    width: 11.8rem !important;
}
.typedropres2{
    margin-left: -14rem !important;
    margin-top: 6.7rem !important;
    width: 11.8rem !important;
}
.typedropres3{
    margin-left: -14rem !important;
    margin-top: 2rem !important;
    width: 11.8rem !important;
}
.mdl{
    margin-left: -7rem !important;
    margin-top: -3rem !important;
    width: 12rem !important;
}
.box-res{
    margin-left: 200px !important;
    width: 130px !important;
    margin-top: -300px;
}
.box-res1{
    margin-left: -740px !important;
    width: 130px !important;
    margin-top: -150px;
}
.box-res2{
    margin-left: -740px !important;
    width: 130px !important;
}
.box-res3{
    margin-left: -740px !important;
    width: 130px !important;
    margin-top: 150px !important;
}
.down{
    width: 20px !important;
    margin-left: -900px;
}
.card-text{
    width: 100px;
}
.t-res{
    width: 50px !important;
    margin-left: -600px;
}
.p{
    margin-left: -35px;
    margin-top: -500px;
}
.idres{
    width: 60% !important;
    margin-left: -4rem !important;
} 
.intres{
    width: 10rem !important;
    margin-left: 1.4rem !important;
    margin-top: -0.5rem !important;
}
.drop{
    width: 174px !important;
    margin-left: -1rem !important;
    margin-top: -4px !important;
}
.dropd{
    width: 180px !important;
    margin-left: 13px !important;
    margin-top: -4px !important;
}
.drop1{
    width: 184px !important;
    margin-left: 0.9rem !important;
}
.intrnalres{
    width: 160px !important;
    margin-left: -8rem !important;
    margin-top: -0.1rem !important;
}
.intrnalheadres{
    margin-left: -5rem !important;
    margin-top: -0.1rem !important;
}
.intrnalheadres1{
    margin-left: -5rem !important;
    margin-top: -0.1rem !important;
    width: 150px !important;
}
.dropsemester{
    width: 160px !important;
    margin-left: -1.6rem !important;
    margin-top: -1rem !important;
}
.dropsemester1{
    width: 160px !important;
    margin-left: -2.9rem !important;
    margin-top: -1rem !important;
}   
.resup{
    margin-left: 2rem !important;
}
.ressp{
    width: 70% !important;
    margin-left: -1rem !important;
}
.mdl-menu__outline1{
    margin-left: 10rem !important;
    margin-top: -0.75rem !important;
}
.year-drop{
    width: 10.8rem !important;
    margin-left: 1.9rem !important;
    margin-top: -0.1rem !important;
}
.staff-res{
    width: 11rem !important;
    margin-left: 1rem !important;
    margin-top: -1.7rem !important;
}
.set-max{
    width: 13rem !important;
    margin-left: -1rem !important;
}
.staff-drop-max{
    width: 13rem !important;
    margin-left: -8rem !important;
    margin-top: -0.4rem !important
}
.edit-res{
    margin-left: -1.6rem !important;
    margin-top: 8px !important;
    width: 11.8rem !important;
}
.edit-res1{
    margin-left: -1.90rem !important;
    margin-top: 8px !important;
    width: 11.8rem !important;
}
.dept-drop{
    width: 10.8rem !important;
    margin-left: -7.1px !important;
    margin-top: 18px !important;
}
.drop-menu-m {
    width: 11rem !important;
    margin-left: 1rem !important;
}
.sal-input{
    width: 110% !important;
    margin-left: -1rem !important;
    margin-top: 1rem !important;
}
.col-name {
    margin-left: 22px !important;
    width: 157px !important;
}
.pass-name{
    margin-left: 35px !important;
    width: 155px !important;
}
.parent-name{
    margin-left: 77px !important;
    width: 157px !important;
}
.con-name {
    width: 155px !important;
    margin-left: 3.4rem !important;
}
.con-name1 {
    width: 155px !important;
    margin-left: 0.2rem !important;
}
.add-date {
    margin-left: 30px !important;
    width: 155px !important;
}
.h4-res {
    margin-left: 5.5rem !important;
}
.but-res{
    margin-left: 1rem !important;
}
.button-res{
    width: 50px;
}
.dep-drop {
    margin-left: -7px !important;
    width: 165px !important;
    margin-top: 9px !important;
}
.id-name{
    margin-left: -1px !important;
    width: 155px !important;  
}
.cls-name {
    margin-left: 1.15rem !important;
    width: 155px !important;
}
.dob-name {
    width: 155px !important;
    margin-left: 50px !important;
}
.delete {
    /* width: 60% !important; */
    margin-left: 2rem !important;
    height: 50px;
}
.leave-resp{
    width: 200px !important;
}
.leave-bar{
    margin-top: 1rem !important;
    width: 65% !important;
    margin-left: -30px !important;
}
.leave-sem{
    width: 150px!important;
    margin-top: -640px !important;
    margin-left: 10px !important;
}
.leave-head{
    width: 90% !important;
    margin-left: 0rem !important;
}
.box-card1{
    margin-left: -870px !important;
    width: 150px;
    height: 150px;
    position: relative;
    margin-top: -200px;
}
.leave-lab{
    margin-left: 8px !important;
    width: 180px !important;
}
.box-card11{
    margin-left: 80px !important;
    width: 150px;
    height: 150px;
    position: relative;
    margin-top: -30px;
}
.box-card22{
    margin-left: -510px !important;
    width: 150px;
    height: 150px;
    position: relative;
    margin-top: 140px;
}
.down1{
    width: 200px !important;
} 
.max-card{
    margin-left: -10rem !important;
}
.m-l{
    margin-left: 0rem !important;
}
.check-max1{
    margin-left: 1rem !important;
    width: 10rem !important;
}
.rs{
    margin-left: 1rem !important;

}
.card-max{
    margin-left: -1.8rem !important;
}
}
.hodNotes{
    margin-top: 60px;
    margin-left: 22px;
}
 .is-focused{
    position: relative;
    margin-top: 35px;
 }
