.containers {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
}

.certificate {
  background-color: #f4f6f6;
  padding: 100px 50px;
  text-align: center;
  position: relative;
  /* min-height: 100vh; */
  /* background-image: url(../../images/landing/logo.svg);
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: 50% 30%;
  filter: grayscale(5%);  */
}
.height-contain{
  border: 2px solid #4F4953;
  position: relative;
  padding:50px;
}
.certificate h1 {
  color: #333;
  font-size: 2.5em;
  margin-bottom: 10px;
}

.certificate h2 {
  color: #333;
  font-size: 1.75em;
  margin-bottom: 20px;
}

.certificate p {
  color: #000;
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 10px;
}

.certificate .name {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
}

.certificate .signature {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 100px 0px 50px 0;
}

.certificate .signature .person {
  color: #333;
  font-size: 1.2em;
}

.certificate .signature .person .title {
  color: #555;
  font-size: 0.9em;
}



.certificate .stripe-top-left{
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 15%;
  background: linear-gradient(to bottom right, #955cca 35%, #f4e8ff 65%);
  z-index: 1;
}

.certificate .stripe-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 33%;
  height: 15%;
  background-color: #ffd700;
  /* clip-path: polygon(100% 100%, 0 100%, 50% 50%); */
  background: linear-gradient(to top left, #4f4953 35%, #f4e8ff 5%);
  z-index: 1;
}

.set-image{
  position: absolute;
  height: 50px;
  top:20px;
  right:30px;
}